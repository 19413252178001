import { Select, SVG } from "react-components";
import { ChangeEventHandler, FC, PropsWithChildren } from "react";

interface Props extends PropsWithChildren {
  onChange: ChangeEventHandler<HTMLSelectElement>;
}

const ActionMenu: FC<Props> = ({ children, onChange }) => (
  <Select
    style={{ width: 55 }}
    value=""
    onChange={onChange}
    parentClassName="is-flex"
    childrenLeft={
      <SVG
        style={{ left: 8 }}
        className="is-absolute is-centered-y is-click-through"
        path="/site/icon/gear"
        alt="Gear"
        height={16}
      />
    }
  >
    <option value="" disabled />
    {children}
  </Select>
);

export { ActionMenu };
