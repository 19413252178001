import { AddressInfo } from "../../components";
import { AccountDO, fullName } from "data-model";
import { FC } from "react";
import clsx from "clsx";

interface Props {
  account: AccountDO;
  className?: string;
  withName?: boolean;
}

const AccountInfo: FC<Props> = ({ account, className, withName = false }) => (
  <div
    className={clsx(
      "is-grid is-grid-template-columns-auto-1fr is-row-gap-2 is-column-gap-2",
      className
    )}
  >
    {withName && (
      <>
        <strong>Name:</strong>
        <span>{fullName(account)}</span>
      </>
    )}
    <strong>Mobile:</strong>
    <span>{account.phoneNumber}</span>
    <strong>Email:</strong>
    <span>{account.email}</span>
    <strong>Address:</strong>
    <span>
      <AddressInfo {...account.address} />
    </span>
    <strong>Password:</strong>
    <span>{account.passwordCreated ? "Created" : "None"}</span>
  </div>
);

export { AccountInfo };
