import { AccountManager } from "./AccountManager";
import { bookingIdParam } from "../ManageBooking";
import { airportsById, db, hotelsById } from "../../jsongo";
import {
  checkAvailability,
  holdSpace,
  lookUpBookingOwner,
  optForTourNotice,
  optOutOfTourNotice,
} from "../../api";
import { BookingNumber, DaysLeft, Slider } from "../../components";
import {
  AccountDO,
  arrOfSize,
  BookingDO,
  busDeparts,
  DannoAvailabilityRes,
  DAYS_TO_PAY_FULL,
  defaultTourYear,
  DepartureDO,
  DepartureDO_Availability,
  DEPOSIT_AMOUNT,
  durationDays,
  finalPaymentDueBy,
  getPublicTourIds,
  holdExpiredAt,
  hotelOnFirstDay,
  hotelOnLastDay,
  isHoldActive,
  isSubjectToFullPayment,
  parseBookingNumber,
  pluralize,
  pricesByValue,
  roomLayoutAbbr,
  roomTypeCapitalized,
  roomTypeFrom,
  SeriesDO,
  SeriesDO_Availability,
  seriesIdFrom,
  sortNumbers,
  totalGuestsFrom,
  TourDO,
  TourDO_ID,
  tourEnds,
  TourYearDO,
  TourYearDO_PriceName,
  TPP_AMOUNT,
  trimAirportName,
  validateBookingNumber,
  yearFromDate,
  tourNumber,
  DepartureDO_PricesByDate,
  MMM_d_yyyy,
  ccc_MMM_d_yyyy,
  nowInCT,
  lowerCaseMeridiem,
  guestsRooms,
} from "data-model";
import {
  Checkbox,
  DatesTable,
  ErrorMessage,
  Image,
  PriceDetailsFooter,
  PriceDetailsHeader,
  PriceDetailsRow,
  PriceDetailsTable,
  PriceDetailsTotal,
  PricesTable,
  Radio,
  Select,
  SVG,
  useErrors,
} from "react-components";
import { ChangeEvent, FC, Fragment, useState } from "react";
import { useNavigate } from "react-router-dom";
import clsx from "clsx";
import { DateTime } from "luxon";

const DANNO_MAX_ROOMS = 10;
const DANNO_MAX_GUESTS = 20;
const DANNO_MAX_GUESTS_IN_ROOM = 4;

const CheckAvailability = () => {
  const navigate = useNavigate();

  const [account, setAccount] = useState<AccountDO | null>(null);

  const [tourId, setTourId] = useState("");
  const [roomGuests, setRoomGuests] = useState([2]);
  const [bookingNumber, setBookingNumber] = useState("");
  const [inviteStatus, setInviteStatus] = useState("");
  const [year, setYear] = useState(0);
  const [availability, setAvailability] = useState<DannoAvailabilityRes>({}); // TODO cache results?
  const [departedAt, setDepartedAt] = useState(""); // that's currently selected
  const [seriesCode, setSeriesCode] = useState("");
  const [pendingDate, setPendingDate] = useState(""); // whose series needs to be confirmed
  const [crossedDate, setCrossedDate] = useState(""); // that's been clicked on

  const [errors, catchErrors] = useErrors();
  const [isLoading, setIsLoading] = useState(false);

  const tour = tourId ? (db.tour.findByIdOrFail(tourId) as TourDO) : null;
  const tourYear = tour
    ? (db.tourYear.findByIdOrFail({ tour_id: tourId, year }) as TourYearDO)
    : null;
  const pricesByDate = pricesByDateFrom(availability);
  const departure = seriesCode
    ? availability[departedAt].departures.find((d) => d.series === seriesCode)!
    : null;

  const isInviteFound =
    inviteStatus && !/(Invalid|Not Found)/.test(inviteStatus);
  const inviteParams = isInviteFound ? parseBookingNumber(bookingNumber) : null;
  const inviteDate = inviteParams ? inviteParams.yyyyMMdd : null;
  const inviteSeries = inviteParams ? inviteParams.seriesCode : null;

  const availableSeries = departedAt
    ? availability[departedAt].series.filter((entry) => entry.available)
    : null;
  const isDateAvailable = (date: string, availabilityRes = availability) => {
    if (date === inviteDate) {
      return availabilityRes[date].series.find(
        (entry) => entry.code === inviteSeries
      )?.available; // instead of !. in case it's called with a lapsed booking number
    }
    return availabilityRes[date].series.some((entry) => entry.available);
  };
  const hasOtherOptions = (date: string) => {
    if (
      date === inviteDate &&
      availability[date].series.some((entry) => entry.available)
    ) {
      return true;
    }
    return availability[date].tours.length !== 0;
  };
  const scrollToDate = (date: string) => {
    setTimeout(() => {
      const calendar = document.querySelector(
        ".availability .calendar"
      ) as HTMLDivElement;
      const cell = document.querySelector(
        `span[data-date="${date}"]`
      ) as HTMLSpanElement;
      calendar.scrollTop = cell.offsetTop; // scroll into view
    });
  };

  // Newly created accounts won't have any active holds.
  // Also, an edit to account info has no effect on its holds.
  const activeHolds = account?.bookings.filter(isHoldActive) ?? [];
  const warnSameTour = activeHolds.some((b) => b.tour === tourId);
  const warnOverlap =
    departedAt && tourYear
      ? activeHolds.some((hold) => isOverlapping(hold, departedAt, tourYear))
      : false;
  const warnManyHolds = activeHolds.length >= 3;
  const canHoldSpace = account && seriesCode;
  const holdWarning = [
    warnSameTour && "Already Holding Tour",
    warnOverlap && "Overlapping Dates",
    warnManyHolds && `Has ${activeHolds.length} Active Holds`,
  ]
    .filter(Boolean)
    .join(", ");

  const handleUpcomingYearToggle = (e: ChangeEvent<HTMLInputElement>) => {
    setIsLoading(true);
    catchErrors(
      async () => {
        const { value, checked } = e.currentTarget;
        const upcomingYear = +value;

        if (checked) {
          const notice = await optForTourNotice(account!.id, {
            tour: tourId,
            year: upcomingYear,
          });
          setAccount((account) => {
            if (!account) return account;
            return {
              ...account,
              notices: [...account.notices, notice],
            };
          });
        } else {
          await optOutOfTourNotice(account!.id, {
            tour: tourId,
            year: upcomingYear,
          });
          setAccount((account) => {
            if (!account) return account;
            return {
              ...account,
              notices: account.notices.filter(
                (n) => !(n.tour === tourId && n.year === upcomingYear)
              ),
            };
          });
        }
      },
      () => setIsLoading(false)
    );
  };

  return (
    <div
      className="is-flex-1 is-grid is-column-gap-4"
      style={{
        gridTemplateColumns: "28% 22% 1fr auto",
      }}
    >
      {/*
        Scenario 1: an existing account is applied (account => null, newAccount => AccountDO).
        Scenario 2: a new account is created (account => null, newAccount => AccountDO).
        Scenario 3: an existing account is updated (account => AccountDO, newAccount => AccountDO).
        Scenario 4: an account is cleared (account => AccountDO, newAccount => null).
      */}
      <AccountManager account={account} setAccount={setAccount} />

      <div className="has-background-white has-border-gray">
        <div className="padding-x-2 padding-top-2 padding-bottom-3">
          <Select
            parentClassName="margin-bottom-2"
            className="is-size-2 has-text-weight-bold"
            value={tourId}
            onChange={async (e) => {
              const tourId = e.currentTarget.value;
              const tour = db.tour.findByIdOrFail(tourId) as TourDO;
              const year = defaultTourYear(tour);

              const availability = await checkAvailability({
                tour: tourId,
                "room-guests": roomGuests.toString(),
                year: year.toString(),
              });

              setTourId(tourId);
              setBookingNumber("");
              setInviteStatus("");
              setYear(year);
              setAvailability(availability);
              setDepartedAt("");
              setSeriesCode("");
              setPendingDate("");
              setCrossedDate("");
            }}
          >
            <option value="" hidden disabled>
              Choose a Tour...
            </option>
            {getPublicTourIds(db).map((tourId) => {
              const tour = db.tour.findByIdOrFail(tourId) as TourDO;
              return (
                <option key={tour._id} value={tour._id}>
                  {tour.name}
                </option>
              );
            })}
          </Select>

          <RoomEditor
            roomGuests={roomGuests}
            onSave={async (roomGuests) => {
              let thisAvailability = availability;
              if (tourId && year) {
                thisAvailability = await checkAvailability({
                  tour: tourId,
                  "room-guests": roomGuests.toString(),
                  year: year.toString(),
                });
                setAvailability(thisAvailability);
              }

              setRoomGuests(roomGuests);

              if (
                departedAt &&
                !isDateAvailable(departedAt, thisAvailability)
              ) {
                // A date was available and selected, but is now unavailable
                setDepartedAt(""); // unselect the date
                setSeriesCode("");
                setPendingDate(""); // in case the popup is open
              }

              if (
                crossedDate &&
                isDateAvailable(crossedDate, thisAvailability)
              ) {
                // A date was unavailable but is now available
                setCrossedDate(""); // in case the popup is open
              }
            }}
          />

          <div
            className="is-grid is-column-gap-2 is-align-items-center is-size-2"
            style={{ gridTemplateColumns: "auto 1fr 100px" }}
          >
            <label htmlFor="booking-number">
              <strong>Booking ID:</strong>
            </label>
            <BookingNumber
              id="booking-number"
              className="is-size-2"
              value={bookingNumber}
              setValue={setBookingNumber}
              onFormat={async (number) => {
                if (number) {
                  const res = validateBookingNumber(number);
                  if (res) {
                    const { seriesCode, yyyyMMdd } = res;

                    const thisYear = yearFromDate(yyyyMMdd);
                    const series = db.series.findByIdOrFail(
                      seriesIdFrom(seriesCode, thisYear)
                    ) as SeriesDO;
                    const thisTourId = series.tourYear_id.tour_id;

                    const { fullName } = await lookUpBookingOwner({ number });
                    if (fullName) {
                      let thisAvailability = availability;
                      if (thisTourId !== tourId || thisYear !== year) {
                        thisAvailability = await checkAvailability({
                          tour: thisTourId,
                          "room-guests": roomGuests.toString(),
                          year: thisYear.toString(),
                        });
                      }

                      setTourId(thisTourId);
                      setInviteStatus(fullName);
                      setYear(thisYear);
                      setAvailability(thisAvailability);
                      setDepartedAt(""); // if date is unavailable, or if the tour or year changed
                      setSeriesCode("");
                      setPendingDate("");

                      scrollToDate(yyyyMMdd);
                    } else {
                      setInviteStatus("Not Found");
                    }
                  } else {
                    setInviteStatus("Invalid");
                  }
                } else {
                  setInviteStatus(""); // when booking number is cleared
                }
                setCrossedDate(""); // in case the popup is open
              }}
            />
            <strong
              className={clsx("has-ellipsis", !isInviteFound && "has-text-red")}
              title={inviteStatus}
            >
              {inviteStatus}
            </strong>
            {tour && (
              <div className="is-grid-column-2-neg-1 is-flex margin-top-3">
                {sortNumbers(tour.publicYears).map((publicYear) => (
                  <Radio
                    id={`year-${publicYear}`}
                    key={publicYear}
                    parentClassName="margin-right-3"
                    value={publicYear}
                    checked={publicYear === year}
                    onChange={async (e) => {
                      const year = e.currentTarget.value;
                      const availability = await checkAvailability({
                        tour: tourId,
                        "room-guests": roomGuests.toString(),
                        year,
                      });

                      setBookingNumber("");
                      setInviteStatus("");
                      setYear(+year);
                      setAvailability(availability);
                      setDepartedAt("");
                      setSeriesCode("");
                      setPendingDate("");
                      setCrossedDate("");
                    }}
                  >
                    <strong>{publicYear}</strong>
                  </Radio>
                ))}
                {!!tour.upcomingYears.length && (
                  <span className="margin-right-2">Notify:</span>
                )}
                {sortNumbers(tour.upcomingYears).map((upcomingYear) => (
                  <Checkbox
                    key={upcomingYear}
                    id={`upcoming-year-${upcomingYear}`}
                    disabled={isLoading || !account}
                    checked={
                      !!account &&
                      account.notices.some(
                        (notice) =>
                          notice.tour === tour._id &&
                          notice.year === upcomingYear
                      )
                    }
                    value={upcomingYear}
                    onChange={handleUpcomingYearToggle}
                    inline
                    parentClassName="margin-right-3"
                  >
                    <strong>{upcomingYear}</strong>
                  </Checkbox>
                ))}
              </div>
            )}
          </div>
        </div>

        {tourYear && (
          <div className="has-border-top-gray is-relative availability">
            <DatesTable
              className="is-scrollable-y"
              cellClassName={(date) =>
                clsx(
                  "is-size-2 is-unselectable has-text-weight-bold",
                  isDateAvailable(date) || hasOtherOptions(date)
                    ? "has-text-light-blue is-clickable"
                    : "has-text-mid-gray",
                  date === departedAt && "has-background-blue has-text-white",
                  date === inviteDate && "is-outlined"
                )
              }
              innerClassName={(date) =>
                clsx(
                  !isDateAvailable(date) &&
                    hasOtherOptions(date) &&
                    "has-border-bottom-light-blue has-border-bottom-width-large"
                )
              }
              onClick={(e) => {
                const { date } = e.currentTarget.dataset as { date: string };

                if (date === departedAt) {
                  // Unselect the date
                  setDepartedAt("");
                  setSeriesCode("");
                  setPendingDate("");
                  setCrossedDate("");
                  return;
                }

                if (isDateAvailable(date)) {
                  // A blue is clicked
                  setDepartedAt(date);

                  if (date === inviteDate) {
                    setSeriesCode(inviteSeries!);
                    setPendingDate("");
                    setCrossedDate("");
                  } else {
                    const availableSeries = availability[date].series.filter(
                      (entry) => entry.available
                    );
                    if (availableSeries.length > 1) {
                      setSeriesCode(""); // in case another date was selected prior
                      setPendingDate(date);
                    } else {
                      setSeriesCode(availability[date].series[0].code); // already sorted by filling order
                      setPendingDate(""); // in case <SeriesPicker /> is open
                    }
                    setCrossedDate(""); // in case <OtherOptions /> is open
                  }
                } else if (hasOtherOptions(date)) {
                  // An underlined date is clicked.
                  setCrossedDate(date);
                  setDepartedAt(""); // unset the current date, if any
                  setSeriesCode("");
                  setPendingDate(""); // in case <SeriesPicker /> is open
                } else {
                  // A gray date is clicked. Possible because it's a span, not a button[disabled].
                }
              }}
              prices={tourYear.prices}
              pricesByDate={pricesByDate}
            />
            <PricesTable
              captionClassName="padding-x-2"
              className="is-wide"
              pricesByDate={pricesByDate}
              tourYear={tourYear}
            />
            {pendingDate && availableSeries && (
              <SeriesPicker
                defaultCode={availableSeries[0].code}
                onCancel={() => {
                  setDepartedAt(""); // unselect
                  setPendingDate("");
                }}
                onConfirm={(code) => {
                  setSeriesCode(code);
                  setPendingDate("");
                }}
                series={availableSeries}
              />
            )}
            {crossedDate && (
              <OtherOptions
                onClose={() => setCrossedDate("")}
                onSeriesClick={(code) => {
                  setBookingNumber("");
                  setInviteStatus("");
                  setDepartedAt(crossedDate);
                  setSeriesCode(code);
                  setCrossedDate("");
                }}
                onTourClick={async (tourId, departedAt) => {
                  const year = yearFromDate(departedAt);

                  const availability = await checkAvailability({
                    tour: tourId,
                    "room-guests": roomGuests.toString(),
                    year: year.toString(),
                  });

                  setTourId(tourId);
                  setBookingNumber("");
                  setInviteStatus("");
                  setYear(year);
                  setAvailability(availability);
                  setDepartedAt(departedAt);

                  const availableSeries = availability[
                    departedAt
                  ].series.filter((entry) => entry.available);
                  if (availableSeries.length > 1) {
                    setSeriesCode("");
                    setPendingDate(departedAt);
                  } else {
                    setSeriesCode(availability[departedAt].series[0].code); // already sorted by filling order
                    setPendingDate("");
                  }
                  setCrossedDate("");

                  scrollToDate(departedAt);
                }}
                otherTours={availability[crossedDate].tours}
                series={availability[crossedDate].series}
                unavailableDate={crossedDate}
                unavailableTour={tourId}
              />
            )}
          </div>
        )}
      </div>

      <div className="is-flex is-flex-direction-column">
        <div className="has-background-white has-border-gray margin-bottom-4 is-flex">
          <div className="has-border-right-gray is-flex-1 padding-4">
            <h2 className="margin-top-0 margin-bottom-4 is-flex is-justify-content-space-between is-align-items-flex-end">
              <span className="is-size-1">
                {seriesCode ? (
                  <span>{tourNumber(seriesCode, departedAt)}</span>
                ) : (
                  "Tour Details"
                )}
              </span>
              {seriesCode && availableSeries && (
                <span className="has-text-weight-normal">
                  <DepartureCapacity
                    inventory={
                      availableSeries.find((s) => s.code === seriesCode)!
                        .inventory
                    }
                  />
                </span>
              )}
            </h2>

            {departedAt && seriesCode ? (
              <TourDetails
                departedAt={departedAt}
                departure={departure!}
                tour={tour!}
                tourYear={tourYear!}
                warnOverlap={warnOverlap}
                warnSameTour={warnSameTour}
              />
            ) : (
              <TourPlaceholder />
            )}
          </div>
          <div className="is-flex-1 is-flex is-flex-direction-column">
            <PriceDetails
              roomGuests={roomGuests}
              priceName={departure?.priceName}
              tourYear={tourYear}
            />
          </div>
        </div>

        <div className="has-background-white has-border-gray padding-4 is-flex-1">
          <div className="is-grid is-grid-template-columns-auto-1fr is-row-gap-2 is-column-gap-5">
            <DueDates departedAt={departedAt} seriesCode={seriesCode} />
            <strong>Travel Protection:</strong>
            <span>
              For medical coverage of pre-existing conditions Travel Protection
              must be purchased within 14 days of the date of deposit payment.
            </span>
            <strong>Confirmation:</strong>
            <span>
              Confirmation will be emailed to: {account && account.email}
            </span>

            <button
              className="button is-light-blue padding-x-4 margin-top-2 is-align-self-flex-start"
              disabled={!canHoldSpace || isLoading}
              onClick={async () => {
                setIsLoading(true);
                catchErrors(
                  async () => {
                    const hold = await holdSpace({
                      accountId: account!.id,
                      tour: tourId,
                      roomGuests,
                      date: departedAt,
                      series: seriesCode,
                    });
                    navigate(`/manage-booking?${bookingIdParam}=${hold.id}`);
                  },
                  () => setIsLoading(false)
                );
              }}
            >
              Hold Space
            </button>

            <h2 className="is-size-1 margin-top-2 margin-bottom-0 is-align-self-center">
              {!account
                ? "Assign Account to Hold Space"
                : !departedAt
                ? "Choose a Date to Hold Space"
                : !seriesCode
                ? "Choose a Series to Hold Space"
                : "Ready to Hold Space!"}
              {canHoldSpace && holdWarning && (
                <span className="has-text-red"> ({holdWarning})</span>
              )}
            </h2>

            <ErrorMessage
              className="is-grid-column-1-neg-1 is-marginless"
              errors={errors}
            />
          </div>
        </div>
      </div>

      <Slider />
    </div>
  );
};

export { CheckAvailability };

interface RoomEditorProps {
  roomGuests: number[];
  onSave: (roomGuests: number[]) => Promise<void>;
}

const RoomEditor: FC<RoomEditorProps> = ({
  roomGuests: initRoomGuests,
  onSave,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [roomGuests, setRoomGuests] = useState(initRoomGuests);
  const totalGuests = totalGuestsFrom(roomGuests);
  const totalRooms = roomGuests.length;

  const handleToggle = () => {
    setIsOpen(!isOpen);
    setRoomGuests(initRoomGuests);
  };

  return (
    <div className="is-relative margin-bottom-2">
      <div className="has-border-gray padding-y-1 padding-x-2 is-rounded-small is-flex is-align-items-center">
        <span className="is-size-2 is-flex-1">
          <strong>{totalGuestsFrom(initRoomGuests)} Guests</strong>:{" "}
          {roomLayoutAbbr(initRoomGuests)}
        </span>
        <button
          className="button is-ghost is-link is-paddingless"
          onClick={handleToggle}
        >
          Edit
        </button>
      </div>
      <div
        className={clsx(
          // Other Options popup has z-index of 1; Room/Guest Editor has 2 so it can overlay the former
          "is-absolute has-background-white padding-4 has-border-bottom-gray is-fullwidth has-shadow-gray is-z-index-2 is-top-0",
          !isOpen && "is-hidden"
        )}
      >
        <h3 className="margin-top-0 margin-bottom-2 padding-bottom-2 has-border-bottom-gray">
          {guestsRooms(roomGuests)}
        </h3>
        <div
          className="is-grid is-align-items-center is-row-gap-2"
          style={{
            gridTemplateColumns: "1.5fr auto 1fr auto",
          }}
        >
          {roomGuests.map((guestsInRoom, roomIdx) => {
            const minusDisabled = guestsInRoom === 1;
            const plusDisabled =
              guestsInRoom === DANNO_MAX_GUESTS_IN_ROOM ||
              totalGuests === DANNO_MAX_GUESTS;

            return (
              <Fragment key={roomIdx}>
                <h4 className="is-marginless">
                  {roomTypeCapitalized(guestsInRoom)} Room
                </h4>

                <button
                  className="button is-ghost is-circle"
                  disabled={minusDisabled}
                  onClick={() =>
                    setRoomGuests(
                      roomGuests.map((g, idx) => (idx === roomIdx ? g - 1 : g))
                    )
                  }
                >
                  <SVG
                    path={
                      minusDisabled
                        ? "/site/icon/circle-minus-gray"
                        : "/site/icon/circle-minus"
                    }
                    alt="Circle Minus"
                  />
                </button>

                <span className="has-text-centered">
                  {guestsInRoom} Guest{pluralize(guestsInRoom)}
                </span>

                <button
                  className="button is-ghost is-circle"
                  disabled={plusDisabled}
                  onClick={() =>
                    setRoomGuests(
                      roomGuests.map((g, idx) => (idx === roomIdx ? g + 1 : g))
                    )
                  }
                >
                  <SVG
                    path={
                      plusDisabled
                        ? "/site/icon/circle-plus-gray"
                        : "/site/icon/circle-plus"
                    }
                    alt="Circle Plus"
                  />
                </button>
              </Fragment>
            );
          })}
          <div className="margin-top-2">
            <button
              className="button is-ghost is-link is-paddingless"
              disabled={
                totalRooms === DANNO_MAX_ROOMS ||
                totalGuests === DANNO_MAX_GUESTS
              }
              onClick={() =>
                setRoomGuests([
                  ...roomGuests,
                  totalGuests === DANNO_MAX_GUESTS - 1 ? 1 : 2,
                ])
              }
            >
              Add a Room
            </button>
          </div>
          <div className="is-grid-column-2-neg-1 margin-top-2">
            <button
              className="button is-ghost is-link is-paddingless"
              disabled={totalRooms === 1}
              onClick={() => setRoomGuests(roomGuests.slice(0, -1))}
            >
              Remove a Room
            </button>
          </div>
        </div>

        <p className="has-border-top-gray padding-top-2 margin-top-2">
          Double rooms have two beds. Single rooms are limited. Triple rooms
          usually have two beds. Children welcome on all tours, if age five or
          older.
        </p>

        <div className="is-flex">
          <button
            className="button is-light-blue is-outlined margin-right-4"
            onClick={handleToggle}
          >
            Cancel
          </button>
          <button
            className="button is-light-blue is-flex-1"
            onClick={async () => {
              await onSave(roomGuests);
              setIsOpen(false);
            }}
          >
            Apply Change
          </button>
        </div>
      </div>
    </div>
  );
};

interface OtherOptionsProps {
  onClose: () => void;
  onSeriesClick: (seriesCode: string) => void;
  onTourClick: (tourId: TourDO_ID, departedAt: string) => Promise<void>;
  otherTours: TourDO_ID[];
  series: SeriesDO_Availability[];
  unavailableDate: string;
  unavailableTour: TourDO_ID;
}

const OtherOptions: FC<OtherOptionsProps> = ({
  onClose,
  onSeriesClick,
  onTourClick,
  otherTours,
  series,
  unavailableDate,
  unavailableTour,
}) => {
  const year = yearFromDate(unavailableDate);
  const tour = db.tour.findByIdOrFail(unavailableTour) as TourDO;
  const tourYear = db.tourYear.findByIdOrFail({
    tour_id: unavailableTour,
    year,
  }) as TourYearDO;

  return (
    <aside
      className="is-absolute has-background-white padding-4 has-shadow-gray is-z-index-1 is-width-max-content is-centered-x"
      style={{ top: 20 }}
    >
      <h3 className="is-flex is-align-items-center is-marginless padding-bottom-3 is-size-2">
        <span className="is-flex-1">
          {DateTime.fromISO(unavailableDate).toFormat(MMM_d_yyyy)}
        </span>
        <button className="button is-light-blue is-outlined" onClick={onClose}>
          Close
        </button>
      </h3>
      <div
        className={clsx(
          "has-border-top-gray padding-top-3",
          otherTours.length && "padding-bottom-3"
        )}
      >
        <h3 className="is-marginless is-size-2">
          {tour.name}, {durationDays(tourYear)} days
        </h3>
        <ul className="has-list-style-type-none is-marginless is-paddingless">
          {series.map(({ code, inventory, available }) => (
            <li key={code} className="margin-top-3">
              <button
                className="button is-ghost is-link is-paddingless is-size-2"
                disabled={!available}
                onClick={() => onSeriesClick(code)}
              >
                <DepartureCapacity code={code} inventory={inventory} />
              </button>
            </li>
          ))}
        </ul>
      </div>
      {!!otherTours.length && (
        <div className="has-border-top-gray">
          <h3 className="margin-top-3 margin-bottom-0 is-size-2">
            Other Tours Available:
          </h3>
          <ul className="has-list-style-type-none is-marginless is-paddingless">
            {otherTours.map((tourId) => {
              const tour = db.tour.findByIdOrFail(tourId) as TourDO;
              const tourYear = db.tourYear.findByIdOrFail({
                tour_id: tourId,
                year,
              }) as TourYearDO;

              return (
                <li key={tourId} className="margin-top-3">
                  <button
                    className="button is-ghost is-link is-paddingless is-size-2"
                    onClick={() => onTourClick(tourId, unavailableDate)}
                  >
                    {tour.name}, {durationDays(tourYear)} days
                  </button>
                </li>
              );
            })}
          </ul>
        </div>
      )}
    </aside>
  );
};

interface DepartureCapacityProps {
  code?: string;
  inventory: DepartureDO_Availability;
}

const DepartureCapacity: FC<DepartureCapacityProps> = ({ code, inventory }) => {
  const capacity = (
    <>
      {inventory.pax} Seat
      {pluralize(inventory.pax)}, {inventory.rooms} Room
      {pluralize(inventory.rooms)}
      {inventory.singles !== undefined && (
        <>
          , {inventory.singles} Single{pluralize(inventory.singles)}
        </>
      )}
    </>
  );

  if (!code) return capacity;

  return (
    <>
      <strong className="has-text-left padding-right-2">{code}</strong>
      <strong>{capacity}</strong>
    </>
  );
};

interface SeriesPickerProps {
  defaultCode: string;
  onCancel: () => void;
  onConfirm: (code: string) => void;
  series: SeriesDO_Availability[];
}

const SeriesPicker: FC<SeriesPickerProps> = ({
  defaultCode,
  onCancel,
  onConfirm,
  series,
}) => {
  const [currCode, setCurrCode] = useState(defaultCode);

  return (
    <aside
      className="is-absolute has-background-white padding-4 has-shadow-gray is-z-index-1 is-width-max-content is-centered-x"
      style={{ top: 20 }}
    >
      <h3 className="margin-top-2 margin-bottom-4 has-text-weight-normal is-size-2">
        Available series{" "}
        <span className="is-underlined">({defaultCode} is recommended)</span>:
      </h3>

      {series.map(({ code, inventory }) => (
        <Radio
          id={`series-${code}`}
          key={code}
          value={code}
          checked={code === currCode}
          onChange={(e) => setCurrCode(e.currentTarget.value)}
          parentClassName="margin-top-3 is-size-2"
          labelClassName="is-flex"
          inline={false}
        >
          <DepartureCapacity code={code} inventory={inventory} />
        </Radio>
      ))}

      <div className="is-flex margin-top-4">
        <button
          onClick={onCancel}
          className="button is-light-blue is-outlined margin-right-2"
        >
          Cancel
        </button>
        <button
          onClick={() => onConfirm(currCode)}
          className="button is-light-blue is-flex-1"
        >
          Continue
        </button>
      </div>
    </aside>
  );
};

const TourPlaceholder = () => (
  <>
    <div className="is-flex">
      <figure className="is-aspect-ratio-golden is-flex-1 has-background-tint-gray margin-right-2" />
      <figure className="is-aspect-ratio-golden is-flex-1 has-background-tint-gray" />
    </div>

    <p className="is-size-2 margin-top-4 margin-bottom-3">
      <strong>Tour:</strong>
      <br />
      <strong>Starts:</strong>
      <br />
      <strong>City:</strong>
      <br />
      <strong>Airport:</strong>
      <br />
      <strong>Hotel:</strong>
      <br />
    </p>

    <p className="is-size-2 is-marginless">
      <strong>Ends:</strong>
      <br />
      <strong>City:</strong>
      <br />
      <strong>Airport:</strong>
      <br />
      <strong>Hotel:</strong>
      <br />
    </p>
  </>
);

interface TourDetailsProps {
  departedAt: string;
  departure: DepartureDO;
  tour: TourDO;
  tourYear: TourYearDO;
  warnOverlap: boolean;
  warnSameTour: boolean;
}

const TourDetails: FC<TourDetailsProps> = ({
  departedAt,
  departure,
  tour,
  tourYear,
  warnOverlap,
  warnSameTour,
}) => {
  const arrivalTransferInfo =
    departure.transfers?.arrival ?? tourYear.transfers.arrival;
  const departureTransferInfo =
    departure.transfers?.departure ?? tourYear.transfers.departure;

  return (
    <>
      <div className="is-flex">
        <figure className="is-aspect-ratio-golden is-flex-1 has-background-tint-gray margin-right-2">
          <Image path={tour.page["headerA(img_id)"]} alt="headerA" />
        </figure>
        <figure className="is-aspect-ratio-golden is-flex-1 has-background-tint-gray">
          <Image path={tour.page["headerB(img_id)"]} alt="headerB" />
        </figure>
      </div>

      <article className="is-size-2 is-grid is-column-gap-2 is-grid-template-columns-auto-1fr margin-top-4 margin-bottom-3">
        <strong>Tour:</strong>
        <strong className={clsx(warnSameTour && "has-text-red")}>
          {tour.name}
        </strong>
        <strong>Starts:</strong>
        <span>
          <strong className={clsx(warnOverlap && "has-text-red")}>
            {DateTime.fromISO(departedAt).toFormat(ccc_MMM_d_yyyy)}
          </strong>{" "}
          <DaysLeft
            departedAt={departedAt}
            timezoneBegin={tourYear.timezoneBegin}
          />
        </span>
        <strong>City:</strong>
        <span>
          {tourYear.cityStart}, {tourYear.countryStart}
        </span>
        <strong>Airport:</strong>
        <span>
          {trimAirportName(airportsById[arrivalTransferInfo.airport_id]!.name)}{" "}
          ({arrivalTransferInfo.airport_id})
        </span>
        <strong>Hotel:</strong>
        <span>{hotelOnFirstDay(hotelsById, tourYear, departure)}</span>
      </article>

      <article className="is-size-2 is-grid is-column-gap-2 is-grid-template-columns-auto-1fr">
        <strong>Ends:</strong>
        <span>
          {DateTime.fromISO(departure.concludedAt).toFormat(ccc_MMM_d_yyyy)} (
          {durationDays(tourYear)} day tour)
        </span>
        <strong>City:</strong>
        <span>
          {tourYear.cityEnd}, {tourYear.countryEnd}
        </span>
        <strong>Airport:</strong>
        <span>
          {trimAirportName(
            airportsById[departureTransferInfo.airport_id]!.name
          )}{" "}
          ({departureTransferInfo.airport_id})
        </span>
        <strong>Hotel:</strong>
        <span>{hotelOnLastDay(hotelsById, tourYear, departure)}</span>
      </article>
    </>
  );
};

const priceDetailsStyle = {
  // Meant to be %, but %s cause overflow https://stackoverflow.com/a/64592456
  gridTemplateColumns: "45px 86px 60px 69px",
};

interface PricingDetailsProps {
  priceName?: TourYearDO_PriceName;
  roomGuests: number[];
  tourYear: TourYearDO | null;
}

const PriceDetails: FC<PricingDetailsProps> = ({
  priceName,
  roomGuests,
  tourYear,
}) => {
  const isDateSelected = !!(priceName && tourYear);
  let guestNumber = 0;
  const totalGuests = totalGuestsFrom(roomGuests);

  const depositTotal = DEPOSIT_AMOUNT * totalGuests;
  const tppTotal = TPP_AMOUNT * totalGuests;
  let total = 0;

  return (
    <>
      <PriceDetailsTable
        className="padding-y-2 padding-x-4"
        style={priceDetailsStyle}
      >
        <PriceDetailsHeader />
      </PriceDetailsTable>

      <div
        className="padding-x-4 padding-bottom-2 has-border-y-gray is-flex-1 is-size-2"
        // @ts-expect-error https://stackoverflow.com/a/43912420
        style={{ maxHeight: "calc(100vh - 508px)", overflowY: "overlay" }}
      >
        <PriceDetailsTable style={priceDetailsStyle}>
          {roomGuests.map((guestsInRoom) => {
            const roomType = roomTypeFrom(guestsInRoom);

            return arrOfSize(guestsInRoom).map((_, idx) => {
              guestNumber += 1;

              let tourPrice = 0;
              let taxes = 0;

              if (isDateSelected) {
                const { prices, taxesAndFees } = pricesByValue(
                  tourYear,
                  priceName
                );
                tourPrice = prices[roomType]! * 100;
                taxes = taxesAndFees[roomType]! * 100;
                total += tourPrice + taxes;
              }

              return (
                <PriceDetailsRow
                  key={guestNumber}
                  guestIdx={idx}
                  guestNumber={guestNumber}
                  roomType={roomType}
                  taxes={taxes}
                  tourPrice={tourPrice}
                  tppPrice={TPP_AMOUNT}
                />
              );
            });
          })}
        </PriceDetailsTable>
      </div>

      <PriceDetailsTable
        className="padding-top-2 padding-x-4 padding-bottom-4 is-size-2"
        style={priceDetailsStyle}
      >
        <PriceDetailsTotal total={total} tppTotal={tppTotal} />

        <PriceDetailsFooter
          depositTotal={depositTotal}
          finalTotal={total - depositTotal}
          totalGuests={totalGuests}
          tppTotal={tppTotal}
        />
      </PriceDetailsTable>
    </>
  );
};

interface DueDatesProps {
  departedAt: string;
  seriesCode: string;
}

const fullDateFormat = "ccc MMM d yyyy, h:mm a ZZZZ";

const DueDates: FC<DueDatesProps> = ({ departedAt, seriesCode }) => {
  if (!(departedAt && seriesCode)) {
    return (
      <>
        <strong>Deposit:</strong>
        <span>Due within</span>
        <strong>Final Payment:</strong>
        <span>Due by</span>
      </>
    );
  }

  const now = nowInCT();
  const nowISO = now.toUTC().toISO();

  const expiredAt = holdExpiredAt(departedAt, nowISO);
  const depositDueBy = expiredAt;
  const finalDueBy = finalPaymentDueBy(departedAt, nowISO);

  return isSubjectToFullPayment(departedAt, nowISO) ? (
    <>
      <strong>Full Payment:</strong>
      <span>
        Due within {depositDueBy.diff(now, "hours").hours} hours:{" "}
        {lowerCaseMeridiem(depositDueBy.toFormat(fullDateFormat))}
      </span>
    </>
  ) : (
    <>
      <strong>Deposit:</strong>
      <span>
        Due within {depositDueBy.diff(now, "hours").hours} hours:{" "}
        {lowerCaseMeridiem(depositDueBy.toFormat(fullDateFormat))}
      </span>
      <strong>Final Payment:</strong>
      <span>
        Due by {lowerCaseMeridiem(finalDueBy.toFormat(fullDateFormat))} (
        {DAYS_TO_PAY_FULL} days prior to start of tour)
      </span>
    </>
  );
};

const isOverlapping = (
  hold: BookingDO,
  tourStarts: string,
  tourYear: TourYearDO
) => {
  const holdTourYear = db.tourYear.findByIdOrFail({
    tour_id: hold.tour,
    year: yearFromDate(hold.departedAt),
  }) as TourYearDO;

  return areDatesOverlapping(
    // current hold
    hold.departedAt,
    hold.departure.concludedAt,
    holdTourYear.singleNightStay,
    // pending hold
    tourStarts,
    tourEnds(tourStarts, tourYear),
    tourYear.singleNightStay
  );
};

export const areDatesOverlapping = (
  // Current hold
  departedAt: string,
  concludedAt: string,
  singleNightStay: boolean,
  // Pending hold
  tourStarts: string,
  tourEnds: string,
  tourSingleNightStay: boolean
) => {
  const busDepartedAt = busDeparts(singleNightStay, departedAt); // departedAt OR (departedAt + 1 day)
  const tourBusDeparts = busDeparts(tourSingleNightStay, tourStarts); // tourStarts OR (tourStarts + 1 day)
  return (
    (tourBusDeparts >= busDepartedAt && tourBusDeparts < concludedAt) ||
    (tourEnds > busDepartedAt && tourEnds < concludedAt)
  );
};

const pricesByDateFrom = (availability: DannoAvailabilityRes) => {
  const pricesByDate: DepartureDO_PricesByDate = {};

  for (const departedAt in availability) {
    // Same-date departures across series share pricing, so any index will do.
    pricesByDate[departedAt] = availability[departedAt].departures[0].price;
  }

  return pricesByDate;
};
