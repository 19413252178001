import { arrOfSize, isLastItem } from "data-model";
import { FC, Fragment } from "react";
import clsx from "clsx";

export type RoomMode = 0 | 1 | 2; // 0 => peek, 1 => read, 2 => write

interface Props {
  activeMode?: RoomMode;
  onChange: (mode: RoomMode) => void;
}

const RoomControls: FC<Props> = ({ activeMode, onChange }) => (
  <div className="has-border-mid-gray is-flex is-rounded-small margin-right-3">
    {arrOfSize(3).map((_, idx, arr) => (
      <Fragment key={idx}>
        <button
          className={clsx(
            "button is-paddingless is-borderless is-square stack-button",
            idx === activeMode && "is-tint-gray"
          )}
          onClick={() => onChange(idx as RoomMode)}
        >
          <RoomControl
            items={arr.length - idx + 1}
            color={idx === activeMode ? "black" : "light-blue"}
          />
        </button>
        {!isLastItem(idx, arr.length) && (
          <span className="has-border-right-mid-gray" />
        )}
      </Fragment>
    ))}
  </div>
);

export { RoomControls };

interface RoomControlProps {
  items?: number;
  color?: "light-blue" | "black"; // has-border-bottom-light-blue or has-border-bottom-black
}

const RoomControl: FC<RoomControlProps> = ({
  items = 2,
  color = "light-blue",
}) => (
  <span
    className={`is-flex is-flex-direction-column has-border-${color} stack`}
  >
    {arrOfSize(items - 1).map((_, idx) => (
      <span key={idx} className={`has-border-bottom-${color} is-flex-1`} />
    ))}
    <span className="is-flex-1" />
  </span>
);
