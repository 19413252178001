import { EmailEditor } from "./EmailEditor";
import { logOut } from "../api";
import { LOGIN_KEY, LOG_OUT, useDannoContext } from "../state";
import { useMessage, SVG, useModal } from "react-components";
import { MouseEvent } from "react";
import { Link, NavLink, useNavigate, useSearchParams } from "react-router-dom";
import clsx from "clsx";

const travelAgentSubject = "Special Notice to Travel Agents";
const travelAgentBody = `
Over the years, Caravan has enjoyed a long and successful relationship with many knowledgeable and dedicated Travel Agents in the US and around the world. Beginning with all tours operating in 2023 Caravan will no longer offer commission to Travel Agents. We thank you for your hard work and collaboration and we wish you good luck in the future.

All tour reservations will be handled directly between Caravan and our travelers.

Sincerely,
Caravan
`.trim();

const Header = () => {
  const setModal = useModal();
  const [{ login }, dispatch] = useDannoContext();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [, setMessage] = useMessage();

  const handleLogout = async (
    e: MouseEvent<HTMLAnchorElement, globalThis.MouseEvent>
  ) => {
    e.preventDefault();

    try {
      await logOut();

      localStorage.removeItem(LOGIN_KEY);

      dispatch({ type: LOG_OUT });

      navigate("/login");
    } catch (e: unknown) {
      const err = (e instanceof Response ? await e.json() : e).message;
      setMessage({ children: err });
      console.error(e);
    }
  };

  const handleTrainingModalOpen = () =>
    setModal({
      isOpen: true,
      body: <p>New training video will be available later this Fall 2023.</p>,
    });

  const handleTravelAgentModalOpen = () =>
    setModal({
      isOpen: true,
      body: (
        <EmailEditor
          defaultBody={travelAgentBody}
          defaultSubject={travelAgentSubject}
          onClose={handleModalClose}
        />
      ),
    });

  const handleModalClose = () => setModal({ isOpen: false, body: null });

  return (
    <header className="has-background-blue is-flex is-justify-content-space-between is-align-items-center padding-x-4">
      <div className="is-flex is-align-items-center">
        <Link to="/">
          <SVG path="site/logo/caravan/white" alt="Caravan" height={37} />
        </Link>
        {login && (
          <>
            <button
              className="button is-ghost has-text-white is-paddingless margin-left-8"
              onClick={handleTrainingModalOpen}
            >
              Training
            </button>
            <a
              href="mailto:developers@caravan.com?subject=Danno%20Feedback"
              className="margin-left-6 has-text-white is-ghost"
            >
              <strong>Feedback?</strong>
            </a>
            <button
              className="button is-ghost has-text-white is-paddingless margin-left-6"
              onClick={handleTravelAgentModalOpen}
            >
              Travel Agent?
            </button>
          </>
        )}
      </div>
      <nav className="is-flex is-size-2">
        {login ? (
          <>
            {[
              ["/check-availability", "Accounts & Availability"],
              ["/manage-booking", "Manage Booking"],
            ].map(([url, text], idx) => (
              <NavLink
                key={idx}
                className={({ isActive }) =>
                  clsx(
                    "is-ghost padding-x-4 padding-y-2",
                    isActive
                      ? "has-background-tint-gray has-text-black"
                      : "has-text-white"
                  )
                }
                to={`${url}?${searchParams.toString()}`}
              >
                <strong>{text}</strong>
              </NavLink>
            ))}
            <a
              href="#"
              className="is-ghost padding-2 padding-left-4 has-text-white"
              onClick={handleLogout}
            >
              <strong>Log Out</strong>
            </a>
          </>
        ) : (
          <Link className="is-ghost padding-2 has-text-white" to="/login">
            <strong>Login</strong>
          </Link>
        )}
      </nav>
    </header>
  );
};

export { Header };
