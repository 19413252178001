import { OptionDivider } from "./OptionDivider";

const PopularCountries = () => (
  <>
    <option value="US">United States</option>
    <option value="CA">Canada</option>
    <option value="GB">United Kingdom</option>
    <option value="AU">Australia</option>
    <OptionDivider />
  </>
);

export { PopularCountries };
