import { db } from "../../jsongo";
import { AddressInfo } from "../../components";
import {
  BookingDO,
  ccc_MMM_d_yyyy,
  fullName,
  guestsRooms,
  TourDO,
  TourYearDO,
  yearFromDate,
} from "data-model";
import { statusForHumans, SVG } from "react-components";
import { FC, PropsWithChildren, ReactNode } from "react";
import clsx from "clsx";
import { DateTime } from "luxon";

interface Props extends PropsWithChildren {
  booking: BookingDO;
  button?: ReactNode;
  className?: string;
  withParty?: boolean;
}

const BookingInfo: FC<Props> = ({
  booking,
  button,
  children,
  className,
  withParty = false,
}) => {
  const tour = db.tour.findByIdOrFail(booking.tour) as TourDO;
  const tourYear = db.tourYear.findByIdOrFail({
    tour_id: booking.tour,
    year: yearFromDate(booking.departedAt),
  }) as TourYearDO;
  const account = booking.owner;

  const { label, description } = statusForHumans(booking, tourYear, true);

  return (
    <div
      className={clsx(
        "is-grid is-grid-template-columns-auto-1fr is-column-gap-2",
        className
      )}
    >
      <h2 className="is-grid-column-2-neg-1 is-size-1 is-marginless">
        {tour.name}
      </h2>
      <span>Starts:</span>
      <span>
        <strong>
          {DateTime.fromISO(booking.departedAt).toFormat(ccc_MMM_d_yyyy)}
        </strong>
        , {tourYear.cityStart}
      </span>
      <span>Ends:</span>
      <span>
        {DateTime.fromISO(booking.departure.concludedAt).toFormat(
          ccc_MMM_d_yyyy
        )}
        , {tourYear.cityEnd}
      </span>
      <span>Layout:</span>
      <span>{guestsRooms(booking.roomGuests)}</span>
      {withParty && booking.party && (
        <>
          <span>Party:</span>
          <span>{booking.party.name}</span>
        </>
      )}
      <span>Status:</span>
      <span>
        {label} {description}
      </span>

      {children}

      <h2 className="is-grid-column-2-neg-1 is-size-1 is-marginless is-flex margin-top-3">
        <SVG
          path="/site/icon/person-black"
          alt="Person"
          height={18}
          className="margin-right-1"
        />
        {fullName(account)}
      </h2>
      <span>Mobile:</span>
      <span>{account.phoneNumber}</span>
      <span>Email:</span>
      <span>{account.email}</span>
      <span>Address:</span>
      <span>
        <AddressInfo {...account.address} />
      </span>

      {button && (
        <div className="is-grid-column-2-neg-1 margin-top-3">{button}</div>
      )}
    </div>
  );
};

export { BookingInfo };
