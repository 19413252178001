import { SVG } from "react-components";
import { CSSProperties, FC, PropsWithChildren } from "react";

interface Props extends PropsWithChildren {
  bodyClassName?: string;
  onClose: () => void;
  style?: CSSProperties;
  title?: string;
}

const Popup: FC<Props> = ({
  bodyClassName,
  children,
  onClose,
  style,
  title,
}) => (
  <div
    className="is-absolute is-z-index-1 has-background-white has-shadow-gray is-top-0 is-left-0 is-right-0 margin-x-auto"
    style={style}
  >
    <div className="is-flex padding-top-4 padding-x-4">
      {title && <h2 className="is-marginless">{title}</h2>}
      <button
        className="button is-ghost is-paddingless is-borderless margin-left-auto"
        onClick={onClose}
      >
        <SVG path="/site/icon/close-light-blue" alt="Close" height={20} />
      </button>
    </div>
    <div className={bodyClassName}>{children}</div>
  </div>
);

export { Popup };
