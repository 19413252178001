import { AddressDO, countriesByCode, regionsByCountryCode } from "data-model";
import { FC } from "react";

const AddressInfo: FC<AddressDO> = ({
  line1,
  line2,
  city,
  state,
  country,
  zip,
}) => {
  const stateName = country && state && regionsByCountryCode[country][state];
  const countryName = country && countriesByCode[country];

  const firstLine = [line1, line2, city].filter(Boolean).join(", ");

  return (
    <>
      {firstLine}
      {firstLine && <br />}
      {[stateName, zip, countryName].filter(Boolean).join(", ")}
    </>
  );
};

export { AddressInfo };
