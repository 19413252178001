import { CheckOrAlert, RoomProps } from "./CollapsedRoom";
import { RoomControls } from "./RoomControls";
import { AddressInfo } from "../../components";
import { hotelsById } from "../../jsongo";
import {
  areNameAndDetailsComplete,
  cardBrandLetter,
  CENTRAL_TZ,
  countriesByCode,
  feeLabel,
  feePayment,
  fullNameOrPlaceholder,
  isAddressComplete,
  isFeeWaived,
  isGuestAccountOwner,
  MMM_d_yyyy,
  passportMustExpireAfter,
  passportRequiresRenewal,
  primaryGuestInfo,
  roomAbbrCapitalized,
  roomRequestBelongsTo,
  RoomRequestDO_Type,
  roomRequestLabel,
  toSorted,
} from "data-model";
import { PassportExpires, SVG, TransportInfo } from "react-components";
import { FC, Fragment } from "react";
import clsx from "clsx";
import { DateTime } from "luxon";

const alertSvg = <SVG path="/site/icon/alert" alt="Alert" height={19} />;

const checkmarkSvg = (
  <SVG path="/site/icon/checkmark-circled-green" alt="Checkmark" height={19} />
);

const roomReqTypes = [
  RoomRequestDO_Type.CONNECTED_OR_ADJOINING,
  RoomRequestDO_Type.BED_TYPE,
  RoomRequestDO_Type.STAIR_FREE,
];

const ExpandedRoom: FC<RoomProps> = ({
  booking,
  guests,
  onModeChange,
  posIdx,
  roomIdx,
  roomMode,
  tourYear,
}) => {
  const guestsInRoom = booking.roomGuests[roomIdx];
  const { primaryPosIdx, primaryGuest, primaryAddress } = primaryGuestInfo(
    booking.guests,
    booking.owner
  );
  const primaryName = fullNameOrPlaceholder(
    booking.guests,
    booking.owner,
    primaryPosIdx
  );
  const sameAsPrimary = `Same as ${primaryName}`;

  const passportMustExpAfter = passportMustExpireAfter(
    booking.departure.concludedAt
  );

  return (
    <>
      <div className="padding-2 has-background-white is-flex is-align-items-center is-grid-column-1-neg-1">
        <RoomControls activeMode={roomMode} onChange={onModeChange} />
        <h3 className="is-marginless is-size-2 margin-right-3">
          Room {roomIdx + 1} &ndash; {roomAbbrCapitalized(guestsInRoom)}
        </h3>
        {toSorted(
          booking.roomRequests,
          (a, b) => roomReqTypes.indexOf(a.type) - roomReqTypes.indexOf(b.type)
        ).map((req) =>
          roomRequestBelongsTo(req, roomIdx) ? (
            <span className="margin-right-3" key={req.id}>
              {roomRequestLabel({
                type: req.type,
                refRoomIndex:
                  req.roomIndex === roomIdx
                    ? req.refRoomIndex // direct connection
                    : req.roomIndex, // inverse connection
                selection: req.selection,
              })}
            </span>
          ) : null
        )}
      </div>
      {guests.map((guest) => {
        const isOwner = isGuestAccountOwner(
          booking.guests,
          booking.owner,
          posIdx
        );
        const isPrimary = posIdx === primaryPosIdx;

        const phoneNumber =
          guest.phoneNumber ?? (isOwner ? booking.owner.phoneNumber : "");
        const email = guest.email ?? (isOwner ? booking.owner.email : "");
        const address = guest.address || primaryAddress;
        const isAddressIncomplete = !address || !isAddressComplete(address);
        const contact = guest.emergencyContact ?? primaryGuest.emergencyContact;
        const isUnder18 = !!guest.age;

        const arrival = guest.arrival || primaryGuest.arrival;
        const departure = guest.departure || primaryGuest.departure;

        const mustRenewPassport = passportRequiresRenewal(
          passportMustExpAfter,
          guest.passportExpiredAt
        );

        return (
          <Fragment key={guest.id}>
            <div className="padding-2 has-background-white">
              <div className="is-grid is-grid-template-columns-auto-1fr-auto is-align-items-center is-row-gap-1 is-column-gap-2">
                <h4 className="is-marginless is-flex is-grid-column-1-3">
                  {isOwner && (
                    <SVG
                      path="/site/icon/person-black"
                      alt="Person"
                      height={16}
                      className="margin-right-1"
                    />
                  )}
                  {fullNameOrPlaceholder(
                    booking.guests,
                    booking.owner,
                    posIdx++
                  )}
                </h4>
                <span>
                  {areNameAndDetailsComplete(
                    guest,
                    tourYear,
                    booking.departure.concludedAt,
                    primaryGuest,
                    primaryAddress
                  ) && checkmarkSvg}
                </span>

                <span>Mobile:</span>
                <span>{phoneNumber}</span>
                <span>{!phoneNumber && !isUnder18 && alertSvg}</span>

                <span>Email:</span>
                <span>{email}</span>
                <span>{!email && !isUnder18 && alertSvg}</span>

                <span className="is-align-self-flex-start">Address:</span>
                <span>
                  {!isPrimary && address?.id === primaryAddress?.id
                    ? sameAsPrimary
                    : address && <AddressInfo {...address} />}
                </span>
                <span>{isAddressIncomplete && alertSvg}</span>

                <span>Contact:</span>
                <span>
                  {!isPrimary && contact === primaryGuest.emergencyContact
                    ? sameAsPrimary
                    : contact}
                </span>
                <span>{!contact && alertSvg}</span>

                {guest.age && (
                  <>
                    <span>Age:</span>
                    <span className="is-grid-column-2-neg-1">{guest.age}</span>
                  </>
                )}

                {guest.specialRequest && (
                  <>
                    <span>Request:</span>
                    <span className="is-grid-column-2-neg-1">
                      {guest.specialRequest}
                    </span>
                  </>
                )}

                {guest.accessibilityRequest && (
                  <>
                    <strong className="has-text-red">ADA:</strong>
                    <span className="is-grid-column-2-neg-1">
                      {guest.accessibilityRequest}
                    </span>
                  </>
                )}
              </div>
              {tourYear.requiresPassport && (
                <div className="is-grid is-grid-template-columns-auto-1fr-auto is-align-items-center is-row-gap-1 is-column-gap-2 margin-top-3">
                  <span>Passport Number:</span>
                  <span>{guest.passportNumber}</span>
                  <span>{!guest.passportNumber && alertSvg}</span>

                  <span>Citizenship:</span>
                  <span>
                    {guest.passportCountry &&
                      countriesByCode[guest.passportCountry]}
                  </span>
                  <span>{!guest.passportCountry && alertSvg}</span>

                  {(tourYear.crossesBorder || tourYear.includesFlights) && (
                    <>
                      <span>Date of Birth:</span>
                      <span>
                        {guest.passportBornAt &&
                          DateTime.fromISO(guest.passportBornAt).toFormat(
                            MMM_d_yyyy
                          )}
                      </span>
                      <span>{!guest.passportBornAt && alertSvg}</span>

                      <span>Passport Expiration:</span>
                      <span>
                        {guest.passportExpiredAt &&
                          DateTime.fromISO(guest.passportExpiredAt).toFormat(
                            MMM_d_yyyy
                          )}
                      </span>
                      <span>
                        {(!guest.passportExpiredAt || mustRenewPassport) &&
                          alertSvg}
                      </span>
                      {mustRenewPassport && (
                        <PassportExpires
                          className="is-grid-column-1-neg-1 margin-top-0"
                          mustExpireAfter={passportMustExpAfter}
                        />
                      )}
                    </>
                  )}
                </div>
              )}
            </div>
            <div className="padding-2 has-background-white">
              <TransportInfo
                departure={booking.departure}
                hotelsById={hotelsById}
                isArrival
                isSameAs={
                  !isPrimary && arrival?.id === primaryGuest.arrival?.id
                }
                primaryFullName={primaryName}
                tourYear={tourYear}
                transport={arrival}
              />

              <TransportInfo
                departure={booking.departure}
                className="margin-top-3"
                hotelsById={hotelsById}
                isArrival={false}
                isSameAs={
                  !isPrimary && departure?.id === primaryGuest.departure?.id
                }
                primaryFullName={primaryName}
                tourYear={tourYear}
                transport={departure}
              />
            </div>
            <div className="padding-2 has-background-white">
              <div
                className="is-grid is-row-gap-1 is-column-gap-2"
                style={{ gridTemplateColumns: `auto 1fr auto auto` }}
              >
                {guest.fees.map((fee) => {
                  const payment = feePayment(fee);
                  return (
                    <Fragment key={fee.id}>
                      <span className={clsx(!payment && "is-grid-column-1-3")}>
                        {feeLabel(fee.type, !!payment)}:
                      </span>
                      {payment && (
                        <em className="has-text-centered">
                          {DateTime.fromISO(payment.submittedAt, {
                            zone: CENTRAL_TZ,
                          }).toFormat(MMM_d_yyyy)}{" "}
                          ({cardBrandLetter(payment.cardBrand)}{" "}
                          {payment.cardLastFour})
                        </em>
                      )}
                      <strong className="has-text-right">
                        {fee.total / 100}
                      </strong>
                      <CheckOrAlert check={!!payment || isFeeWaived(fee)} />
                      {fee.discounts.map((discount) => (
                        <em
                          key={discount.id}
                          className="is-grid-column-2-neg-1 has-text-green"
                        >
                          {discount.type} of ${discount.amount / 100} applied
                        </em>
                      ))}
                    </Fragment>
                  );
                })}
              </div>
            </div>
          </Fragment>
        );
      })}
    </>
  );
};

export { ExpandedRoom };
