import { Popup } from "./Popup";
import { RoomControls, RoomMode } from "./RoomControls";
import { BookingFinder, dateParam, seriesParam } from "./BookingFinder";
import { BookingInfo } from "./BookingInfo";
import { CollapsedRoom } from "./CollapsedRoom";
import { ExpandedRoom } from "./ExpandedRoom";
import { EditableRoom } from "./EditableRoom";
import {
  BookingHistory,
  CancelOptions,
  ExtendDeadline,
  RefundTpp,
  ReplaceAccount,
  SendSurvey,
} from "./ActionMenu";
import { PartySelector } from "./PartySelector";
import { accountIdParam } from "../CheckAvailability";
import { airportsById, db, globalDO, travelDocumentsById } from "../../jsongo";
import {
  applyDiscount,
  findBooking,
  getFormToken,
  makeNote,
  reconfirmBooking,
  savePayment,
} from "../../api";
import {
  ActionMenu,
  buttonStyle,
  Notes,
  OptionDivider,
  Slider,
} from "../../components";
import { useDannoContext } from "../../state";
import {
  adjustmentFees,
  arrDelete,
  AuthCaptureTransactionRes,
  BookingDO,
  ccc_MMM_d_yyyy,
  CENTRAL_TZ,
  changesSince,
  confirmationTitle,
  confirmationUrl,
  depositFee,
  directionLowerFrom,
  DiscountDO_Type,
  FeeDO,
  FeeDO_Interlocks,
  FeeDO_Type,
  finalPaymentFee,
  formatCents,
  fullName,
  GuestDO,
  guestFee,
  guestsRooms,
  hasGuestFee,
  initFeesByGuest,
  isBookingAnnulled,
  isFeeOwed,
  isHoldOrBookingActive,
  isLastItem,
  isSurveyOpen,
  lastItem,
  mapPaymentResponse,
  MM_dd_yy,
  NoteDO,
  NoteDO_Type,
  primaryGuestPosIdx,
  RoomRequestDO,
  startingPosIdx,
  termsOfTravelDoc,
  toReversed,
  totalToPayNow,
  TourDO,
  TourYearDO,
  TransportDO,
  travelDocuments,
  travelDocumentUrl,
  travelProtectionDoc,
  unpaidFees,
  yearFromDate,
} from "data-model";
import {
  DottedHeading,
  ExternalLink,
  Image,
  PaymentForm,
  PriceDetails,
  Radio,
  statusForHumans,
  SVG,
  TransferInfo,
  useModal,
} from "react-components";
import {
  ChangeEvent,
  Dispatch,
  FC,
  PropsWithChildren,
  SetStateAction,
  useEffect,
  useState,
} from "react";
import { useSearchParams } from "react-router-dom";
import clsx from "clsx";
import { DateTime } from "luxon";

enum MenuOptions {
  reconfirmation = "reconfirmation",
  survey = "survey",
  rooming = "rooming",
  merge = "merge",
  split = "split",
  transfer = "transfer",
  replace = "replace",
  extend = "extend",
  refundTpp = "refundTpp",
  cancel = "cancel",
  history = "history",
  newSearch = "newSearch",
  searchHistory = "searchHistory",
}

export const bookingIdParam = "booking-id";

type BookingSetter = (booking: BookingDO) => BookingDO;
export type SetBooking = (cb: BookingSetter) => void;

const ManageBooking = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const setModal = useModal();

  const [booking, setBooking] = useState<BookingDO | null>(null);
  const [isManaged, setIsManaged] = useState(false);
  const tour = booking
    ? (db.tour.findByIdOrFail(booking.tour) as TourDO)
    : null;
  const tourYear = booking
    ? (db.tourYear.findByIdOrFail({
        tour_id: booking.tour,
        year: yearFromDate(booking.departedAt),
      }) as TourYearDO)
    : null;
  const tourChanges =
    tourYear && booking
      ? changesSince(db, tourYear._id, booking.createdAt)
      : [];
  const [masterMode, setMasterMode] = useState<RoomMode | undefined>(0);
  const [roomModes, setRoomModes] = useState<RoomMode[]>([]);
  const [isAirportTransfersOpen, setIsAirportTransfers] = useState(false);
  const [isPriceDetailsOpen, setIsPriceDetailsOpen] = useState(false);

  const [isLoading, setIsLoading] = useState(
    searchParams.has(bookingIdParam) ||
      searchParams.has(seriesParam) ||
      searchParams.has(dateParam)
  );

  const isActive =
    booking && tourYear && isHoldOrBookingActive(booking, tourYear.timezoneEnd);
  const isAnnulled = !!booking && isBookingAnnulled(booking);

  const handleAirportTransfersToggle = () =>
    setIsAirportTransfers(!isAirportTransfersOpen);

  const handlePriceDetailsToggle = () =>
    setIsPriceDetailsOpen(!isPriceDetailsOpen);

  useEffect(() => {
    const fetchBooking = async () => {
      const bookingId = searchParams.get(bookingIdParam);
      if (bookingId) {
        const booking = await findBooking(+bookingId);
        handleBookingManage(booking);
        setIsLoading(false);

        // Validate against missing or mismatching accountIdParam.
        if (searchParams.get(accountIdParam) !== `${booking.owner.id}`) {
          searchParams.set(accountIdParam, `${booking.owner.id}`);
          setSearchParams(searchParams);
        }
      }
    };
    fetchBooking();
  }, []);

  const handleBookingChange = (booking: BookingDO | null) => {
    setBooking(booking);
    setMasterMode(booking ? 1 : 0);
    setRoomModes(booking ? initRoomModes(booking, 1) : []);
  };

  const handleBookingManage = (booking: BookingDO) => {
    setBooking(booking);
    setIsManaged(true);
    setMasterMode(1);
    setRoomModes(initRoomModes(booking, 1));
  };

  const setBookingIfNotNull = (cb: BookingSetter) => {
    setBooking((booking) => {
      if (!booking) return null; // booking was cleared
      return cb(booking);
    });
  };

  const handleModalClose = () => setModal({ isOpen: false, body: null });

  return (
    <div
      className="is-flex-1 is-grid is-column-gap-4"
      style={{
        gridTemplateColumns: "28% 1fr auto",
      }}
    >
      <div className="is-flex is-flex-direction-column has-background-white has-border-gray">
        {booking && isManaged ? (
          <>
            <h1 className="is-flex is-align-items-center padding-2 is-marginless has-border-bottom-gray">
              <figure
                className="is-aspect-ratio-golden has-background-tint-gray margin-right-1"
                style={{ height: 30 }}
              >
                <Image path={tour!.page["headerA(img_id)"]} alt="headerA" />
              </figure>
              <figure
                className="is-aspect-ratio-golden has-background-tint-gray margin-right-2"
                style={{ height: 30 }}
              >
                <Image path={tour!.page["headerB(img_id)"]} alt="headerB" />
              </figure>
              <span className="is-flex-1">
                {booking.number}
                {!!tourChanges.length && (
                  <span title={lastItem(tourChanges)._id}> (Pre-Change)</span>
                )}
              </span>
              {isLoading && (
                <SVG
                  path="site/icon/spinner"
                  alt="Spinner"
                  className="margin-right-2"
                  height={20}
                />
              )}
              <ActionMenu
                onChange={async (e) => {
                  if (!booking) throw new Error("unreachable");
                  const action = e.currentTarget.value as MenuOptions;

                  if (action === MenuOptions.reconfirmation) {
                    setIsLoading(true);
                    const confirmation = await reconfirmBooking(booking.id);
                    setBookingIfNotNull((booking) => {
                      if (
                        booking.confirmations.some(
                          (c) => c.id === confirmation.id
                        ) // request was throttled
                      ) {
                        return booking;
                      }
                      return {
                        ...booking,
                        confirmations: [...booking.confirmations, confirmation],
                      };
                    });
                    setIsLoading(false);
                  } else if (action === MenuOptions.survey) {
                    setModal({
                      isOpen: true,
                      body: (
                        <SendSurvey
                          booking={booking}
                          onClose={handleModalClose}
                        />
                      ),
                      title: <h1 className="margin-bottom-0">Send a Survey</h1>,
                    });
                  } else if (action === MenuOptions.rooming) {
                    return;
                  } else if (action === MenuOptions.merge) {
                    return;
                  } else if (action === MenuOptions.split) {
                    return;
                  } else if (action === MenuOptions.transfer) {
                    return;
                  } else if (action === MenuOptions.replace) {
                    setModal({
                      isOpen: true,
                      body: (
                        <ReplaceAccount
                          booking={booking}
                          onClose={handleModalClose}
                          searchParams={searchParams}
                          setBooking={setBookingIfNotNull}
                          setSearchParams={setSearchParams}
                        />
                      ),
                      title: (
                        <h1 className="margin-bottom-0">
                          Replace Booking Owner
                        </h1>
                      ),
                    });
                  } else if (action === MenuOptions.extend) {
                    setModal({
                      isOpen: true,
                      body: (
                        <ExtendDeadline
                          booking={booking}
                          onClose={handleModalClose}
                          setBooking={setBookingIfNotNull}
                        />
                      ),
                    });
                  } else if (action === MenuOptions.refundTpp) {
                    setModal({
                      isOpen: true,
                      body: (
                        <RefundTpp
                          booking={booking}
                          onClose={handleModalClose}
                          setBooking={setBookingIfNotNull}
                        />
                      ),
                    });
                  } else if (action === MenuOptions.cancel) {
                    setModal({
                      isOpen: true,
                      body: (
                        <CancelOptions
                          booking={booking}
                          onClose={handleModalClose}
                          setBooking={setBookingIfNotNull}
                        />
                      ),
                    });
                  } else if (action === MenuOptions.history) {
                    setModal({
                      isOpen: true,
                      body: (
                        <BookingHistory
                          events={booking.events}
                          onClose={handleModalClose}
                        />
                      ),
                      title: (
                        <h1 className="margin-bottom-0 margin-right-2">
                          Booking History — {booking.number}
                        </h1>
                      ),
                    });
                  } else if (action === MenuOptions.newSearch) {
                    setBooking(null);
                    setIsManaged(false);
                    setMasterMode(0);
                    setRoomModes([]);
                    searchParams.delete(bookingIdParam);
                    // Note that if present, accountIdParam remains in the URL.
                    setSearchParams(searchParams);
                  } else if (action === MenuOptions.searchHistory) {
                    return;
                  }
                }}
              >
                <option disabled={!booking} value={MenuOptions.reconfirmation}>
                  Send a Reconfirmation
                </option>
                <option
                  disabled={
                    !booking ||
                    isAnnulled ||
                    !isSurveyOpen(booking.departure.concludedAt)
                  }
                  value={MenuOptions.survey}
                >
                  Send a Survey
                </option>
                <option disabled /*={!booking}*/ value={MenuOptions.rooming}>
                  Room Options
                </option>
                <option disabled /*={!booking}*/ value={MenuOptions.merge}>
                  Merge with Another Booking
                </option>
                <option disabled /*={!booking}*/ value={MenuOptions.split}>
                  Split Room(s) to a New Booking
                </option>
                <option disabled /*={!booking}*/ value={MenuOptions.transfer}>
                  Transfer this Booking
                </option>
                <option disabled={!booking} value={MenuOptions.replace}>
                  Replace Booking Owner
                </option>
                <option
                  disabled={!booking || !isActive}
                  value={MenuOptions.extend}
                >
                  Extend Hold or Final Payment
                </option>
                <option
                  disabled={!booking || !isActive}
                  value={MenuOptions.refundTpp}
                >
                  Refund Travel Protection
                </option>
                <option
                  disabled={!booking || isAnnulled}
                  value={MenuOptions.cancel}
                >
                  Cancel Booking Options
                </option>
                <option disabled={!booking} value={MenuOptions.history}>
                  Booking History
                </option>
                <OptionDivider length={33} />
                <option value={MenuOptions.newSearch}>
                  New Search (Clear Booking)
                </option>
                {/* <option disabled value={MenuOptions.searchHistory}>
                  Search History
                </option> */}
              </ActionMenu>
            </h1>
            <div className="is-flex-1">
              <BookingInfo
                booking={booking}
                className="padding-y-3 padding-x-2"
              >
                <div className="is-grid-column-2-neg-1 margin-top-1">
                  <PartySelector
                    booking={booking}
                    setBooking={setBookingIfNotNull}
                  />
                </div>
              </BookingInfo>
            </div>

            {noteTypes.map((type) => (
              <BookingNotes
                key={type}
                booking={booking}
                setBooking={setBookingIfNotNull}
                type={type}
              />
            ))}

            <PdfDocuments booking={booking} />

            <AutomatedTickets />
          </>
        ) : searchParams.has(bookingIdParam) ? null : (
          <div className="padding-2">
            <BookingFinder
              booking={booking}
              isLoading={isLoading}
              onBookingChange={handleBookingChange}
              onBookingManage={(booking) => {
                handleBookingManage(booking);
                searchParams.delete(seriesParam);
                searchParams.delete(dateParam);
                searchParams.set(bookingIdParam, `${booking.id}`);
                searchParams.set(accountIdParam, `${booking.owner.id}`);
                setSearchParams(searchParams);
              }}
              searchParams={searchParams}
              setIsLoading={setIsLoading}
              setSearchParams={setSearchParams}
            />
          </div>
        )}
      </div>

      <div
        className={clsx(
          "has-background-white has-border-gray is-flex is-flex-direction-column",
          !booking && "is-justify-content-space-between"
        )}
      >
        {booking && !isManaged && (
          <article className="padding-x-2 padding-y-1 has-border-bottom-gray is-flex is-justify-content-space-between is-align-items-center">
            <span>
              <strong>{tour!.name}</strong>
              <br />
              {guestsRooms(booking.roomGuests)}
            </span>
            <span>
              <strong>
                {DateTime.fromISO(booking.departedAt).toFormat(ccc_MMM_d_yyyy)}
              </strong>
              , {tourYear!.cityStart}
              <br />
              {DateTime.fromISO(booking.departure.concludedAt).toFormat(
                ccc_MMM_d_yyyy
              )}
              , {tourYear!.cityEnd}
            </span>
            <span>
              <span className="is-flex">
                {fullName(booking.owner)}
                <SVG
                  path="/site/icon/person-black"
                  alt="Person"
                  height={18}
                  className="margin-left-1"
                />
              </span>
              {booking.party && (
                <>
                  <strong>Party:</strong> {booking.party.name}
                </>
              )}
            </span>
            <span>
              {booking.owner.email}
              <br />
              {booking.owner.phoneNumber}
            </span>
            <span>
              <BookingStatus booking={booking} tourYear={tourYear!} />
            </span>
          </article>
        )}
        <div className="is-grid is-grid-template-columns-repeat-3-1fr is-align-items-center is-relative has-border-bottom-gray">
          <h2 className="margin-2 is-flex is-align-items-center">
            <RoomControls
              activeMode={masterMode}
              onChange={(newMode) => {
                setMasterMode(newMode);
                if (booking) {
                  setRoomModes(initRoomModes(booking, newMode));
                }
              }}
            />
            Guest Details
          </h2>
          <div className="margin-2 is-flex is-justify-content-space-between is-relative">
            <h2 className="is-marginless">Flight Details</h2>
            <button
              className="button is-ghost is-link is-paddingless"
              onClick={handleAirportTransfersToggle}
            >
              Airport Transfers
            </button>
            {isAirportTransfersOpen && booking && tourYear && (
              <Popup
                bodyClassName="padding-4"
                onClose={handleAirportTransfersToggle}
                title="Airport Transfers"
              >
                {[true, false].map((isArrival, idx) => (
                  <TransferInfo
                    key={idx}
                    airportsById={airportsById}
                    className="margin-top-0 margin-bottom-4"
                    departure={booking.departure}
                    isArrival={isArrival}
                    tourYear={tourYear}
                  />
                ))}

                <p className="is-marginless">
                  <em>Airfare is extra and not sold by Caravan.</em>
                </p>
              </Popup>
            )}
          </div>
          <div className="margin-2 is-flex is-justify-content-space-between is-relative">
            <h2 className="is-marginless">Payments</h2>
            <button
              className="button is-ghost is-link is-paddingless"
              onClick={handlePriceDetailsToggle}
            >
              Price Details
            </button>
            {isPriceDetailsOpen && booking && (
              <Popup
                bodyClassName="padding-4"
                onClose={handlePriceDetailsToggle}
                title="Price Details"
              >
                <PriceDetails
                  priceName={booking.departure.priceName}
                  roomGuests={booking.roomGuests}
                  tourYear={tourYear!}
                  withFooter
                />
              </Popup>
            )}
          </div>
        </div>
        {booking ? (
          <BookingManager
            key={booking.id}
            booking={booking}
            isManaged={isManaged}
            roomModes={roomModes}
            setBooking={setBookingIfNotNull}
            setRoomModes={setRoomModes}
            setMasterMode={setMasterMode}
            tourYear={tourYear!}
          />
        ) : (
          <div style={{ height: 132 }} className="has-border-top-gray" />
        )}
      </div>

      <Slider />
    </div>
  );
};

export { ManageBooking };

const initRoomModes = (booking: BookingDO, value: RoomMode = 0): RoomMode[] =>
  booking.roomGuests.map(() => value);

// Match the payment modal in Booking, where the iframe width is 335px,
// padding is 20px, and margin is 20px, totaling var(--iphone-6-width).
const paymentPopupStyle = { width: 375 };

interface BookingManager {
  booking: BookingDO;
  isManaged: boolean;
  roomModes: RoomMode[];
  setBooking: SetBooking;
  setMasterMode: Dispatch<React.SetStateAction<RoomMode | undefined>>;
  setRoomModes: Dispatch<SetStateAction<RoomMode[]>>;
  tourYear: TourYearDO;
}

const BookingManager: FC<BookingManager> = ({
  booking,
  isManaged,
  roomModes,
  setBooking,
  setMasterMode,
  setRoomModes,
  tourYear,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [fees, setFees] = useState(initFeesByGuest(booking.guests));
  const [formToken, setFormToken] = useState("");

  const grandTotal = totalToPayNow(fees, booking.guests);

  const isActive = isHoldOrBookingActive(booking, tourYear.timezoneEnd);

  const isMakingPayment = isLoading || !!formToken;

  const handleGuestDetailsChange = (guest: GuestDO) =>
    setBooking((b) => ({
      ...b,
      guests: b.guests.map((g) => {
        if (g.id === guest.id) {
          return {
            ...g,
            fullName: guest.fullName,
            phoneNumber: guest.phoneNumber,
            email: guest.email,
            address: guest.address,
            emergencyContact: guest.emergencyContact,
            age: guest.age,
            specialRequest: guest.specialRequest,
            accessibilityRequest: guest.accessibilityRequest,
            passportNumber: guest.passportNumber,
            passportCountry: guest.passportCountry,
            passportBornAt: guest.passportBornAt,
            passportExpiredAt: guest.passportExpiredAt,
          };
        }
        return g;
      }),
    }));

  const handleGuestTransportChange = (
    guestId: number,
    isArrival: boolean,
    transport?: TransportDO
  ) =>
    setBooking((b) => {
      const posIdx = b.guests.findIndex((g) => g.id === guestId);
      const primaryChanged = posIdx === primaryGuestPosIdx(b.guests, b.owner);
      const direction = directionLowerFrom(isArrival);

      return {
        ...b,
        guests: b.guests.map((g) => {
          if (
            g.id === guestId ||
            // When primary transport changes, sync up other guests with Same As
            (primaryChanged &&
              transport && // not possible to be false
              (!g[direction] || g[direction]!.id === transport.id))
          ) {
            return { ...g, [direction]: transport };
          }
          return g;
        }),
      };
    });

  const handleDiscountApply = async (
    posIdx: number,
    type: DiscountDO_Type,
    amount: number
  ) => {
    if (
      type === DiscountDO_Type.PrizeWinner ||
      type === DiscountDO_Type.SalesFamiliarization
    ) {
      // FIXME This is a more complex case. We need to redefine what
      // a "paid in full" booking means when all fees are zeroed out.
    } else {
      const guest = booking.guests[posIdx];
      const unpaidFP = [finalPaymentFee(guest), ...adjustmentFees(guest)].find(
        isFeeOwed
      );
      if (!unpaidFP) throw new Error("unreachable");
      const [updatedFee, discount] = await applyDiscount(unpaidFP.id, {
        type,
        amount,
      });

      setBooking((b) => ({
        ...b,
        guests: b.guests.map((g, gPosIdx) => {
          if (gPosIdx === posIdx) {
            return {
              ...g,
              fees: g.fees.map((f) => {
                if (f.id === updatedFee.id) {
                  return {
                    ...f,
                    amount: updatedFee.amount,
                    tax: updatedFee.tax,
                    total: updatedFee.total,
                    discounts: [...f.discounts, discount],
                  };
                }
                return f;
              }),
            };
          }

          return g;
        }),
      }));
    }
  };

  const handlePayAll = (posIdx: number) =>
    setFees((fees) =>
      fees.map((guestFees, guestPosIdx) => {
        if (guestPosIdx === posIdx) {
          const guest = booking.guests[posIdx];
          const feesDue = unpaidFees(guest, booking.departedAt, true);

          return guestFees.length === feesDue.length
            ? []
            : feesDue.map((fee) => fee.type);
        }
        return guestFees;
      })
    );

  const handleFeeChange = (posIdx: number, feeType: FeeDO_Type) => {
    setFees((fees) =>
      fees.map((guestFees, guestPosIdx) => {
        if (guestPosIdx === posIdx) {
          const newGuestFees = [...guestFees];
          const guest = booking.guests[posIdx];
          const isAdded = !newGuestFees.includes(feeType); // present => removed, not present => added
          const isFinalOrAdj = FeeDO_Interlocks.includes(feeType);

          if (isAdded) {
            // When TPP or final/adj is added, auto-add deposit (unless already purchased)
            if (
              (feeType === FeeDO_Type.TPP || isFinalOrAdj) &&
              !newGuestFees.includes(FeeDO_Type.DEPOSIT) &&
              isFeeOwed(depositFee(guest))
            ) {
              newGuestFees.push(FeeDO_Type.DEPOSIT);
            }

            // When final/adjustments are added, auto-add the dependent fees (if applicable)
            if (isFinalOrAdj) {
              for (const interlockedFee of FeeDO_Interlocks) {
                if (
                  hasGuestFee(guest, interlockedFee) &&
                  isFeeOwed(guestFee(guest, interlockedFee)) // just in case it was paid somehow (against the policy)
                ) {
                  newGuestFees.push(interlockedFee);
                }
              }
            } else {
              newGuestFees.push(feeType);
            }
          } else {
            // When deposit is removed, auto-remove TPP and final/adj (if present)
            if (feeType === FeeDO_Type.DEPOSIT) {
              for (const fee of [FeeDO_Type.TPP, ...FeeDO_Interlocks]) {
                arrDelete(newGuestFees, fee);
              }
            }

            // When final/adjustments are removed, auto-remove the dependent fees (if present)
            if (isFinalOrAdj) {
              for (const interlockedFee of FeeDO_Interlocks) {
                arrDelete(newGuestFees, interlockedFee);
              }
            } else {
              arrDelete(newGuestFees, feeType);
            }
          }
          return newGuestFees;
        }
        return guestFees;
      })
    );
  };

  const handleCheckout = async () => {
    setIsLoading(true);
    const { token } = await getFormToken(booking.id, fees);
    setFormToken(token);
    setIsLoading(false);
  };

  const handlePaymentClose = () => setFormToken("");

  const handlePaymentSuccess = async (res: AuthCaptureTransactionRes) => {
    const paymentReq = mapPaymentResponse(res);

    const payment = await savePayment(booking.id, {
      ...paymentReq,
      fees,
    });

    setBooking((b) => ({
      ...b,
      guests: b.guests.map((g, posIdx) => {
        return {
          ...g,
          fees: g.fees.map((f) => {
            if (fees[posIdx].includes(f.type)) {
              return {
                ...f,
                paymentLineItems: [
                  ...f.paymentLineItems,
                  {
                    fee: f.id as unknown as FeeDO,
                    payment,
                    amount: f.amount,
                    tax: f.tax,
                    total: f.total,
                  },
                ],
              };
            }
            return f;
          }),
        };
      }),
    }));

    setFees(initFeesByGuest(booking.guests));
  };

  const handleRequestChange = (request: RoomRequestDO, isAdded: boolean) =>
    setBooking((b) => ({
      ...b,
      roomRequests: isAdded
        ? [...b.roomRequests, request]
        : b.roomRequests.filter((r) => r.id !== request.id),
    }));

  return (
    <>
      {formToken && (
        <div className="is-relative">
          <Popup
            bodyClassName="padding-x-4 padding-bottom-4"
            onClose={handlePaymentClose}
            style={paymentPopupStyle}
          >
            <PaymentForm
              token={formToken}
              onClose={handlePaymentClose}
              onSuccess={handlePaymentSuccess}
            />
          </Popup>
        </div>
      )}
      <div
        className="is-flex-1 is-scrollable-y"
        style={{ maxHeight: `calc(100vh - ${isManaged ? 241 : 289}px)` }}
      >
        <div className="has-background-gray is-grid is-grid-template-columns-repeat-3-1fr is-gap-1px has-border-bottom-gray">
          {booking.roomGuests.map((guestsInRoom, roomIdx) => {
            const posIdx = startingPosIdx(booking.roomGuests, roomIdx);
            const roomMode = roomModes[roomIdx];
            const guests = booking.guests.slice(posIdx, posIdx + guestsInRoom);
            const props = {
              key: roomIdx,
              booking,
              guests,
              onModeChange: (newMode: RoomMode) => {
                const newRoomModes = roomModes.map((currMode, idx) =>
                  idx === roomIdx ? newMode : currMode
                );
                setMasterMode(
                  newRoomModes.every((mode) => mode === newMode)
                    ? newMode
                    : undefined
                );
                setRoomModes(newRoomModes);
              },
              posIdx,
              roomIdx,
              roomMode,
              tourYear,
            };

            return roomMode === 0 ? (
              <CollapsedRoom {...props} />
            ) : roomMode === 1 ? (
              <ExpandedRoom {...props} />
            ) : (
              <EditableRoom
                {...props}
                fees={fees}
                isMakingPayment={isMakingPayment}
                onDetailsChange={handleGuestDetailsChange}
                onDiscountApply={handleDiscountApply}
                onTransportChange={handleGuestTransportChange}
                onFeeChange={handleFeeChange}
                onPayAll={handlePayAll}
                onRequestChange={handleRequestChange}
              />
            );
          })}
        </div>
      </div>
      <div
        style={{ height: 132 }}
        className={clsx(
          "is-flex",
          isManaged &&
            "is-align-items-center is-justify-content-flex-end padding-x-5 has-border-top-gray"
        )}
      >
        {isManaged ? (
          <>
            <div
              style={{ minWidth: 350 }}
              className={clsx(!booking && "is-invisible")}
            >
              <DottedHeading
                className={clsx(
                  "is-size-1 margin-top-0",
                  grandTotal === 0 && "has-text-mid-gray"
                )}
                left="Total to Pay Now"
                right={formatCents(grandTotal)}
              />
              <div className="is-flex is-justify-content-space-between">
                <SVG
                  path="/site/logo/payments"
                  alt="Payment Methods"
                  height={28}
                />
                <button
                  className="button is-yellow padding-x-4 is-size-7"
                  disabled={grandTotal === 0 || !isActive || isMakingPayment}
                  onClick={handleCheckout}
                >
                  Go to Pay
                </button>
              </div>
            </div>
          </>
        ) : (
          noteTypes.map((type, idx) => (
            <div
              key={type}
              className={clsx(
                "is-flex-1",
                !isLastItem(idx, noteTypes.length) && "has-border-right-gray"
              )}
            >
              <BookingNotes
                booking={booking}
                setBooking={setBooking}
                type={type}
              />
            </div>
          ))
        )}
      </div>
    </>
  );
};

const noteTypes = Object.keys(NoteDO_Type).slice(0, -1) as NoteDO_Type[];
const noteCategories = ["Internal Booking", "Payment", "Tour Director"];

interface BookingNotesProps {
  booking: BookingDO;
  setBooking: SetBooking;
  type: NoteDO_Type;
}

const BookingNotes: FC<BookingNotesProps> = ({ booking, setBooking, type }) => {
  const [{ login }] = useDannoContext();
  if (!login) throw new Error("unreachable");

  const idx = noteTypes.indexOf(type);
  const category = noteCategories[idx];
  const note = booking.notes.find((n) => n.type === type);

  const handleNoteChange = (note: NoteDO) => {
    setBooking((booking) => {
      if (!booking) throw new Error("unreachable");
      const notes = booking.notes.slice(0);
      const oldNoteIdx = notes.findIndex((n) => n.type === note.type);
      if (oldNoteIdx !== -1) notes.splice(oldNoteIdx, 1);
      return {
        ...booking,
        notes: [note, ...notes],
      };
    });
  };

  return (
    <Notes
      key={note?.body} // if the note was added automatically (force cancellation)
      category={category}
      currentBody={note?.body}
      onNoteSave={async (body) => {
        const newNote = await makeNote({ bookingId: booking.id, type, body });
        handleNoteChange(newNote);
      }}
      placeholder={
        type === NoteDO_Type.TOUR_DIRECTOR
          ? "Please keep brief (appears on passenger list)"
          : undefined
      }
    />
  );
};

interface PdfDocumentsProps {
  booking: BookingDO;
}

const confirmations = "confirmations";
const travel = "travel";
const policy = "policy";
type PdfDocCategory = typeof confirmations | typeof travel | typeof policy;

const fourDocRows = 4;
const fourRowsStyle = { height: 28 * fourDocRows + 3 }; // row_height x 4 + rows_top_border x 3

const PdfDocuments: FC<PdfDocumentsProps> = ({ booking }) => {
  const [category, setCategory] = useState<PdfDocCategory>(confirmations);

  const travelDocs = Object.values(travelDocuments(db, booking));

  const handleCategoryChange = (e: ChangeEvent<HTMLInputElement>) =>
    setCategory(e.currentTarget.value as PdfDocCategory);

  return (
    <>
      <div className="has-border-y-gray is-flex padding-y-1 padding-x-2">
        <h3 className="is-marginless">Documents:</h3>
        <Radio
          id={confirmations}
          parentClassName="margin-x-3"
          value={confirmations}
          checked={category === confirmations}
          onChange={handleCategoryChange}
        >
          Confirmations ({booking.confirmations.length})
        </Radio>
        <Radio
          id={travel}
          parentClassName="margin-right-3"
          value={travel}
          checked={category === travel}
          onChange={handleCategoryChange}
        >
          Travel ({travelDocs.length})
        </Radio>
        <Radio
          id={policy}
          value={policy}
          checked={category === policy}
          onChange={handleCategoryChange}
        >
          Policy (2)
        </Radio>
      </div>
      <div style={fourRowsStyle} className="is-scrollable-y">
        {category === confirmations
          ? toReversed(booking.confirmations).map((confirmation, idx, arr) => (
              <PdfDocumentRow
                key={confirmation.id}
                index={idx}
                totalCount={arr.length}
                dateTimeIso={confirmation.issuedAt}
                url={confirmationUrl(process.env.PDF_ORIGIN!, confirmation)}
              >
                {confirmationTitle(confirmation.type)}
              </PdfDocumentRow>
            ))
          : category === travel
          ? travelDocs.map((travelDocument, idx, arr) => (
              <PdfDocumentRow
                key={travelDocument._id}
                index={idx}
                totalCount={arr.length}
                dateTimeIso={travelDocument.uploadedAt}
                url={travelDocumentUrl(process.env.PDF_ORIGIN!, travelDocument)}
              >
                {travelDocument.type}
              </PdfDocumentRow>
            ))
          : [
              termsOfTravelDoc(booking, globalDO, travelDocumentsById),
              travelProtectionDoc(booking, globalDO, travelDocumentsById),
            ].map((policyDoc, idx, arr) => (
              <PdfDocumentRow
                key={policyDoc._id}
                index={idx}
                totalCount={arr.length}
                dateTimeIso={policyDoc.uploadedAt}
                url={travelDocumentUrl(process.env.PDF_ORIGIN!, policyDoc)}
              >
                {policyDoc.type}
              </PdfDocumentRow>
            ))}
      </div>
    </>
  );
};

interface PdfDocumentRowProps extends PropsWithChildren {
  dateTimeIso: string;
  index: number;
  totalCount: number;
  url: string;
}

const PdfDocumentRow: FC<PdfDocumentRowProps> = ({
  children,
  dateTimeIso,
  index,
  totalCount,
  url,
}) => (
  <p
    className={clsx(
      "is-marginless padding-left-2 is-line-height-small is-flex is-align-items-center",
      (index < fourDocRows - 1 || !isLastItem(index, totalCount)) &&
        "has-border-bottom-gray"
    )}
  >
    <span className="padding-right-2 has-text-nowrap">
      {DateTime.fromISO(dateTimeIso, { zone: CENTRAL_TZ }).toFormat(MM_dd_yy)}:
    </span>
    <span className="has-ellipsis is-flex-1">{children}</span>
    <span className="has-border-left-gray">
      <ExternalLink
        className="button is-ghost is-flex-1"
        href={url}
        target="_blank"
        style={buttonStyle}
      >
        <SVG path="site/icon/document" alt="Document" height={16} />
      </ExternalLink>
    </span>
  </p>
);

const AutomatedTickets = () => {
  return (
    <h3 className="is-marginless padding-y-2 padding-x-2 has-border-top-gray">
      Tickets: 0 Open
    </h3>
  );
};

interface BookingStatusProps {
  booking: BookingDO;
  tourYear: TourYearDO;
}

const BookingStatus: FC<BookingStatusProps> = ({ booking, tourYear }) => {
  const status = statusForHumans(booking, tourYear, true);
  return status.label ? (
    <>
      <strong>{status.label}</strong>
      <br />
      {status.description}
    </>
  ) : (
    <>{status.description}</>
  );
};
