import { daysUntilDepartureAt12am, DAYS_TO_PAY_FULL } from "data-model";
import { FC } from "react";
import clsx from "clsx";

interface Props {
  departedAt: string;
  timezoneBegin: string;
}

const DaysLeft: FC<Props> = ({ departedAt, timezoneBegin }) => {
  const daysLeft = daysUntilDepartureAt12am(departedAt, timezoneBegin);
  if (daysLeft <= 0) return null;

  return (
    <span className={clsx(daysLeft < DAYS_TO_PAY_FULL && "has-text-red")}>
      ({daysLeft} days left)
    </span>
  );
};

export { DaysLeft };
