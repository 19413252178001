import { sendEmail } from "../api";
import {
  ErrorMessage,
  Input,
  SVG,
  Textarea,
  useErrors,
} from "react-components";
import { FC, useState } from "react";

interface Props {
  defaultBody: string;
  defaultSubject: string;
  onClose: () => void;
  rows?: number;
}

const EmailEditor: FC<Props> = ({
  defaultBody,
  defaultSubject,
  onClose,
  rows = 15,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [errors, catchErrors] = useErrors();

  const [to, setTo] = useState("");
  const [subject, setSubject] = useState(defaultSubject);
  const [body, setBody] = useState(defaultBody);

  return (
    <form
      autoComplete="off"
      style={{ minWidth: 500 }}
      onSubmit={(e) => {
        e.preventDefault();
        setIsLoading(true);
        catchErrors(
          async () => {
            await sendEmail({ to, subject, text: body });
            onClose();
          },
          () => setIsLoading(false)
        );
      }}
    >
      <div className="is-grid is-grid-template-columns-auto-1fr is-column-gap-2 is-row-gap-2">
        <strong>From:</strong>
        <span>donotreply@caravan.com</span>
        <label htmlFor="travel-agent-to" className="is-align-self-center">
          <strong>To:</strong>
        </label>
        <Input
          id="travel-agent-to"
          type="email"
          value={to}
          onChange={(e) => setTo(e.currentTarget.value)}
          placeholder="name@example.com"
        />
        <label htmlFor="travel-agent-subject" className="is-align-self-center">
          <strong>Subject:</strong>
        </label>
        <Input
          id="travel-agent-subject"
          value={subject}
          onChange={(e) => setSubject(e.currentTarget.value)}
        />
        <label htmlFor="travel-agent-body">
          <strong>Body:</strong>
        </label>
        <div className="is-flex">
          <Textarea
            id="travel-agent-body"
            className="is-resizable"
            value={body}
            onChange={(e) => setBody(e.currentTarget.value)}
            rows={rows}
          />
          {/* {!isText && (
            <div
              dangerouslySetInnerHTML={{ __html: body }}
              className="margin-left-2"
            />
          )} */}
        </div>
        <div className="is-grid-column-2-neg-1 is-flex is-align-items-center">
          <button
            className="button is-light-blue"
            disabled={isLoading || !to || !subject || !body}
          >
            Send
          </button>
          <button
            type="button"
            className="button is-light-blue is-outlined margin-x-2"
            onClick={onClose}
            disabled={isLoading}
          >
            Cancel
          </button>
          {isLoading && (
            <SVG path="site/icon/spinner" alt="Spinner" height={20} />
          )}
        </div>
        <ErrorMessage
          className="is-grid-column-2-neg-1 is-marginless"
          errors={errors}
        />
      </div>
    </form>
  );
};

export { EmailEditor };
