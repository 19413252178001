import { FC } from "react";

interface Props {
  length?: number;
}

const OptionDivider: FC<Props> = ({ length = 15 }) => (
  <option
    disabled
    className="has-text-gray"
    value=""
    dangerouslySetInnerHTML={{ __html: "&#9472;".repeat(length) }}
  />
);

export { OptionDivider };
