import { LoginDO } from "data-model";
import {
  Dispatch,
  createContext,
  FC,
  useContext,
  useReducer,
  Reducer,
  PropsWithChildren,
} from "react";

// Constants

export const LOG_IN = "LOG_IN";
export const LOG_OUT = "LOG_OUT";

// Actions

type DannoAction = LogInAction | LogOutAction;

interface LogInAction {
  type: typeof LOG_IN;
  login: LoginDO;
}

interface LogOutAction {
  type: typeof LOG_OUT;
}

// State

interface DannoState {
  login: LoginDO | null;
}

// Context

type DannoDispatch = Dispatch<DannoAction>;

type DannoValue = [DannoState, DannoDispatch];

const DannoContext = createContext<DannoValue>([] as any);

export const DannoProvider: FC<PropsWithChildren> = ({ children }) => {
  const state = useReducer(dannoReducer, undefined, initState);

  return (
    <DannoContext.Provider value={state}>{children}</DannoContext.Provider>
  );
};
export const useDannoContext = () => useContext(DannoContext);

// Reducer

const dannoReducer: Reducer<DannoState, DannoAction> = (state, action) => {
  switch (action.type) {
    case LOG_IN: {
      const { login } = action;
      return { ...state, login };
    }
    case LOG_OUT: {
      return { ...state, login: null };
    }
    default:
      throw new Error(`No case for ${(action as any).type} action`);
  }
};

// Init state

export const LOGIN_KEY = "login";

const initState = (): DannoState => {
  const loginRaw = localStorage.getItem(LOGIN_KEY);
  return {
    login: loginRaw ? JSON.parse(loginRaw) : null,
  };
};
