import { logIn } from "../api";
import { LOGIN_KEY, LOG_IN, useDannoContext } from "../state";
import { Input, useErrors, ErrorMessage } from "react-components";
import { useState, FormEvent } from "react";
import { useNavigate } from "react-router-dom";

const LogIn = () => {
  const [, dispatch] = useDannoContext();
  const navigate = useNavigate();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [errors, catchErrors] = useErrors();

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    setIsLoading(true);

    await catchErrors(
      async () => {
        const login = await logIn({ email, password });

        localStorage.setItem(LOGIN_KEY, JSON.stringify(login));

        dispatch({ type: LOG_IN, login });

        navigate("/", { replace: true });
      },
      () => setIsLoading(false)
    );
  };

  return (
    <div className="is-flex margin-x-auto">
      <form onSubmit={handleSubmit} style={{ width: 250 }}>
        <div className="margin-bottom-2">
          <Input
            value={email}
            onChange={(e) => setEmail(e.currentTarget.value)}
            type="email"
            placeholder="Email"
            required
          />
        </div>
        <div className="margin-bottom-2">
          <Input
            value={password}
            onChange={(e) => setPassword(e.currentTarget.value)}
            type="password"
            placeholder="Password"
            required
          />
        </div>
        <ErrorMessage errors={errors} />
        <button className="button" disabled={isLoading}>
          Log In
        </button>
      </form>
    </div>
  );
};

export { LogIn };
