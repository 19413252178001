import { SetBooking } from "./ManageBooking";
import {
  addBookingToParty,
  createParty,
  removeBookingFromParty,
  searchParties,
} from "../../api";
import { OptionDivider } from "../../components";
import { BookingDO, PartyDO, totalGuestsFrom } from "data-model";
import { Input, Select, SVG } from "react-components";
import { FC, useEffect, useState } from "react";

interface Props {
  booking: BookingDO;
  setBooking: SetBooking;
}

const PartySelector: FC<Props> = ({ booking, setBooking }) => {
  const [parties, setParties] = useState<PartyDO[]>([]);

  const [partyName, setPartyName] = useState<string | undefined>(undefined);
  const [isLoading, setIsLoading] = useState(false);

  const { series, departedAt } = booking;

  // Re-run when guests are cancelled. Also runs on mount as intended.
  useEffect(() => {
    searchParties({ series, departedAt }).then(setParties);
  }, [booking.roomGuests]);

  return (
    <div className="is-flex is-align-items-center is-relative">
      <label htmlFor="party" className="margin-right-2">
        Party:
      </label>
      <Select
        id="party"
        value={booking.party?.id || ""}
        onChange={async (e) => {
          const { value } = e.currentTarget;

          if (value === "") {
            if (!booking.party) throw new Error("unreachable");

            setIsLoading(true);
            await removeBookingFromParty(booking.party.id, booking.id);
            setParties((parties) => syncOldParty(parties, booking));
            setBooking((booking) => ({ ...booking, party: undefined }));
            setIsLoading(false);
          } else if (value === "0") {
            setPartyName("");
          } else {
            const partyId = +value;
            setIsLoading(true);
            if (booking.party) {
              await removeBookingFromParty(booking.party.id, booking.id);
            }
            await addBookingToParty(partyId, { bookingId: booking.id });
            setParties((parties) =>
              syncOldParty(parties, booking).map((p) =>
                p.id === partyId
                  ? { ...p, bookings: [...p.bookings, booking] }
                  : p
              )
            );
            const party = parties.find((p) => p.id === partyId)!;
            setBooking((booking) => ({
              ...booking,
              party: { ...party, bookings: [...party.bookings, booking] },
            }));
            setIsLoading(false);
          }
        }}
      >
        <option value="">None Selected</option>
        {parties.length > 0 && <OptionDivider length={12} />}
        {parties.map((party) => (
          <option key={party.id} value={party.id}>
            {party.name} (
            {party.bookings.reduce(
              (acc, b) => (acc += totalGuestsFrom(b.roomGuests)),
              0
            )}{" "}
            pax)
          </option>
        ))}
        <OptionDivider length={12} />
        <option value="0">Create a new party</option>
      </Select>
      {isLoading && (
        <SVG
          path="site/icon/spinner"
          alt="Spinner"
          className="margin-left-2"
          height={20}
        />
      )}
      {partyName !== undefined && (
        <div className="is-absolute has-background-white padding-2 has-shadow-gray is-z-index-1 is-top-0">
          <Input
            placeholder="Party name"
            value={partyName}
            onChange={(e) => setPartyName(e.currentTarget.value)}
          />
          <div className="is-flex margin-top-2">
            <button
              className="button margin-right-2"
              onClick={() => setPartyName(undefined)}
            >
              Cancel
            </button>
            <button
              className="button is-light-blue"
              disabled={!partyName}
              onClick={async () => {
                setIsLoading(true);
                setPartyName(undefined);

                const party = await createParty({
                  name: partyName!,
                  series,
                  departedAt,
                });
                if (booking.party) {
                  await removeBookingFromParty(booking.party.id, booking.id);
                }
                await addBookingToParty(party.id, { bookingId: booking.id });
                party.bookings = [booking];

                setParties((parties) => {
                  const newParties = syncOldParty(parties, booking);
                  newParties.push(party);
                  return newParties;
                });
                setBooking((booking) => ({ ...booking, party }));
                setIsLoading(false);
              }}
            >
              Create
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export { PartySelector };

const syncOldParty = (parties: PartyDO[], booking: BookingDO) => {
  const newParties = parties.slice(0);

  const oldPartyId = booking.party?.id;
  if (oldPartyId) {
    const oldPartyIdx = parties.findIndex((p) => p.id === oldPartyId);
    const oldParty = parties[oldPartyIdx];

    if (oldParty.bookings.length === 1) {
      // Dissolve the old party since it has no other bookings
      newParties.splice(oldPartyIdx, 1);
    } else {
      // Detach the booking from the old party
      newParties.splice(oldPartyIdx, 1, {
        ...oldParty,
        bookings: oldParty.bookings.filter((b) => b.id !== booking.id),
      });
    }
  }

  return newParties;
};
