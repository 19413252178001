import { Input } from "react-components";
import { Dispatch, FC, SetStateAction } from "react";
import { useRifm } from "rifm";

interface Props {
  id?: string;
  className?: string;
  value: string;
  setValue: Dispatch<SetStateAction<string>>;
  onFormat?: (formattedValue: string) => void;
}

const BookingNumber: FC<Props> = ({ value, setValue, onFormat, ...rest }) => {
  // See https://realadvisor.github.io/rifm/ for examples
  const rifm = useRifm({
    accept: acceptedChars,
    mask: 12 <= value.length,
    format: formatBookingNumberWithAppend,
    append: appendHyphen,
    replace: toUpperCase,
    value,
    onChange: (formattedValue) => {
      setValue(formattedValue);
      onFormat?.(formattedValue);
    },
  });

  return (
    <Input
      {...rest}
      value={rifm.value}
      onChange={rifm.onChange}
      autoComplete="off"
    />
  );
};

export { BookingNumber };

const acceptedChars = /[A-Za-z0-9]+/g;

const formatBookingNumber = (string: string) => {
  const noHyphen = (string.match(acceptedChars) || []).join("");
  const chars = noHyphen.split("");
  return chars
    .reduce((r, v, index) => (index === 8 ? `${r}-${v}` : `${r}${v}`), "")
    .slice(0, 12);
};

const formatBookingNumberWithAppend = (string: string) => {
  const res = formatBookingNumber(string);

  if (string.endsWith("-")) {
    if (res.length === 8) {
      return `${res}-`;
    }
  }
  return res;
};

const appendHyphen = (v: string) => (v.length === 8 ? `${v}-` : v);

const toUpperCase = (v: string) => v.toUpperCase();
