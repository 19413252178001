import { editTransport, searchFlights } from "../../../api";
import { db, hotelsById } from "../../../jsongo";
import {
  BookingDO,
  DannoEditTransportReq,
  DannoTransportDraft,
  FlightInfo,
  GuestDO,
  TourYearDO,
  TourYearDO_TransferInfo,
  TransportDO,
  airlineCarriers,
  arrivesEarly,
  bestFlightIdx,
  capitalize,
  changesSince,
  departsLate,
  directionLowerFrom,
  draftDefaults,
  flightDefaults,
  flightMissedDueToTime,
  hotelOnFirstDay,
  hotelOnLastDay,
  yearFromDate,
} from "data-model";
import {
  Checkbox,
  ErrorMessage,
  FlightPicker,
  Input,
  Radio,
  Select,
  SVG,
  Tooltip,
  useErrors,
} from "react-components";
import {
  ChangeEvent,
  FC,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import clsx from "clsx";
import debounce from "lodash.debounce";
import { DateTime } from "luxon";

interface Props {
  booking: BookingDO;
  className?: string;
  guest: GuestDO;
  isArrival: boolean;
  isPrimary: boolean;
  onChange: (
    guestId: number,
    isArrival: boolean,
    transport?: TransportDO
  ) => void;
  primaryFullName: string;
  primaryGuest: GuestDO;
}

enum StayOptions {
  caravanHotel = "caravan-hotel",
  extraNight = "extra-night",
  differentStay = "different-stay",
}

const FlightEditor: FC<Props> = ({
  booking,
  className = "margin-top-0",
  guest,
  isArrival,
  isPrimary,
  onChange,
  primaryFullName,
  primaryGuest,
}) => {
  const direction = directionLowerFrom(isArrival);
  const transport = guest[direction] || primaryGuest[direction];

  const { departure } = booking;
  const { departedAt, concludedAt } = departure;
  const tourYear = db.tourYear.findByIdOrFail({
    tour_id: booking.tour,
    year: yearFromDate(departedAt),
  }) as TourYearDO;

  const isMounted = useRef(false);
  const [errors, catchErrors, setErrors] = useErrors();
  const [loadingStack, setLoadingStack] = useState<number[]>([]); // container for multiple isLoading states
  const setIsLoading = (isLoading: boolean) =>
    setLoadingStack((s) => (isLoading ? [...s, 1] : s.slice(0, -1)));

  const [isSameAs, setIsSameAs] = useState(
    !isPrimary && transport?.id === primaryGuest[direction]?.id
  );
  const [draft, setDraft] = useState<DannoTransportDraft>(
    initDraft(isArrival, departedAt, concludedAt, transport)
  );

  const preOrPost = isArrival ? "pre" : "post";
  const tourChanges = changesSince(db, tourYear._id, booking.createdAt);
  const isEligibleForExtraNight =
    booking.series === "RI" &&
    !!tourChanges.length && // too loose?
    draft.isFlight &&
    !draft.isArrival;
  const hotelName = isArrival
    ? hotelOnFirstDay(hotelsById, tourYear, departure)
    : hotelOnLastDay(hotelsById, tourYear, departure);

  const handleDraftChange = (changes: Partial<DannoTransportDraft>) =>
    setDraft((draft) => ({ ...draft, ...changes }));

  const handleMeansChange = () =>
    setDraft(({ isFlight }) => ({
      ...draftDefaults,
      isArrival,
      isFlight: !isFlight,
      isManual: false,
      scheduledDate: isArrival ? departedAt : concludedAt,
    }));

  const handleStayChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { value } = e.currentTarget;
    let stayAtCaravanHotel: boolean | undefined;
    let extraNight: boolean | undefined;

    if (value === StayOptions.caravanHotel) {
      stayAtCaravanHotel = true;
    } else if (value === StayOptions.extraNight) {
      extraNight = true;
    } else {
      stayAtCaravanHotel = false;
    }

    setDraft((draft) => ({
      ...draft,
      stayAtCaravanHotel,
      extraNight,
      stayNote: "",
    }));
  };

  const saveTransport = async (
    guestId: number,
    args: DannoEditTransportReq,
    signal: AbortSignal
  ) => {
    setIsLoading(true);
    // Usually, previous errors are cleared by catchErrors.
    // However, in this case, we memoize the debounced function,
    // so catchErrors always sees its errors.length as 0.
    setErrors([]);

    catchErrors(
      async () => {
        const transport = await editTransport(guestId, args, { signal });
        onChange(guestId, args.isArrival, transport);
        if (
          transport?.isManual &&
          args.draft &&
          (!args.draft.fromAirportName || !args.draft.toAirportName)
        ) {
          // API will auto-assign airport names if they're missing
          setDraft((d) => ({
            ...d,
            toAirportName: transport.toAirportName || "",
            fromAirportName: transport.fromAirportName || "",
          }));
        }
      },
      () => {
        setIsLoading(false);
      }
    );
  };

  const debounceSaveTransport = useCallback(debounce(saveTransport, 1000), []);

  useEffect(() => {
    if (!isMounted.current) {
      isMounted.current = true;
      return;
    }

    if (!draft.scheduledDate) return;

    const controller = new AbortController();

    debounceSaveTransport(
      guest.id,
      {
        draft: isSameAs ? undefined : draft,
        isArrival,
      },
      controller.signal
    );

    return () => controller.abort();
  }, [
    isSameAs,
    // draft.isArrival // doesn't change
    draft.isFlight,
    draft.isManual,
    draft.airline,
    draft.flightNumber,
    draft.fromAirport,
    // draft.fromAirportName // not editable
    draft.toAirport,
    // draft.toAirportName // not editable
    draft.stayAtCaravanHotel,
    draft.extraNight,
    draft.stayNote,
    draft.travelNote,
    draft.scheduledDate,
    draft.scheduledTime,
  ]);

  const meansControls = (
    <>
      <Radio
        id={`flight-${guest.id}-${direction}`}
        // containerClassName="margin-right-1"
        checked={draft.isFlight}
        onChange={handleMeansChange}
      >
        Flight
      </Radio>
      <Checkbox
        id={`manual-flight-${guest.id}-${direction}`}
        parentClassName="margin-x-3"
        checked={draft.isManual}
        onChange={() =>
          setDraft((draft) => {
            const isManual = !draft.isManual;
            return { ...draft, ...(!isManual && flightDefaults), isManual };
          })
        }
        disabled={!draft.isFlight}
      >
        Manual
      </Checkbox>
      <Radio
        id={`other-${guest.id}-${direction}`}
        parentClassName="is-flex-1"
        // containerClassName="margin-right-1"
        checked={!draft.isFlight}
        onChange={handleMeansChange}
      >
        Other
      </Radio>
    </>
  );

  return (
    <>
      <h4 className={clsx("margin-bottom-0", className)}>
        {isArrival ? "Arrive" : "Depart"}:
      </h4>
      <div className={clsx("is-flex is-align-items-center", className)}>
        {isPrimary ? (
          meansControls
        ) : (
          <Checkbox
            id={`same-as-${guest.id}-${direction}`}
            parentClassName="is-flex-1"
            checked={isSameAs}
            onChange={() => {
              const isSame = !isSameAs;
              setIsSameAs(isSame);
              setDraft(
                initDraft(
                  isArrival,
                  departedAt,
                  concludedAt,
                  isSame ? primaryGuest[direction] : undefined
                )
              );
            }}
          >
            Same as {primaryFullName}
          </Checkbox>
        )}
        <SVG
          className={clsx(
            "margin-left-2",
            !loadingStack.length && "is-invisible"
          )}
          path="/site/icon/spinner"
          alt="Loading"
          height={20}
        />
      </div>

      {!isPrimary && !isSameAs && (
        <div className="is-grid-column-2-neg-1 is-flex is-align-items-center">
          {meansControls}
        </div>
      )}

      {!isSameAs && (
        <>
          {draft.isFlight ? (
            <FlightSearch
              concludedAt={concludedAt}
              draft={draft}
              guestId={guest.id}
              onDraftChange={handleDraftChange}
              onLoading={setIsLoading}
              transferInfo={
                departure.transfers?.[direction] ??
                tourYear.transfers[direction]
              }
            />
          ) : (
            <OtherPlans
              draft={draft}
              guestId={guest.id}
              onDraftChange={handleDraftChange}
            />
          )}

          {(arrivesEarly(isArrival, draft.scheduledDate, departedAt) ||
            departsLate(isArrival, draft.scheduledDate, concludedAt)) && (
            <>
              <label className="is-align-self-flex-start">
                <span className="is-size-7 is-block">
                  {capitalize(preOrPost)}-tour
                </span>
                Stay:
              </label>
              <div>
                <Radio
                  id={`${StayOptions.caravanHotel}-${guest.id}-${direction}`}
                  parentClassName="margin-bottom-1"
                  value={StayOptions.caravanHotel}
                  checked={draft.stayAtCaravanHotel === true}
                  onChange={handleStayChange}
                  inline={false}
                >
                  Client to book additional night(s) at {hotelName}
                </Radio>
                {isEligibleForExtraNight && (
                  <Radio
                    id={`${StayOptions.extraNight}-${guest.id}-${direction}`}
                    parentClassName="margin-bottom-1"
                    value={StayOptions.extraNight}
                    checked={draft.extraNight === true}
                    onChange={handleStayChange}
                    inline={false}
                  >
                    Caravan to provide one {preOrPost}-tour night at {hotelName}{" "}
                    and airport transfer
                  </Radio>
                )}
                <Radio
                  id={`${StayOptions.differentStay}-${guest.id}-${direction}`}
                  value={StayOptions.differentStay}
                  checked={draft.stayAtCaravanHotel === false}
                  onChange={handleStayChange}
                  inline={false}
                >
                  Client to arrange a different stay
                </Radio>
              </div>

              {draft.stayAtCaravanHotel === false && (
                <>
                  <label htmlFor={`stayNote-${guest.id}-${direction}`}>
                    Details:
                  </label>
                  <Input
                    id={`stayNote-${guest.id}-${direction}`}
                    value={draft.stayNote}
                    onChange={(e) =>
                      handleDraftChange({ stayNote: e.currentTarget.value })
                    }
                    placeholder="Where are you staying? (Optional)"
                  />
                </>
              )}
            </>
          )}

          <ErrorMessage
            errors={errors}
            className="is-grid-column-2-neg-1 is-marginless"
          />
        </>
      )}
    </>
  );
};

export { FlightEditor };

const initDraft = (
  isArrival: boolean,
  departedAt: string,
  concludedAt: string,
  transport?: TransportDO
): DannoTransportDraft => ({
  isArrival,
  isFlight: transport?.isFlight ?? true,
  isManual: transport?.isManual ?? false,
  airline: transport?.airline || "",
  flightNumber: transport?.flightNumber || "",
  fromAirport: transport?.fromAirport || "",
  fromAirportName: transport?.fromAirportName || "",
  toAirport: transport?.toAirport || "",
  toAirportName: transport?.toAirportName || "",
  stayAtCaravanHotel: transport?.stayAtCaravanHotel,
  extraNight: transport?.extraNight,
  stayNote: transport?.stayNote || "",
  travelNote: transport?.travelNote || "",
  scheduledDate:
    transport?.scheduledDate || (isArrival ? departedAt : concludedAt),
  scheduledTime: transport?.scheduledTime || "",
});

interface FlightSearchProps {
  concludedAt: string;
  draft: DannoTransportDraft;
  guestId: number;
  onDraftChange: (changes: Partial<DannoTransportDraft>) => void;
  onLoading: (isLoading: boolean) => void;
  transferInfo: TourYearDO_TransferInfo;
}

const FlightSearch: FC<FlightSearchProps> = ({
  concludedAt,
  draft,
  guestId,
  onDraftChange,
  onLoading,
  transferInfo,
}) => {
  const isMounted = useRef(false);
  const flightForm = useRef<HTMLFormElement>(null);

  const [errors, catchErrors, setErrors] = useErrors();

  const isCustomAirline = draft.airline
    ? !airlineCarriers.some((c) => c.iata === draft.airline)
    : false;
  const [isCustom, setIsCustom] = useState(isCustomAirline);

  const [flights, setFlights] = useState<FlightInfo[]>([]);
  const [flightIdx, setFlightIdx] = useState(-1);

  const direction = directionLowerFrom(draft.isArrival);
  const flightError = flightMissedDueToTime(draft, concludedAt, transferInfo);

  const updateFlightInfo = (flights: FlightInfo[], flightIdx: number) => {
    const { from, fromName, to, toName, time } = flights[flightIdx];
    onDraftChange({
      fromAirport: from,
      fromAirportName: fromName,
      toAirport: to,
      toAirportName: toName,
      scheduledTime: time,
    });
  };

  const findFlights = async (
    date: string,
    airlineIata: string,
    number: string,
    signal: AbortSignal
  ) => {
    onLoading(true);
    setErrors([]);
    setFlights([]);
    setFlightIdx(-1);

    catchErrors(
      async () => {
        const flights = await searchFlights(
          {
            airlineIata,
            date,
            isArrival: `${draft.isArrival}`,
            number,
          },
          { signal }
        );

        const bestIdx = bestFlightIdx(flights, draft.isArrival, transferInfo);

        updateFlightInfo(flights, bestIdx);

        if (flights.length > 1) {
          setFlights(flights);
          setFlightIdx(bestIdx);
        }
      },
      () => {
        onLoading(false);
      }
    );
  };

  // Why useCallback? https://stackoverflow.com/a/61786423
  // Why not debounce(searchFlights)? https://github.com/lodash/lodash/issues/4400
  const debounceFindFlight = useCallback(debounce(findFlights, 1000), []);

  useEffect(() => {
    if (!isMounted.current) {
      isMounted.current = true;
      return;
    }

    if (draft.isManual) return;

    if (
      draft.scheduledDate &&
      draft.airline &&
      draft.flightNumber &&
      flightForm.current?.reportValidity()
    ) {
      const controller = new AbortController();
      debounceFindFlight(
        draft.scheduledDate,
        draft.airline,
        draft.flightNumber,
        controller.signal
      );

      return () => controller.abort();
    }
  }, [draft.isManual, draft.scheduledDate, draft.airline, draft.flightNumber]);

  return (
    <>
      <label htmlFor={`scheduledDate-${guestId}-${direction}`}>Date:</label>
      <form
        ref={flightForm}
        className="is-grid is-align-items-center"
        style={{ gridTemplateColumns: "42% 18% 20% 20%" }}
      >
        <Input
          type="date"
          className="padding-right-1"
          id={`scheduledDate-${guestId}-${direction}`}
          value={draft.scheduledDate}
          onChange={(e) =>
            onDraftChange({
              ...(!draft.isManual && flightDefaults),
              scheduledDate: e.currentTarget.value,
              stayAtCaravanHotel: undefined,
              extraNight: undefined,
              stayNote: "",
            })
          }
        />

        <label
          className="has-text-centered"
          htmlFor={`airline-${guestId}-${direction}`}
        >
          Flight:
        </label>

        {isCustom ? (
          <Input
            id={`airline-${guestId}-${direction}`}
            placeholder="--"
            value={draft.airline}
            onChange={(e) =>
              onDraftChange({
                ...(!draft.isManual && flightDefaults),
                airline: e.currentTarget.value.toUpperCase(),
              })
            }
            pattern="[A-Z0-9]{2}"
            maxLength={2}
            childrenRight={
              <button
                type="button"
                className="button is-ghost is-paddingless field-helper"
                onClick={() => {
                  setIsCustom(false);
                  onDraftChange({
                    ...(!draft.isManual && flightDefaults),
                    airline: "",
                  });
                }}
              >
                <SVG path="site/icon/close-blue" alt="Cross" height={14} />
              </button>
            }
          />
        ) : (
          <Select
            className="padding-right-4"
            id={`airline-${guestId}-${direction}`}
            value={draft.airline}
            onChange={(e) => {
              const { value } = e.currentTarget;
              const isCustom = value === "Custom";
              if (isCustom) setIsCustom(true);
              onDraftChange({
                ...(!draft.isManual && flightDefaults),
                airline: isCustom ? "" : value,
              });
            }}
          >
            <option value="">--</option>
            <option value="Custom">Custom</option>
            {airlineCarriers.map(({ iata }) => (
              <option key={iata} value={iata}>
                {iata}
              </option>
            ))}
          </Select>
        )}

        <Input
          placeholder="#"
          value={draft.flightNumber}
          onChange={(e) =>
            onDraftChange({
              ...(!draft.isManual && flightDefaults),
              flightNumber: e.currentTarget.value,
            })
          }
          pattern="[0-9]{1,4}"
          maxLength={4}
        />
      </form>

      <label htmlFor={`fromAirport-${guestId}-${direction}`}>From:</label>
      <div
        className="is-grid is-align-items-center"
        style={{ gridTemplateColumns: "46% 20% 34%" }}
      >
        <div className="is-flex is-align-items-center">
          {draft.isManual ? (
            <Input
              id={`fromAirport-${guestId}-${direction}`}
              className="padding-x-1"
              maxLength={3}
              placeholder="---"
              value={draft.fromAirport}
              onChange={(e) =>
                onDraftChange({
                  fromAirport: e.currentTarget.value.toUpperCase(),
                })
              }
            />
          ) : (
            <Tooltip
              title={draft.fromAirportName || ""}
              parentClassName="is-flex-1"
              className="has-text-light-blue"
              titleClassName="is-centered-x"
            >
              {draft.fromAirport}
            </Tooltip>
          )}
          <label
            htmlFor={`toAirport-${guestId}-${direction}`}
            className="margin-x-1"
          >
            To:
          </label>
          {draft.isManual ? (
            <Input
              id={`toAirport-${guestId}-${direction}`}
              className="padding-x-1"
              maxLength={3}
              placeholder="---"
              value={draft.toAirport}
              onChange={(e) =>
                onDraftChange({
                  toAirport: e.currentTarget.value.toUpperCase(),
                })
              }
            />
          ) : (
            <Tooltip
              title={draft.toAirportName || ""}
              parentClassName="is-flex-1"
              className="has-text-light-blue"
              titleClassName="is-centered-x"
            >
              {draft.toAirport}
            </Tooltip>
          )}
        </div>
        <label
          htmlFor={`scheduledTime-${guestId}-${direction}`}
          className="has-text-centered"
        >
          {draft.isArrival ? "Arrives" : "Departs"}:
        </label>
        {draft.isManual ? (
          <Input
            id={`scheduledTime-${guestId}-${direction}`}
            type="time"
            className="padding-x-1"
            value={draft.scheduledTime}
            onChange={(e) => {
              let { value } = e.currentTarget;
              // Initial format is 24h hh:mm (becomes hh:mm:ss when we supply seconds).
              if (value.length === 5) value += ":00";
              onDraftChange({ scheduledTime: value });
            }}
          />
        ) : (
          <span>
            {draft.scheduledTime
              ? DateTime.fromISO(draft.scheduledTime).toFormat("hh:mm a")
              : ""}
          </span>
        )}
      </div>

      <ErrorMessage
        errors={
          // Whether we have any search related errors
          errors.length
            ? errors
            : flightError // Whether the flight will be missed
            ? [{ message: flightError }]
            : []
        }
        className="is-grid-column-2-neg-1 is-marginless"
      />

      {!!flights.length && (
        <div className="is-grid-column-1-neg-1 is-relative">
          <div className="is-absolute has-background-white padding-2 has-shadow-gray is-right-0 is-z-index-1">
            <FlightPicker
              buttonClassName="is-light-blue"
              currentIdx={flightIdx}
              flights={flights}
              isArrival={draft.isArrival}
              idPrefix={`${guestId}-${direction}`}
              onClose={() => {
                setFlights([]);
                setFlightIdx(-1);
              }}
              onIdxChange={(newIdx) => {
                setFlightIdx(newIdx);
                updateFlightInfo(flights, newIdx);
              }}
            />
          </div>
        </div>
      )}
    </>
  );
};

type OtherPlansProps = Pick<
  FlightSearchProps,
  "draft" | "guestId" | "onDraftChange"
>;

const OtherPlans: FC<OtherPlansProps> = ({ draft, guestId, onDraftChange }) => {
  const direction = directionLowerFrom(draft.isArrival);

  return (
    <>
      <label htmlFor={`travelNote-${guestId}-${direction}`}>Details:</label>
      <Input
        id={`travelNote-${guestId}-${direction}`}
        value={draft.travelNote}
        onChange={(e) => onDraftChange({ travelNote: e.currentTarget.value })}
        placeholder={`How are you ${
          draft.isArrival ? "arriving" : "departing"
        }? (Optional)`}
      />

      <label htmlFor={`scheduledDate-${guestId}-${direction}`}>Date:</label>
      <div>
        <Input
          type="date"
          id={`scheduledDate-${guestId}-${direction}`}
          value={draft.scheduledDate}
          onChange={(e) =>
            onDraftChange({
              scheduledDate: e.currentTarget.value,
              stayAtCaravanHotel: undefined,
              stayNote: "",
            })
          }
        />
      </div>
    </>
  );
};
