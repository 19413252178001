import { Header } from "./components";
import { DannoProvider, useDannoContext } from "./state";
import { CheckAvailability, LogIn, ManageBooking, NotFound } from "./pages";
import {
  CvnProvider,
  fakeContext,
  MessageProvider,
  ModalProvider,
  RequiresAuth,
  RequiresGuest,
} from "react-components";
import { Link, BrowserRouter, Routes, Route, Navigate } from "react-router-dom";

const cvnContext = {
  ...fakeContext,
  routerLink: Link,
};

const App = () => (
  <CvnProvider value={cvnContext}>
    <MessageProvider>
      <DannoProvider>
        <BrowserRouter>
          <ModalProvider>
            <Header />
            <main className="is-flex-1 padding-y-2 padding-x-4 has-background-tint-gray is-flex">
              <Main />
            </main>
          </ModalProvider>
        </BrowserRouter>
      </DannoProvider>
    </MessageProvider>
  </CvnProvider>
);

const Main = () => {
  const [{ login }] = useDannoContext();
  const isLoggedIn = !!login;

  return (
    <Routes>
      <Route
        path="/"
        element={
          <Navigate to={isLoggedIn ? "/check-availability" : "/login"} />
        }
      />
      <Route
        path="/login"
        element={
          <RequiresGuest auth={isLoggedIn}>
            <LogIn />
          </RequiresGuest>
        }
      />
      <Route
        path="/check-availability"
        element={
          <RequiresAuth auth={isLoggedIn}>
            <CheckAvailability />
          </RequiresAuth>
        }
      />
      <Route
        path="/manage-booking"
        element={
          <RequiresAuth auth={isLoggedIn}>
            <ManageBooking />
          </RequiresAuth>
        }
      />
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
};

export { App };
