import { SetBooking } from "../ManageBooking";
import { requestTppRefund } from "../../../api";
import {
  arrOfSize,
  BookingDO,
  cardBrandLetter,
  CENTRAL_TZ,
  DAYS_TO_REFUND_TPP,
  FeeDO,
  feePayment,
  feeRefund,
  formatCents,
  fullName,
  fullNameOrPlaceholder,
  GuestDO,
  isLastItem,
  isTppRefundable,
  MMM_dd_yyyy,
  paymentConsumption,
  tppFee,
  tppRefundDeadline,
} from "data-model";
import {
  Checkbox,
  DottedHeading,
  ErrorMessage,
  Input,
  SVG,
  Textarea,
  useErrors,
} from "react-components";
import { FC, Fragment, useState } from "react";
import { DateTime } from "luxon";

export interface Props {
  booking: BookingDO;
  onClose: () => void;
  setBooking: SetBooking;
}

const idPrefix = "tpp-refund";

const RefundTpp: FC<Props> = ({ booking, onClose, setBooking }) => {
  const [guestsToRefund, setGuestsToRefund] = useState(
    initGuestsToRefund(booking.guests)
  );
  const [reason, setReason] = useState("");
  const [requestedBy, setRequestedBy] = useState(fullName(booking.owner));

  const [errors, catchErrors] = useErrors();
  const [isLoading, setIsLoading] = useState(false);

  let posIdx = -1;
  const total = guestsToRefund.reduce(
    (acc, isRefunding, posIdx) =>
      isRefunding ? acc + tppFee(booking.guests[posIdx]).amount : acc,
    0
  );

  const handleRefundRequest = () => {
    setIsLoading(true);
    catchErrors(
      async () => {
        const refunds = await requestTppRefund(booking.id, {
          guestsToRefund,
          reason,
          requestedBy,
        });
        setBooking((b) => ({
          ...b,
          guests: b.guests.map((g) => {
            const tpp = tppFee(g);
            const refund = refunds.find((r) =>
              r.lineItems.some((item) => item.fee.id === tpp.id)
            );
            if (!refund) return g;

            return {
              ...g,
              fees: g.fees.map((f) => {
                if (f === tpp) {
                  return {
                    ...f,
                    refundLineItems: [
                      ...f.refundLineItems,
                      {
                        fee: f.id as unknown as FeeDO,
                        refund,
                        total: f.total,
                      },
                    ],
                  };
                }
                return f;
              }),
            };
          }),
        }));
        onClose();
      },
      () => setIsLoading(false)
    );
  };

  return (
    <>
      <h1 className="is-flex">
        Refund Travel Protection
        {isLoading && (
          <SVG
            className="margin-left-2"
            path="/site/icon/spinner"
            alt="Loading"
            height={18}
          />
        )}
      </h1>

      <div
        className="is-grid is-row-gap-2 is-column-gap-4 margin-y-4 padding-2 has-border-gray"
        style={{ gridTemplateColumns: "repeat(3, auto)" }}
      >
        {booking.roomGuests.map((guestsInRoom, roomIdx) =>
          arrOfSize(guestsInRoom).map((_, guestIdx) => {
            ++posIdx;

            const isLast = isLastItem(guestIdx, guestsInRoom);
            const tpp = tppFee(booking.guests[posIdx]);

            const payment = feePayment(
              tpp,
              () => false,
              () => false
            ); // ignoring all consumptions and refunds (i.e. last accepted payment)
            const refund = payment ? feeRefund(tpp, payment) : undefined;
            const consumption = payment
              ? paymentConsumption(payment, tpp)
              : undefined;
            const isRefundable =
              !refund &&
              !consumption &&
              isTppRefundable(booking.departedAt, payment);

            return (
              <Fragment key={posIdx}>
                <Checkbox
                  id={`${idPrefix}-${roomIdx}-${guestIdx}`}
                  data-pos-idx={posIdx}
                  checked={guestsToRefund[posIdx]}
                  onChange={(e) => {
                    const { checked, dataset } = e.currentTarget;
                    const posIdx = Number(dataset.posIdx);

                    setGuestsToRefund((g) =>
                      g.map((isRefunding, gPosIdx) =>
                        gPosIdx === posIdx ? checked : isRefunding
                      )
                    );
                  }}
                  disabled={!isRefundable}
                >
                  {fullNameOrPlaceholder(booking.guests, booking.owner, posIdx)}
                </Checkbox>

                <span>
                  {payment
                    ? `${DateTime.fromISO(payment.submittedAt, {
                        zone: CENTRAL_TZ,
                      }).toFormat(MMM_dd_yyyy)} (${cardBrandLetter(
                        payment.cardBrand
                      )} ${payment.cardLastFour})`
                    : "Unpaid"}
                </span>

                <span>
                  {payment
                    ? refund
                      ? refund.submittedAt
                        ? `Refunded ${DateTime.fromISO(refund.submittedAt, {
                            zone: CENTRAL_TZ,
                          }).toFormat(MMM_dd_yyyy)}`
                        : "Refund is pending approval"
                      : consumption
                      ? `Non-refundable (consumed via ${consumption.type})`
                      : isRefundable
                      ? `Refundable up to ${tppRefundDeadline(
                          payment,
                          booking.departedAt
                        ).toFormat(MMM_dd_yyyy)}`
                      : `Non-refundable (over ${DAYS_TO_REFUND_TPP} days)`
                    : "Unpaid"}
                </span>

                {isLast && !isLastItem(roomIdx, booking.roomGuests.length) && (
                  <div className="has-border-bottom-gray is-grid-column-1-neg-1" />
                )}
              </Fragment>
            );
          })
        )}
      </div>

      <div className="is-flex is-justify-content-space-between">
        <div className="is-flex is-flex-direction-column">
          <label htmlFor={`${idPrefix}-reason`}>Reason for TP refund:</label>
          <Textarea
            id={`${idPrefix}-reason`}
            value={reason}
            onChange={(e) => setReason(e.currentTarget.value)}
          />
        </div>

        <div className="is-flex is-flex-direction-column">
          <label htmlFor={`${idPrefix}-requested-by`}>Requested By:</label>
          <Input
            id={`${idPrefix}-requested-by`}
            value={requestedBy}
            onChange={(e) => setRequestedBy(e.currentTarget.value)}
          />
        </div>

        <div
          className="is-flex is-flex-direction-column"
          style={{ minWidth: 250 }}
        >
          <DottedHeading
            heading="h2"
            className="margin-top-0 margin-bottom-2"
            left="Total Refund"
            right={formatCents(total)}
          />
          <div className="is-flex">
            <button
              className="button is-light-blue is-outlined"
              onClick={onClose}
            >
              Close
            </button>
            <button
              className="button is-light-blue is-flex-1 margin-left-2"
              disabled={total === 0 || !requestedBy || isLoading}
              onClick={handleRefundRequest}
            >
              Refund
            </button>
          </div>
        </div>
      </div>

      <ErrorMessage
        errors={errors}
        className="margin-top-2 margin-bottom-0 is-justify-content-flex-end"
      />

      <p className="margin-top-4 margin-bottom-0" style={{ maxWidth: 750 }}>
        Travel Protection is refundable within {DAYS_TO_REFUND_TPP} days of
        purchase provided you have: (1) not already departed on your trip; or
        (2) not filed a claim. This provision applies only to your Travel
        Protection premium and does not apply to your tour payments. After{" "}
        {DAYS_TO_REFUND_TPP} days, Travel Protection is non-refundable and
        non-transferable.
      </p>
    </>
  );
};

const initGuestsToRefund = (guests: GuestDO[]) => guests.map(() => false);

export { RefundTpp };
