import {
  canBuyTpp,
  cardBrandLetter,
  CENTRAL_TZ,
  DiscountDO_Type,
  FeeDO_Type,
  feeLabel,
  feePayment,
  finalPaymentFee,
  formatCents,
  GuestDO,
  guestFee,
  isFeeWaived,
  MMM_d_yyyy,
  unpaidFees,
} from "data-model";
import { Checkbox, Input, Select } from "react-components";
import { FC, Fragment, useState } from "react";
import clsx from "clsx";
import { DateTime } from "luxon";

interface Props {
  departedAt: string;
  fees: FeeDO_Type[];
  guest: GuestDO;
  isLocked: boolean;
  onDiscountApply: (
    posIdx: number,
    type: DiscountDO_Type,
    amount: number
  ) => void;
  onFeeChange: (posIdx: number, feeType: FeeDO_Type) => void;
  onPayAll: (posIdx: number) => void;
  posIdx: number;
}

const PaymentEditor: FC<Props> = ({
  departedAt,
  fees,
  guest,
  isLocked,
  onDiscountApply,
  onFeeChange,
  onPayAll,
  posIdx,
}) => {
  const [discountType, setDiscountType] = useState<DiscountDO_Type | "">("");
  const [discountAmount, setDiscountAmount] = useState(0);
  const [discountPercent, setDiscountPercent] = useState(0);

  const feesDue = unpaidFees(guest, departedAt, true);
  const isPaidInFull = feesDue.length === 0;
  const isMissingName = !guest.fullName?.trim();

  const guestTotal = fees.reduce(
    (total, feeType) => total + guestFee(guest, feeType).total,
    0
  );

  return (
    <div
      className="is-grid is-column-gap-2 is-row-gap-1"
      style={{ gridTemplateColumns: "auto 1fr auto auto" }}
    >
      <h4 className="is-marginless is-grid-column-1-3">
        {isPaidInFull ? "Paid in Full" : "Check Items to Pay Now:"}
      </h4>
      <Checkbox
        id={`pay-all-${guest.id}`}
        parentClassName={clsx(
          "is-justify-content-flex-end is-align-items-flex-start",
          isPaidInFull && "is-invisible"
        )}
        checked={fees.length === feesDue.length}
        onChange={() => onPayAll(posIdx)}
        disabled={isLocked || isMissingName}
      />
      <label
        htmlFor={`pay-all-${guest.id}`}
        className={clsx(
          "margin-bottom-3",
          (isLocked || isMissingName) && "has-text-mid-gray is-unclickable",
          isPaidInFull && "is-invisible"
        )}
      >
        <strong>Pay All</strong>
      </label>

      {guest.fees.map((fee) => {
        const isAdded = fees.includes(fee.type);
        const payment = feePayment(fee);
        return (
          <Fragment key={fee.id}>
            <span className={clsx(!payment && "is-grid-column-1-3")}>
              {feeLabel(fee.type, !!payment)}:
            </span>
            {payment ? (
              <em className="is-grid-column-2-4 is-flex is-justify-content-space-between">
                <span>
                  {DateTime.fromISO(payment.submittedAt, {
                    zone: CENTRAL_TZ,
                  }).toFormat(MMM_d_yyyy)}{" "}
                  ({cardBrandLetter(payment.cardBrand)} {payment.cardLastFour})
                </span>
                <span>{fee.total / 100}</span>
              </em>
            ) : isFeeWaived(fee) ? (
              <span>{fee.total / 100}</span>
            ) : (
              <Checkbox
                id={`${fee.type}-${guest.id}`}
                labelClassName="is-order-neg-1 margin-right-2 margin-left-0"
                parentClassName="is-justify-content-flex-end"
                checked={isAdded}
                onChange={() => onFeeChange(posIdx, fee.type)}
                disabled={
                  isLocked ||
                  (fee.type !== FeeDO_Type.DEPOSIT && isMissingName) ||
                  (fee.type === FeeDO_Type.TPP &&
                    !canBuyTpp(guest, departedAt, true))
                }
              >
                <span className={clsx(isAdded && "is-invisible")}>
                  {fee.total / 100}
                </span>
              </Checkbox>
            )}
            <span
              className={clsx(
                "has-text-right",
                payment ? "has-text-green is-italic" : "has-text-weight-bold"
              )}
            >
              {payment ? "Paid" : isAdded ? fee.total / 100 : null}
            </span>
            {fee.discounts.map((discount) => (
              <em
                key={discount.id}
                className="is-grid-column-2-neg-1 has-text-green"
              >
                {discount.type} of ${discount.amount / 100} applied
              </em>
            ))}
          </Fragment>
        );
      })}

      {!isPaidInFull && (
        <div className="is-grid-column-1-neg-1 margin-y-3 is-flex">
          <Select
            parentClassName="is-width-max-content"
            value={discountType}
            onChange={(e) =>
              setDiscountType(e.currentTarget.value as DiscountDO_Type)
            }
          >
            <option disabled hidden value="">
              Discount...
            </option>
            {Object.values(DiscountDO_Type).map((type) => (
              <option key={type} value={type}>
                {type}
              </option>
            ))}
          </Select>
          {discountType && (
            <>
              {discountType === DiscountDO_Type.PrizeWinner ||
              discountType === DiscountDO_Type.SalesFamiliarization ? (
                <Select
                  parentClassName="margin-x-2"
                  value={discountPercent}
                  onChange={(e) => setDiscountPercent(+e.currentTarget.value)}
                  disabled // FIXME
                >
                  <option disabled hidden value={0}>
                    %
                  </option>
                  <option value={50}>50%</option>
                  <option value={100}>100%</option>
                </Select>
              ) : (
                <Input
                  value={discountAmount.toString()}
                  className="margin-x-2"
                  style={{ maxWidth: 60 }}
                  onChange={(e) => {
                    const str = e.currentTarget.value;
                    if (str !== "" && !/^\d+$/.test(str)) return; // allow "" or digits

                    setDiscountAmount(str === "" ? 0 : +str);
                  }}
                />
              )}
              <button
                className="button is-ghost is-link"
                disabled={
                  !discountType ||
                  discountAmount <= 0 ||
                  discountAmount >= finalPaymentFee(guest).total / 100 // FIXME
                }
                onClick={() => {
                  onDiscountApply(posIdx, discountType, discountAmount * 100);
                  setDiscountType("");
                  setDiscountAmount(0);
                  setDiscountPercent(0);
                }}
              >
                Apply
              </button>
            </>
          )}
        </div>
      )}

      <div
        className={clsx("is-display-contents", isPaidInFull && "is-invisible")}
      >
        <h4 className="is-marginless">Guest Total:</h4>
        <strong className="is-grid-column-2-neg-1 has-text-right">
          {guestTotal ? formatCents(guestTotal) : null}
        </strong>
      </div>
    </div>
  );
};

export { PaymentEditor };
