import { SetAccount } from "./AccountManager";
import { editAccount } from "../../api";
import { PopularCountries } from "../../components";
import {
  AccountDO,
  BookingEditAccountReq,
  countriesByCode,
  isObjectEmpty,
  regionsByCountryCode,
  sortRegionsAlpha,
} from "data-model";
import {
  ErrorMessage,
  Input,
  PhoneNumber,
  Select,
  SVG,
  useErrors,
} from "react-components";
import { FC, FormEvent, useState } from "react";

interface Props {
  account: AccountDO;
  setAccount: SetAccount;
  onClose: () => void;
}

const EditAccount: FC<Props> = ({ account, setAccount, onClose }) => {
  const [firstName, setFirstName] = useState(account.firstName);
  const [lastName, setLastName] = useState(account.lastName);
  const [phoneNumber, setPhoneNumber] = useState(account.phoneNumber);
  const [email, setEmail] = useState(account.email);
  const { address } = account;
  const [line1, setLine1] = useState(address.line1);
  const [line2, setLine2] = useState(address.line2 || "");
  const [country, setCountry] = useState(address.country);
  const [state, setState] = useState(address.state);
  const [city, setCity] = useState(address.city);
  const [zip, setZip] = useState(address.zip);

  const [isLoading, setIsLoading] = useState(false);
  const [errors, catchErrors] = useErrors();

  const handleSave = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    setIsLoading(true);

    catchErrors(
      async () => {
        const form: BookingEditAccountReq = { address: {} };
        if (firstName !== account.firstName) form.firstName = firstName;
        if (lastName !== account.lastName) form.lastName = lastName;
        if (phoneNumber !== account.phoneNumber) form.phoneNumber = phoneNumber;
        if (email !== account.email) form.email = email;
        if (line1 !== address.line1) form.address!.line1 = line1;
        if (line2 !== address.line2 && (line2 || address.line2))
          form.address!.line2 = line2;
        if (country !== address.country) form.address!.country = country;
        if (state !== address.state) form.address!.state = state;
        if (city !== address.city) form.address!.city = city;
        if (zip !== address.zip) form.address!.zip = zip;
        if (isObjectEmpty(form.address!)) {
          delete form.address;
        }

        if (!isObjectEmpty(form)) {
          const updatedAccount = await editAccount(account.id, form);
          setAccount((account) => ({ ...account, ...updatedAccount }));
        }

        onClose();
      },
      () => setIsLoading(false)
    );
  };

  return (
    <div
      className="is-absolute has-background-white padding-4 has-shadow-gray is-z-index-1 is-centered-x"
      style={{ top: 50, width: "calc(100% - 40px)" }}
    >
      <h2 className="margin-top-0 margin-bottom-3 is-flex is-justify-content-space-between">
        Edit Account Information
        {isLoading && (
          <SVG path="/site/icon/spinner" alt="Loading" height={20} />
        )}
      </h2>

      <form
        className="is-grid is-grid-template-columns-1fr-4fr is-align-items-center is-column-gap-3 is-row-gap-1"
        onSubmit={handleSave}
      >
        <label htmlFor="edit-firstName">First Name:</label>
        <Input
          id="edit-firstName"
          value={firstName}
          onChange={(e) => setFirstName(e.currentTarget.value)}
        />

        <label htmlFor="edit-lastName">Last Name:</label>
        <Input
          id="edit-lastName"
          value={lastName}
          onChange={(e) => setLastName(e.currentTarget.value)}
        />

        <label htmlFor="edit-phoneNumber">Mobile:</label>
        <PhoneNumber
          id="edit-phoneNumber"
          value={phoneNumber}
          onChange={setPhoneNumber}
        />

        <label htmlFor="edit-email">Email:</label>
        <Input
          id="edit-email"
          type="email"
          value={email}
          onChange={(e) => setEmail(e.currentTarget.value)}
        />

        <label htmlFor="edit-line1">Address:</label>
        <Input
          id="edit-line1"
          value={line1}
          onChange={(e) => setLine1(e.currentTarget.value)}
        />

        <label htmlFor="edit-line2">Apt/Unit/Suite:</label>
        <Input
          id="edit-line2"
          value={line2}
          onChange={(e) => setLine2(e.currentTarget.value)}
        />

        <label htmlFor="edit-country">Country:</label>
        <Select
          id="edit-country"
          value={country}
          onChange={(e) => {
            setCountry(e.currentTarget.value);
            setState("");
          }}
        >
          <option value="" />
          <PopularCountries />
          {Object.entries(countriesByCode).map(([code, name]) => (
            <option key={code} value={code}>
              {name}
            </option>
          ))}
        </Select>

        <label htmlFor="edit-city">City:</label>
        <Input
          id="edit-city"
          value={city}
          onChange={(e) => setCity(e.currentTarget.value)}
        />

        <label htmlFor="edit-state">State/Prov:</label>
        <Select
          id="edit-state"
          value={state}
          onChange={(e) => setState(e.currentTarget.value)}
        >
          <option value="" />
          {country &&
            Object.entries(regionsByCountryCode[country])
              .sort(sortRegionsAlpha)
              .map(([code, name]) => (
                <option key={code} value={code}>
                  {name}
                </option>
              ))}
        </Select>

        <label htmlFor="edit-zip">Zip:</label>
        <Input
          id="edit-zip"
          value={zip}
          onChange={(e) => setZip(e.currentTarget.value)}
        />

        {!!errors.length && (
          <div className="is-grid-column-2-neg-1 margin-top-1">
            <ErrorMessage errors={errors} className="is-marginless" />
          </div>
        )}

        <div className="is-grid-column-2-neg-1 is-flex margin-top-3">
          <button
            type="button"
            className="button is-light-blue is-outlined padding-x-4 margin-right-3"
            onClick={onClose}
            disabled={isLoading}
          >
            Cancel
          </button>

          <button
            className="button is-light-blue padding-x-4"
            disabled={
              isLoading ||
              !firstName ||
              !lastName ||
              !phoneNumber ||
              !email ||
              !line1 ||
              !country ||
              !state ||
              !city ||
              !zip
            }
          >
            Save Changes
          </button>
        </div>
      </form>
    </div>
  );
};

export { EditAccount };
