import { EditAccount } from "./EditAccount";
import { AccountInfo } from "./AccountInfo";
import { AccountFinder } from "./AccountFinder";
import { bookingIdParam } from "../ManageBooking";
import { ActionMenu, Notes, OptionDivider } from "../../components";
import { deleteHold, findAccount, makeNote, recoverPassword } from "../../api";
import { db, tourYearsById } from "../../jsongo";
import { SVG, Checkbox, statusForHumans, Image } from "react-components";
import {
  AccountDO,
  BookingDO,
  categorizeBookings,
  ccc_MMM_d_yyyy,
  durationDays,
  fullName,
  guestsRooms,
  isHoldActive,
  isHoldDeleted,
  isHoldExpired,
  isLastItem,
  NoteDO_Type,
  TourDO,
  TourYearDO,
  yearFromDate,
} from "data-model";
import {
  CSSProperties,
  Dispatch,
  FC,
  SetStateAction,
  useEffect,
  useState,
} from "react";
import clsx from "clsx";
import { Link, useSearchParams } from "react-router-dom";
import { DateTime } from "luxon";

enum MenuOptions {
  edit = "edit",
  reset = "reset",
  delete = "delete",
  history = "history",
  find = "find",
}

const listStyle: CSSProperties = { maxHeight: "calc(100vh - 518px)" };

export const accountIdParam = "account-id";

interface Props {
  account: AccountDO | null;
  setAccount: Dispatch<SetStateAction<AccountDO | null>>;
}

type AccountSetter = (account: AccountDO) => AccountDO;
export type SetAccount = (cb: AccountSetter) => void;

const AccountManager: FC<Props> = ({ account, setAccount }) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [showEdit, setShowEdit] = useState(false);

  const [isLoading, setIsLoading] = useState(searchParams.has(accountIdParam));

  useEffect(() => {
    const fetchAccount = async () => {
      const accountId = searchParams.get(accountIdParam);
      if (accountId) {
        const account = await findAccount(+accountId);
        setAccount(account);
        setIsLoading(false);
        // We don't attempt to validate bookingIdParam if it's present.
      }
    };
    fetchAccount();
  }, []);

  const handleAccountApply = (account: AccountDO) => {
    setAccount(account);
    searchParams.append(accountIdParam, `${account.id}`);
    searchParams.delete(bookingIdParam); // TODO don't delete if they select the same account they cleared
    setSearchParams(searchParams);
  };

  const setAccountIfNotNull = (cb: AccountSetter) => {
    setAccount((account) => {
      if (!account) return null; // account was cleared
      return cb(account);
    });
  };

  return (
    <div className="is-flex is-flex-direction-column has-background-white has-border-gray is-relative">
      <h1
        className={clsx(
          "is-flex is-align-items-center padding-2 is-marginless",
          account && "has-border-bottom-gray"
        )}
      >
        <SVG
          path="/site/icon/person-black"
          alt="Person"
          height={23}
          className="margin-right-2"
        />
        <span className="is-flex-1">
          {account ? fullName(account) : "Find or Create Account"}
        </span>
        {isLoading && (
          <SVG
            path="site/icon/spinner"
            alt="Spinner"
            className="margin-right-2"
            height={20}
          />
        )}
        <ActionMenu
          onChange={async (e) => {
            if (!account) throw new Error("unreachable");

            const action = e.currentTarget.value as MenuOptions;
            if (action === MenuOptions.edit) {
              setShowEdit(true);
              return;
            } else if (action === MenuOptions.reset) {
              setIsLoading(true);
              await recoverPassword(account.id);
              setIsLoading(false);
              return;
            } else if (action === MenuOptions.delete) {
              return;
            } else if (action === MenuOptions.history) {
              return;
            } else if (action === MenuOptions.find) {
              setAccount(null);
              searchParams.delete(accountIdParam);
              // Note that if present, bookingIdParam remains in the URL.
              setSearchParams(searchParams);
            }
          }}
        >
          <option disabled={!account} value={MenuOptions.edit}>
            Edit Account Information
          </option>
          <option disabled={!account} value={MenuOptions.reset}>
            Reset Client Password
          </option>
          <option disabled value={MenuOptions.delete}>
            Delete this Account
          </option>
          <option disabled value={MenuOptions.history}>
            Search History
          </option>
          <OptionDivider />
          <option disabled={!account} value={MenuOptions.find}>
            Find Account (Clear)
          </option>
        </ActionMenu>
      </h1>
      {account ? (
        <>
          <AccountInfo account={account} className="padding-y-3 padding-x-2" />

          <AccountNotes account={account} setAccount={setAccountIfNotNull} />

          <div className="padding-y-3 padding-x-2 padding-bottom-3 is-flex-1 has-border-top-gray">
            <BookingExplorer
              bookings={account.bookings}
              listStyle={listStyle}
              setAccount={setAccountIfNotNull}
            />
          </div>
        </>
      ) : searchParams.has(accountIdParam) ? null : (
        <AccountFinder
          className="padding-2"
          onAccountApply={handleAccountApply}
        />
      )}

      {showEdit && (
        <EditAccount
          account={account!}
          setAccount={setAccountIfNotNull}
          onClose={() => setShowEdit(false)}
        />
      )}
    </div>
  );
};

export { AccountManager };

interface BookingExplorerProps {
  bookings: BookingDO[];
  hideControls?: boolean;
  listStyle?: CSSProperties;
  setAccount: SetAccount;
}

type BookingCategory = keyof ReturnType<typeof categorizeBookings>;

const categoryNames: Record<BookingCategory, string> = {
  activeHolds: "Active Holds",
  activeBookings: "Active Bookings",
  expiredOrDeleted: "Expired/Deleted Holds",
  travelled: "Travelled Bookings",
  cancelled: "Cancelled Bookings",
};

const categoryColumns: BookingCategory[][] = [
  ["activeHolds", "activeBookings"],
  ["expiredOrDeleted", "travelled", "cancelled"],
];

const allCategories = Object.keys(categoryNames) as BookingCategory[];
const sortCategories = (catA: BookingCategory, catB: BookingCategory) =>
  allCategories.indexOf(catA) - allCategories.indexOf(catB);

const BookingExplorer: FC<BookingExplorerProps> = ({
  bookings,
  hideControls = false,
  listStyle,
  setAccount,
}) => {
  const bookingMap = categorizeBookings(bookings, tourYearsById);

  const [categories, setCategories] = useState<BookingCategory[]>([
    "activeHolds",
  ]);

  const handleHoldDelete = async (holdId: number) => {
    const { deletedAt } = await deleteHold(holdId);
    setAccount((account) => ({
      ...account,
      bookings: account.bookings.map((b) => {
        if (b.id === holdId) {
          return { ...b, deletedAt };
        }
        return b;
      }),
    }));
  };

  return (
    <>
      <div className="is-flex">
        {categoryColumns.map((column, idx) => (
          <div key={idx} className="is-flex is-flex-direction-column">
            {column.map((category) => (
              <Checkbox
                key={category}
                id={category}
                checked={categories.includes(category)}
                onChange={(e) => {
                  if (e.currentTarget.checked) {
                    setCategories((cats) =>
                      [...cats, category].sort(sortCategories)
                    );
                  } else {
                    setCategories((cats) =>
                      cats.filter((c) => c !== category).sort(sortCategories)
                    );
                  }
                }}
                inline
                parentClassName="margin-right-4 margin-bottom-2"
              >
                {categoryNames[category]} ({bookingMap[category].length})
              </Checkbox>
            ))}
          </div>
        ))}
      </div>

      <div className="margin-top-1 is-scrollable-y" style={listStyle}>
        {categories.map((category, categoryIdx) =>
          bookingMap[category].map((booking, bookingIdx, bookingsArr) => (
            <BookingSummary
              key={booking.id}
              booking={booking}
              className={clsx(
                isLastItem(categoryIdx, categories.length) &&
                  isLastItem(bookingIdx, bookingsArr.length)
                  ? ""
                  : "margin-bottom-3"
              )}
              hideControls={hideControls}
              onDelete={handleHoldDelete}
            />
          ))
        )}
      </div>
    </>
  );
};

export { BookingExplorer };

interface BookingSummaryProps {
  booking: BookingDO;
  className?: string;
  hideControls: boolean;
  onDelete: (holdId: number) => void;
}

const BookingSummary: FC<BookingSummaryProps> = ({
  booking,
  className,
  hideControls,
  onDelete,
}) => {
  const tour = db.tour.findByIdOrFail(booking.tour) as TourDO;
  const tourYear = db.tourYear.findByIdOrFail({
    tour_id: booking.tour,
    year: yearFromDate(booking.departedAt),
  }) as TourYearDO;

  const isActiveHold = isHoldActive(booking);

  const { label, description } = statusForHumans(booking, tourYear, true);

  const [isDeleting, setIsDeleting] = useState(false);

  return (
    <>
      <div
        className={clsx(
          "is-flex is-flex-direction-column is-relative",
          className
        )}
      >
        <div className="is-flex is-align-items-center margin-bottom-1">
          <figure
            className="is-aspect-ratio-golden has-background-tint-gray margin-right-1"
            style={{ height: 30 }}
          >
            <Image path={tour.page["headerA(img_id)"]} alt="headerA" />
          </figure>
          <figure
            className="is-aspect-ratio-golden has-background-tint-gray margin-right-2"
            style={{ height: 30 }}
          >
            <Image path={tour.page["headerB(img_id)"]} alt="headerB" />
          </figure>
          <h3 className="is-marginless has-text-weight-normal is-size-2">
            {booking.number} (
            {isActiveHold || isHoldExpired(booking) || isHoldDeleted(booking)
              ? "Hold"
              : "Booking"}
            )
          </h3>
          {!hideControls && (
            <div className="is-flex-1 is-flex is-justify-content-flex-end">
              {isActiveHold && (
                <button
                  className="button is-ghost is-link is-paddingless margin-right-4"
                  onClick={() => setIsDeleting(true)}
                >
                  Delete
                </button>
              )}
              <Link
                className="button is-ghost is-link is-paddingless margin-right-4"
                to={`/manage-booking?${bookingIdParam}=${booking.id}&${accountIdParam}=${booking.owner}`}
              >
                Manage
              </Link>
            </div>
          )}
          <div
            className={clsx(
              "is-absolute has-background-white padding-x-3 padding-y-2 has-border-bottom-gray has-shadow-gray is-z-index-1",
              !isDeleting && "is-hidden"
            )}
            style={{ bottom: 2, right: 2 }}
          >
            <h3 className="margin-top-0 has-text-centered">Delete Hold?</h3>
            <div className="is-flex is-justify-content-center">
              <button
                className="button padding-x-4 margin-right-2"
                onClick={() => setIsDeleting(false)}
              >
                No
              </button>
              <button
                className="button is-light-blue padding-x-4"
                onClick={() => {
                  onDelete(booking.id);
                  setIsDeleting(false);
                }}
              >
                Yes
              </button>
            </div>
          </div>
        </div>
        <h4 className="is-marginless">
          {tour.name} ({durationDays(tourYear)} Days)
        </h4>
        <span>
          Starts:{" "}
          {DateTime.fromISO(booking.departedAt).toFormat(ccc_MMM_d_yyyy)},{" "}
          {tourYear.cityStart}
        </span>
        <span>
          Ends:{" "}
          {DateTime.fromISO(booking.departure.concludedAt).toFormat(
            ccc_MMM_d_yyyy
          )}
          , {tourYear.cityEnd}
        </span>
        <span>{guestsRooms(booking.roomGuests)}</span>
        <span>
          {label} {description}
        </span>
      </div>
    </>
  );
};

interface AccountNotesProps {
  account: AccountDO;
  isLocked?: boolean;
  setAccount: SetAccount;
}

const AccountNotes: FC<AccountNotesProps> = ({
  account,
  isLocked = false,
  setAccount,
}) => (
  <Notes
    category="Account"
    currentBody={account.note?.body}
    isLocked={isLocked}
    onNoteSave={async (body) => {
      const newNote = await makeNote({
        accountId: account.id,
        type: NoteDO_Type.ACCOUNT,
        body,
      });
      setAccount((account) => ({ ...account, note: newNote }));
    }}
  />
);

export { AccountNotes };
