import { GuestEditor } from "./GuestEditor";
import { FlightEditor } from "./FlightEditor";
import { PaymentEditor } from "./PaymentEditor";
import { RoomProps } from "../CollapsedRoom";
import { RoomControls } from "../RoomControls";
import { makeRequest, revokeRequest } from "../../../api";
import {
  DiscountDO_Type,
  FeeDO_Type,
  findRoomRequest,
  fullNameOrPlaceholder,
  GuestDO,
  isGuestAccountOwner,
  primaryGuestInfo,
  referencedRoomIdx,
  roomAbbrCapitalized,
  RoomRequestDO,
  RoomRequestDO_Type,
  roomRequestLabel,
  TransportDO,
} from "data-model";
import { Checkbox, Select, SVG } from "react-components";
import { CSSProperties, FC, Fragment, useState } from "react";

const { CONNECTED_OR_ADJOINING, BED_TYPE, STAIR_FREE } = RoomRequestDO_Type;

interface Props extends RoomProps {
  fees: FeeDO_Type[][];
  isMakingPayment: boolean;
  onFeeChange: (posIdx: number, feeType: FeeDO_Type) => void;
  onDiscountApply: (
    posIdx: number,
    type: DiscountDO_Type,
    amount: number
  ) => void;
  onDetailsChange: (updatedGuest: GuestDO) => void;
  onPayAll: (posIdx: number) => void;
  onRequestChange: (request: RoomRequestDO, isAdded: boolean) => void;
  onTransportChange: (
    guestId: number,
    isArrival: boolean,
    transport?: TransportDO
  ) => void;
}

const EditableRoom: FC<Props> = ({
  booking,
  fees,
  guests,
  isMakingPayment,
  onDetailsChange,
  onDiscountApply,
  onFeeChange,
  onModeChange,
  onPayAll,
  onRequestChange,
  onTransportChange,
  posIdx,
  roomIdx,
  roomMode,
  tourYear,
}) => {
  const guestsInRoom = booking.roomGuests[roomIdx];
  const { primaryPosIdx, primaryGuest, primaryAddress } = primaryGuestInfo(
    booking.guests,
    booking.owner
  );
  const primaryName = fullNameOrPlaceholder(
    booking.guests,
    booking.owner,
    primaryPosIdx
  );

  return (
    <>
      <div className="padding-2 has-background-white is-flex is-align-items-center is-grid-column-1-neg-1">
        <RoomControls activeMode={roomMode} onChange={onModeChange} />
        <h3 className="is-marginless is-size-2 margin-right-3">
          Room {roomIdx + 1} &ndash; {roomAbbrCapitalized(guestsInRoom)}
        </h3>
        <RoomRequests
          booking={booking}
          onRequestChange={onRequestChange}
          roomIdx={roomIdx}
        />
      </div>
      {guests.map((guest) => {
        const isPrimary = guest === primaryGuest;
        return (
          <Fragment key={guest.id}>
            <div className="padding-2 has-background-white">
              <GuestEditor
                concludedAt={booking.departure.concludedAt}
                guest={guest}
                isOwner={isGuestAccountOwner(
                  booking.guests,
                  booking.owner,
                  posIdx
                )}
                isPrimary={isPrimary}
                onDetailsChange={onDetailsChange}
                owner={booking.owner}
                posIdx={posIdx}
                primaryAddress={primaryAddress}
                primaryFullName={primaryName}
                primaryGuest={primaryGuest}
                tourYear={tourYear}
              />
            </div>
            <div className="padding-2 has-background-white">
              <div className="is-grid is-grid-template-columns-auto-1fr is-column-gap-1 is-row-gap-2 is-align-items-center">
                <FlightEditor
                  key={`arrival-${primaryGuest.id}-${isPrimary}`}
                  booking={booking}
                  guest={guest}
                  isArrival
                  isPrimary={isPrimary}
                  onChange={onTransportChange}
                  primaryFullName={primaryName}
                  primaryGuest={primaryGuest}
                />

                <FlightEditor
                  key={`departure-${primaryGuest.id}-${isPrimary}`}
                  booking={booking}
                  className="margin-top-4"
                  guest={guest}
                  isArrival={false}
                  isPrimary={isPrimary}
                  onChange={onTransportChange}
                  primaryFullName={primaryName}
                  primaryGuest={primaryGuest}
                />
              </div>
            </div>
            <div className="padding-2 has-background-white">
              <PaymentEditor
                departedAt={booking.departedAt}
                fees={fees[posIdx]}
                guest={guest}
                isLocked={isMakingPayment}
                onDiscountApply={onDiscountApply}
                onFeeChange={onFeeChange}
                onPayAll={onPayAll}
                posIdx={posIdx++}
              />
            </div>
          </Fragment>
        );
      })}
    </>
  );
};

export { EditableRoom };

type RoomRequestsProps = Pick<Props, "booking" | "onRequestChange" | "roomIdx">;

const selectStyles: CSSProperties = { paddingTop: 3, paddingBottom: 3 };

const RoomRequests: FC<RoomRequestsProps> = ({
  booking,
  onRequestChange,
  roomIdx,
}) => {
  const [isLoading, setIsLoading] = useState(false);

  // See if this room connects to another room, or another room connects to this room.
  const connectReq = findRoomRequest(
    booking.roomRequests,
    roomIdx,
    CONNECTED_OR_ADJOINING
  );
  const bedReq = findRoomRequest(booking.roomRequests, roomIdx, BED_TYPE);
  const stairReq = findRoomRequest(booking.roomRequests, roomIdx, STAIR_FREE);

  return (
    <>
      <Select
        id={`${CONNECTED_OR_ADJOINING}-${roomIdx}`}
        style={selectStyles}
        parentClassName="margin-right-3"
        disabled={booking.roomGuests.length === 1}
        value={referencedRoomIdx(roomIdx, connectReq)}
        onChange={async (e) => {
          setIsLoading(true);
          const refRoomIndex = +e.currentTarget.value;
          // See if the room that's being connected to already has a
          // connection to another room (either direct or inverse).
          const otherConnectReq = findRoomRequest(
            booking.roomRequests,
            refRoomIndex,
            CONNECTED_OR_ADJOINING
          );

          try {
            // Both can exist. Ex: room 2 connects to room 3, room 1 connects
            // to room 4, and we want to connect room 1 to room 3.
            await Promise.all(
              [
                connectReq && revokeRequest(connectReq.id),
                otherConnectReq && revokeRequest(otherConnectReq.id),
              ].filter(Boolean)
            );

            if (refRoomIndex !== -1) {
              const req = await makeRequest(booking.id, {
                type: CONNECTED_OR_ADJOINING,
                roomIndex: roomIdx,
                refRoomIndex,
              });
              onRequestChange(req, true);
            }

            // Goes last to avoid flicker to & from -1
            if (connectReq) onRequestChange(connectReq, false);
            if (otherConnectReq) onRequestChange(otherConnectReq, false);
          } finally {
            setIsLoading(false);
          }
        }}
      >
        <option value={-1}>Connected/adjoining to Room...</option>
        {booking.roomGuests.map((_, idx) =>
          idx === roomIdx ? null : (
            <option key={idx} value={idx}>
              {roomRequestLabel({
                type: CONNECTED_OR_ADJOINING,
                refRoomIndex: idx,
              })}
            </option>
          )
        )}
      </Select>
      <Select
        id={`${BED_TYPE}-${roomIdx}`}
        style={selectStyles}
        parentClassName="margin-right-3"
        value={bedReq?.selection || ""}
        onChange={async (e) => {
          setIsLoading(true);
          const selection = e.currentTarget.value;
          try {
            if (bedReq) {
              await revokeRequest(bedReq.id);
              onRequestChange(bedReq, false);
            }

            if (selection) {
              const req = await makeRequest(booking.id, {
                type: BED_TYPE,
                roomIndex: roomIdx,
                selection,
              });
              onRequestChange(req, true);
            }
          } finally {
            setIsLoading(false);
          }
        }}
      >
        <option value="">Bed Type...</option>
        <option>Two-bedded room</option>
        <option>King/Queen</option>
      </Select>
      <Checkbox
        id={`${STAIR_FREE}-${roomIdx}`}
        parentClassName="margin-right-3"
        checked={!!stairReq}
        onChange={async () => {
          setIsLoading(true);
          try {
            if (stairReq) {
              await revokeRequest(stairReq.id);
              onRequestChange(stairReq, false);
            } else {
              const req = await makeRequest(booking.id, {
                type: STAIR_FREE,
                roomIndex: roomIdx,
              });
              onRequestChange(req, true);
            }
          } finally {
            setIsLoading(false);
          }
        }}
      >
        {roomRequestLabel({ type: STAIR_FREE })}
      </Checkbox>
      <em className="has-text-dark-gray">Not guaranteed</em>
      {isLoading && (
        <SVG
          path="/site/icon/spinner"
          alt="Loading"
          height={20}
          className="margin-left-3"
        />
      )}
    </>
  );
};
