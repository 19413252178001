import { SVG, Textarea, useModal } from "react-components";
import { FC, useEffect, useRef, useState } from "react";
import clsx from "clsx";

export const buttonStyle = { width: 28 };

interface Props {
  category: string;
  currentBody?: string;
  isLocked?: boolean;
  onNoteSave: (body: string) => Promise<void>;
  placeholder?: string;
  rows?: number;
}

const Notes: FC<Props> = ({
  category,
  currentBody,
  isLocked = false,
  onNoteSave,
  placeholder,
  rows = 5,
}) => {
  const setModal = useModal();
  const [isLoading, setIsLoading] = useState(false);

  const [body, setBody] = useState(currentBody || "");
  const textareaRef = useRef<HTMLTextAreaElement>(null);

  const handleSave = async () => {
    // Skip when changes are undone back to original (including "")
    if (currentBody === body || (!currentBody && !body)) return;

    setIsLoading(true);
    await onNoteSave(body);
    setIsLoading(false);
  };

  const handleExpand = () => {
    setModal({
      isOpen: true,
      body: (
        <ExpandedNoteModal
          category={category}
          initBody={body}
          onChange={setBody}
          placeholder={placeholder}
        />
      ),
      className: "danno-modal",
      onClose: () => {
        // When handleSave is called here, its `body` var
        // is stale, so it wouldn't work as expected.
        textareaRef.current?.focus();
        textareaRef.current?.blur();
      },
    });
  };

  return (
    <>
      <div className="has-border-y-gray is-flex">
        <h3
          className={clsx(
            "is-marginless padding-y-1 padding-x-2 is-flex-1 is-flex",
            !isLocked && "has-border-right-gray"
          )}
        >
          <span className="is-flex-1">{category} Notes:</span>
          {isLoading && (
            <SVG path="site/icon/spinner" alt="Spinner" height={18} />
          )}
        </h3>
        {!isLocked && (
          <button
            className="button is-ghost"
            style={buttonStyle}
            onClick={handleExpand}
          >
            <SVG path="site/icon/expand" alt="Expand" height={15} />
          </button>
        )}
      </div>
      <Textarea
        rows={rows}
        innerRef={textareaRef}
        className="is-borderless is-placeholder-italic"
        placeholder={placeholder}
        onBlur={handleSave}
        value={body}
        onChange={(e) => setBody(e.currentTarget.value)}
        readOnly={isLocked}
      />
    </>
  );
};

export { Notes };

interface ExpandedNoteModalProps {
  category: string;
  initBody: string;
  onChange: (body: string) => void;
  placeholder?: string;
}

const ExpandedNoteModal: FC<ExpandedNoteModalProps> = ({
  category,
  initBody,
  onChange,
  placeholder,
}) => {
  const [body, setBody] = useState(initBody);
  const textareaRef = useRef<HTMLTextAreaElement>(null);

  useEffect(() => textareaRef.current?.focus(), []);

  return (
    <>
      <h1 className="is-size-2">{category} Notes:</h1>

      <Textarea
        innerRef={textareaRef}
        rows={30}
        className="is-resizable"
        placeholder={placeholder}
        value={body}
        onChange={(e) => {
          const value = e.currentTarget.value;
          setBody(value);
          onChange(value);
        }}
      />
    </>
  );
};
