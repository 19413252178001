import { SVG } from "react-components";

const Slider = () => (
  <div className="has-background-light-gray has-border-gray is-rounded-small is-flex is-flex-direction-column is-align-items-center">
    <button className="button is-ghost padding-2 margin-bottom-3">
      <SVG
        path="/site/icon/caret-right-heavy"
        alt="Right Arrow"
        className="is-flipped-x"
        height={20}
      />
    </button>
    <strong className="has-text-vertical-bottom-up is-size-1 has-text-blue">
      Caravan<span className="has-text-red">.com</span>
    </strong>
  </div>
);

export { Slider };
