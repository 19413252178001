import { editDetails } from "../../../api";
import { PopularCountries } from "../../../components";
import {
  AccountDO,
  AddressDO,
  arrOfSize,
  countriesByCode,
  DannoEditDetailsReq,
  fullName as getFullName,
  GuestDO,
  passportMustExpireAfter,
  passportRequiresRenewal,
  regionsByCountryCode,
  sortRegionsAlpha,
  TourYearDO,
} from "data-model";
import {
  Checkbox,
  Datepicker,
  Input,
  PassportExpires,
  PassportNotice,
  PhoneNumber,
  Select,
  SVG,
  Textarea,
} from "react-components";
import { FC, useCallback, useEffect, useRef, useState } from "react";
import clsx from "clsx";
import debounce from "lodash.debounce";

const grid1frAuto1fr = { gridTemplateColumns: "1fr auto 1fr" };

interface Props {
  concludedAt: string;
  guest: GuestDO;
  isOwner: boolean;
  isPrimary: boolean;
  onDetailsChange: (updatedGuest: GuestDO) => void;
  owner: AccountDO;
  posIdx: number;
  primaryAddress?: AddressDO;
  primaryFullName: string;
  primaryGuest: GuestDO;
  tourYear: TourYearDO;
}

const GuestEditor: FC<Props> = ({
  concludedAt,
  guest,
  isOwner,
  isPrimary,
  onDetailsChange,
  owner,
  posIdx,
  primaryAddress,
  primaryFullName,
  primaryGuest,
  tourYear,
}) => {
  const isMounted = useRef(false);
  const [isLoading, setIsLoading] = useState(false);

  const ownerFullName = getFullName(owner);
  const [fullName, setFullName] = useState(
    guest.fullName ?? (isOwner ? ownerFullName : "")
  );
  const [phoneNumber, setPhoneNumber] = useState(
    guest.phoneNumber ?? (isOwner ? owner.phoneNumber : "")
  );
  const [email, setEmail] = useState(
    guest.email ?? (isOwner ? owner.email : "")
  );

  const address = guest.address || primaryAddress;
  const [isSameAddress, setIsSameAddress] = useState(
    !isPrimary && address?.id === primaryAddress?.id
  );
  const [line1, setLine1] = useState(address?.line1 || "");
  const [line2, setLine2] = useState(address?.line2 || "");
  const [city, setCity] = useState(address?.city || "");
  const [state, setState] = useState(address?.state || "");
  const [country, setCountry] = useState(address?.country || "");
  const [zip, setZip] = useState(address?.zip || "");

  const contact = guest.emergencyContact ?? primaryGuest.emergencyContact;
  const [isSameContact, setIsSameContact] = useState(
    !isPrimary && contact === primaryGuest.emergencyContact
  );
  const [emergencyContact, setEmergencyContact] = useState(contact);
  const [age, setAge] = useState(guest.age || "");
  const [specialReq, setSpecialReq] = useState(guest.specialRequest || "");
  const [a11yReq, setA11yReq] = useState(guest.accessibilityRequest || "");

  const [passportNumber, setPassportNumber] = useState(
    guest.passportNumber || ""
  );
  const [passportCountry, setPassportCountry] = useState(
    guest.passportCountry ?? (tourYear.requiresPassport ? "US" : "")
  );
  const [passportBornAt, setPassportBornAt] = useState(guest.passportBornAt);
  const [passportExpiredAt, setPassportExpiredAt] = useState(
    guest.passportExpiredAt
  );

  const passportMustExpAfter = passportMustExpireAfter(concludedAt);
  const mustRenewPassport = passportRequiresRenewal(
    passportMustExpAfter,
    passportExpiredAt
  );

  const saveDetails = async (
    guestId: number,
    args: DannoEditDetailsReq,
    signal: AbortSignal
  ) => {
    setIsLoading(true);
    try {
      const updatedGuest = await editDetails(guestId, args, { signal });
      onDetailsChange(updatedGuest);
    } finally {
      setIsLoading(false);
    }
  };

  const debounceSaveDetails = useCallback(debounce(saveDetails, 1000), []);

  useEffect(() => {
    if (!isMounted.current) {
      isMounted.current = true;
      return;
    }

    const controller = new AbortController();

    const args: DannoEditDetailsReq = {
      fullName,
      phoneNumber,
      email,
      address: isSameAddress
        ? undefined
        : { line1, line2, city, state, country, zip },
      emergencyContact:
        isSameContact || (isPrimary && emergencyContact === "")
          ? undefined
          : emergencyContact,
      age: age === "" ? undefined : +age,
      specialRequest: specialReq,
      accessibilityRequest: a11yReq,
    };
    if (tourYear.requiresPassport) {
      Object.assign(args, {
        passportNumber,
        passportCountry,
        passportBornAt,
        passportExpiredAt,
      });
    }

    debounceSaveDetails(guest.id, args, controller.signal);

    return () => controller.abort();
  }, [
    fullName,
    phoneNumber,
    email,
    line1,
    line2,
    city,
    state,
    country,
    zip,
    emergencyContact,
    age,
    specialReq,
    a11yReq,
    isSameAddress,
    isSameContact,
    passportNumber,
    passportCountry,
    passportBornAt,
    passportExpiredAt,
  ]);

  return (
    <form
      className="is-grid is-grid-template-columns-auto-1fr is-column-gap-3 is-align-items-center"
      autoComplete="off"
    >
      <label htmlFor={`fullName-${guest.id}`}>Name:</label>
      <div className="is-flex is-align-items-center">
        <Input
          id={`fullName-${guest.id}`}
          value={fullName}
          onChange={(e) => setFullName(e.currentTarget.value)}
          placeholder={`Guest ${posIdx + 1}`}
          className="has-text-weight-bold"
          parentClassName="is-flex-1"
          childrenRight={
            <SVG
              className={clsx(
                "field-helper",
                !(isPrimary && fullName === ownerFullName) && "is-hidden"
              )}
              path="/site/icon/person-black"
              alt="Person"
              height={15}
            />
          }
        />
        <SVG
          className={clsx("margin-left-2", !isLoading && "is-invisible")}
          path="/site/icon/spinner"
          alt="Loading"
          height={20}
        />
      </div>

      <label htmlFor={`phoneNumber-${guest.id}`}>Mobile:</label>
      <PhoneNumber
        id={`phoneNumber-${guest.id}`}
        value={phoneNumber}
        onChange={setPhoneNumber}
      />

      <label htmlFor={`email-${guest.id}`}>Email:</label>
      <Input
        id={`email-${guest.id}`}
        type="email"
        value={email}
        onChange={(e) => setEmail(e.currentTarget.value)}
      />

      <label htmlFor={`line1-${guest.id}`}>Address:</label>
      {!isPrimary && (
        <Checkbox
          id={`address-same-as-${guest.id}`}
          parentClassName="margin-y-1"
          checked={isSameAddress}
          onChange={() => {
            const sameAs = !isSameAddress;
            setLine1(sameAs ? primaryAddress?.line1 ?? "" : "");
            setLine2(sameAs ? primaryAddress?.line2 ?? "" : "");
            setCity(sameAs ? primaryAddress?.city ?? "" : "");
            setState(sameAs ? primaryAddress?.state ?? "" : "");
            setCountry(sameAs ? primaryAddress?.country ?? "" : "");
            setZip(sameAs ? primaryAddress?.zip ?? "" : "");
            setIsSameAddress(sameAs);
          }}
        >
          Same as {primaryFullName}
        </Checkbox>
      )}
      {(isPrimary || !isSameAddress) && (
        <>
          <Input
            id={`line1-${guest.id}`}
            className={clsx(!isPrimary && "is-grid-column-2-neg-1")}
            value={line1}
            onChange={(e) => setLine1(e.currentTarget.value)}
          />

          <Input
            id={`line2-${guest.id}`}
            className="is-grid-column-2-neg-1"
            value={line2}
            onChange={(e) => setLine2(e.currentTarget.value)}
          />

          <label htmlFor={`country-${guest.id}`}>Country:</label>
          <div className="is-grid is-align-items-center" style={grid1frAuto1fr}>
            <Select
              id={`country-${guest.id}`}
              value={country}
              onChange={(e) => {
                setCountry(e.currentTarget.value);
                setState("");
              }}
            >
              <option value="" />
              <PopularCountries />
              {Object.entries(countriesByCode).map(([code, name]) => (
                <option key={code} value={code}>
                  {name}
                </option>
              ))}
            </Select>
            <label htmlFor={`city-${guest.id}`} className="margin-x-2">
              City:
            </label>
            <Input
              id={`city-${guest.id}`}
              value={city}
              onChange={(e) => setCity(e.currentTarget.value)}
            />
          </div>

          <label
            htmlFor={`state-${guest.id}`}
            className={clsx(!isPrimary && !isSameAddress && "margin-bottom-1")}
          >
            State:
          </label>
          <div
            className={clsx(
              "is-grid is-align-items-center",
              !isPrimary && !isSameAddress && "margin-bottom-1"
            )}
            style={grid1frAuto1fr}
          >
            <Select
              id={`state-${guest.id}`}
              value={state}
              onChange={(e) => setState(e.currentTarget.value)}
            >
              <option value="" />
              {country &&
                Object.entries(regionsByCountryCode[country])
                  .sort(sortRegionsAlpha)
                  .map(([code, name]) => (
                    <option key={code} value={code}>
                      {name}
                    </option>
                  ))}
            </Select>
            <label
              htmlFor={`zip-${guest.id}`}
              className="margin-x-2 has-text-right"
            >
              Zip:
            </label>
            <Input
              id={`zip-${guest.id}`}
              value={zip}
              onChange={(e) => setZip(e.currentTarget.value)}
            />
          </div>
        </>
      )}

      <label htmlFor={`contact-${guest.id}`}>Contact:</label>
      {!isPrimary && (
        <Checkbox
          id={`contact-same-as-${guest.id}`}
          parentClassName="margin-bottom-1"
          checked={isSameContact}
          onChange={() => {
            const isSame = !isSameContact;
            setEmergencyContact(isSame ? primaryGuest.emergencyContact : "");
            setIsSameContact(isSame);
          }}
        >
          Same as {primaryFullName}
        </Checkbox>
      )}
      {(isPrimary || !isSameContact) && (
        <Input
          id={`contact-${guest.id}`}
          className={clsx(!isPrimary && "is-grid-column-2-neg-1")}
          value={emergencyContact || ""}
          onChange={(e) => setEmergencyContact(e.currentTarget.value)}
        />
      )}

      <label htmlFor={`age-${guest.id}`}>Age:</label>
      <div className="is-flex is-align-items-center">
        <Select
          id={`age-${guest.id}`}
          value={age}
          onChange={(e) => setAge(e.currentTarget.value)}
          parentClassName="is-width-max-content"
          disabled={isOwner}
        >
          <option value="">18+</option>
          {arrOfSize(13)
            .map((_, idx) => 17 - idx)
            .map((ageOpt) => (
              <option key={ageOpt} value={ageOpt}>
                {ageOpt}
              </option>
            ))}
        </Select>
        {age && +age < 18 && (
          <em className="margin-left-2 has-text-dark-gray">
            Mobile and Email optional
          </em>
        )}
      </div>

      <label htmlFor={`specialRequest-${guest.id}`}>Request:</label>
      <Input
        id={`specialRequest-${guest.id}`}
        value={specialReq}
        onChange={(e) => setSpecialReq(e.currentTarget.value)}
        placeholder="Not guaranteed"
      />

      <label
        htmlFor={`accessibilityRequest-${guest.id}`}
        className={clsx(
          "is-align-self-flex-start",
          a11yReq && "has-text-red has-text-weight-bold"
        )}
      >
        <span className="is-size-7">Possible</span>
        <br />
        ADA:
      </label>
      <Textarea
        id={`accessibilityRequest-${guest.id}`}
        value={a11yReq}
        onChange={(e) => setA11yReq(e.currentTarget.value)}
        rows={1}
        className="is-align-self-flex-start"
      />

      {tourYear.requiresPassport && (
        <div className="is-grid-column-1-neg-1 is-grid is-grid-template-columns-auto-1fr is-align-items-center is-column-gap-2">
          <PassportNotice
            className="is-grid-column-1-neg-1"
            concludedAt={concludedAt}
            tourYear={tourYear}
          />

          <label htmlFor={`passportNumber-${guest.id}`}>Passport Number:</label>
          <Input
            id={`passportNumber-${guest.id}`}
            value={passportNumber}
            onChange={(e) => setPassportNumber(e.currentTarget.value)}
          />

          <label htmlFor={`passportCountry-${guest.id}`}>Citizenship:</label>
          <Select
            id={`passportCountry-${guest.id}`}
            value={passportCountry}
            onChange={(e) => setPassportCountry(e.currentTarget.value)}
          >
            <option value="" />
            <PopularCountries />
            {Object.entries(countriesByCode).map(([code, name]) => (
              <option key={code} value={code}>
                {name}
              </option>
            ))}
          </Select>

          {(tourYear.crossesBorder || tourYear.includesFlights) && (
            <>
              <label>Date of Birth:</label>
              <Datepicker
                initialValue={passportBornAt}
                onChange={setPassportBornAt}
              />

              <label>Passport Expiration:</label>
              <Datepicker
                isInvalid={mustRenewPassport}
                initialValue={passportExpiredAt}
                onChange={setPassportExpiredAt}
              />
              {mustRenewPassport && (
                <PassportExpires
                  className="is-grid-column-1-neg-1 margin-top-2"
                  mustExpireAfter={passportMustExpAfter}
                />
              )}
            </>
          )}
        </div>
      )}
    </form>
  );
};

export { GuestEditor };
