import { RoomControls, RoomMode } from "./RoomControls";
import {
  areArrivalDepartureComplete,
  areNameAndDetailsComplete,
  areRequiredFeesPaid,
  arrOfSize,
  BookingDO,
  fullNameOrPlaceholder,
  GuestDO,
  isGuestAccountOwner,
  primaryGuestInfo,
  roomAbbrCapitalized,
  TourYearDO,
} from "data-model";
import { SVG } from "react-components";
import { FC } from "react";

export interface RoomProps {
  booking: BookingDO;
  guests: GuestDO[]; // in this room (i.e. subset of booking.guests)
  onModeChange: (mode: RoomMode) => void;
  posIdx: number; // starting index for this room
  roomIdx: number;
  roomMode: RoomMode;
  tourYear: TourYearDO;
}

const CollapsedRoom: FC<RoomProps> = ({
  booking,
  guests,
  onModeChange,
  posIdx,
  roomIdx,
  roomMode,
  tourYear,
}) => {
  const guestsInRoom = booking.roomGuests[roomIdx];
  const { primaryGuest, primaryAddress } = primaryGuestInfo(
    booking.guests,
    booking.owner
  );

  const isRoomDetailsComplete = guests.every((guest) =>
    areNameAndDetailsComplete(
      guest,
      tourYear,
      booking.departure.concludedAt,
      primaryGuest,
      primaryAddress
    )
  );
  const isRoomFlightsComplete = guests.every((guest) =>
    areArrivalDepartureComplete(
      guest,
      booking.departedAt,
      booking.departure.concludedAt,
      primaryGuest
    )
  );
  const isRoomPaymentsComplete = guests.every(areRequiredFeesPaid);

  return (
    <>
      <div
        className="padding-2 has-background-white is-grid is-align-items-center"
        style={{
          gridTemplateColumns: "auto 1fr 0.95fr auto",
        }}
      >
        <RoomControls activeMode={roomMode} onChange={onModeChange} />
        <h3 className="is-marginless is-size-2 margin-right-1">
          Room {roomIdx + 1} &ndash; {roomAbbrCapitalized(guestsInRoom)}
        </h3>
        <span className="is-flex is-flex-direction-column is-size-8 is-line-height-small">
          {arrOfSize(guestsInRoom).map((_, guestIdx) => {
            const isOwner = isGuestAccountOwner(
              booking.guests,
              booking.owner,
              posIdx
            );

            return (
              <span key={guestIdx} className="is-flex">
                {fullNameOrPlaceholder(booking.guests, booking.owner, posIdx++)}
                {isOwner && (
                  <SVG
                    path="/site/icon/person-black"
                    alt="Person"
                    height={12}
                    className="margin-left-1"
                  />
                )}
                {guests[guestIdx].accessibilityRequest && (
                  <strong className="has-text-red margin-left-1">(ADA)</strong>
                )}
              </span>
            );
          })}
        </span>
        <CheckOrAlert check={isRoomDetailsComplete} />
      </div>
      <div className="padding-2 has-background-white is-flex is-align-items-center is-justify-content-flex-end">
        <CheckOrAlert check={isRoomFlightsComplete} />
      </div>
      <div className="padding-2 has-background-white is-flex is-align-items-center is-justify-content-flex-end">
        <CheckOrAlert check={isRoomPaymentsComplete} />
      </div>
    </>
  );
};

interface CheckOrAlertProps {
  check: boolean;
}

const CheckOrAlert: FC<CheckOrAlertProps> = ({ check }) => (
  <SVG
    path={check ? "/site/icon/checkmark-circled-green" : "/site/icon/alert"}
    alt={check ? "Checkmark" : "Alert"}
    height={19}
  />
);

export { CollapsedRoom, CheckOrAlert };
