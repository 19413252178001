import { db } from "../../jsongo";
import { searchBookings } from "../../api";
import { BookingNumber } from "../../components";
import {
  adjustmentFees,
  arrOfSize,
  arrUnique,
  BookingDO,
  capitalize,
  DannoSearchBookingReqSerialized,
  depositFee,
  finalPaymentFee,
  fullName as getFullName,
  getPublicTourIds,
  isBookingAnnulled,
  isDepositOverdue,
  isFeeOwed,
  isFinalPaymentOverdue,
  isLastItem,
  lastItem,
  MMM_d_yyyy,
  seriesCodeFrom,
  SeriesDO,
  TourDO,
  validateBookingNumber,
} from "data-model";
import { Circle, Input, PhoneNumber, Select, SVG } from "react-components";
import { FC, useCallback, useEffect, useState } from "react";
import debounce from "lodash.debounce";
import clsx from "clsx";
import { DateTime } from "luxon";

const defaultLimit = 25;

const allSeries = db.series.docs() as SeriesDO[];

type Category = DannoSearchBookingReqSerialized["category"];

const categories: Category[] = [
  "all",
  "active",
  "travelled",
  "cancelled",
  "deleted",
  "expired",
];

export const seriesParam = "series";
export const dateParam = "date";

interface Props {
  booking: BookingDO | null;
  isLoading: boolean;
  onBookingChange: (booking: BookingDO | null) => void;
  onBookingManage: (booking: BookingDO) => void;
  searchParams: URLSearchParams;
  setIsLoading: (isLoading: boolean) => void;
  setSearchParams: (searchParams: URLSearchParams) => void;
}

const BookingFinder: FC<Props> = ({
  booking,
  isLoading,
  onBookingChange,
  onBookingManage,
  searchParams,
  setSearchParams,
  setIsLoading,
}) => {
  const [number, setNumber] = useState("");
  const [tour, setTour] = useState("");
  const [series, setSeries] = useState(searchParams.get(seriesParam) ?? "");
  const [date, setDate] = useState(searchParams.get(dateParam) ?? "");
  const [party, setParty] = useState("");
  const [fullName, setFullName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [email, setEmail] = useState("");
  const [category, setCategory] = useState<Category>("active");
  const [notes, setNotes] = useState("");

  const [bookings, setBookings] = useState<BookingDO[]>([]);
  const [total, setTotal] = useState(0);
  const [limit, setLimit] = useState(defaultLimit);
  const [offset, setOffset] = useState(0);

  const filteredSeries = tour
    ? allSeries.filter((s) => s.tourYear_id.tour_id === tour)
    : allSeries.slice(); // due to sort below
  const uniqueCodes = arrUnique(
    filteredSeries
      .sort((seriesA, seriesB) => seriesA.priority - seriesB.priority)
      .map(seriesCodeFrom)
  );

  const isEmpty =
    !number &&
    !tour &&
    !series &&
    !date &&
    !party &&
    !fullName &&
    !phoneNumber &&
    !email &&
    !notes;
  const isNumberInvalid = Boolean(number && !validateBookingNumber(number));

  const search = async (
    args: DannoSearchBookingReqSerialized,
    signal?: AbortSignal,
    selectBooking?: (bookings: BookingDO[]) => BookingDO
  ) => {
    setIsLoading(true);
    try {
      const results = await searchBookings(args, { signal });
      setBookings(results.bookings);
      setTotal(results.total);
      setLimit(args.limit);
      setOffset(args.offset);
      onBookingChange(selectBooking?.(results.bookings) ?? null);
    } finally {
      setIsLoading(false);
    }
  };

  // Why useCallback? https://stackoverflow.com/a/61786423
  const debounceSearch = useCallback(debounce(search, 500), []);

  const formValues = {
    number,
    tour,
    series,
    date,
    party,
    fullName,
    phoneNumber,
    email,
    category,
    notes,
  };

  useEffect(() => {
    const controller = new AbortController();

    if (
      isEmpty || // last non-empty field was just manually cleared
      (isNumberInvalid &&
        !tour &&
        !series &&
        !date &&
        !party &&
        !fullName &&
        !phoneNumber &&
        !email &&
        !notes) // bail out of search if number is the only param and is invalid
    ) {
      // Clean up previous results, if any
      if (bookings.length) {
        setBookings([]);
        setTotal(0);
      }
    } else {
      debounceSearch(
        {
          ...formValues,
          number: isNumberInvalid ? "" : number,
          limit,
          offset: 0,
        },
        controller.signal
      );
    }

    return () => controller.abort();
  }, [
    number,
    tour,
    series,
    date,
    party,
    fullName,
    phoneNumber,
    email,
    category,
    notes,
  ]);

  useEffect(() => {
    if (booking) {
      const cachedBooking = bookings.find((b) => b.id === booking.id);
      if (!cachedBooking) throw new Error("unreachable");

      if (cachedBooking !== booking) {
        // The booking was previewed, then edited, unselected, and is now
        // being previewed again. Because we never re-ran `search`,
        // `bookings` is now stale and needs to be synced.
        setBookings((bookings) =>
          bookings.map((b) => {
            if (b.id === booking.id) return booking;
            return b;
          })
        );
      }
    }
  }, [booking]);

  return (
    <>
      <form
        className="is-grid is-align-items-center is-grid-template-columns-auto-1fr is-column-gap-2"
        autoComplete="off"
      >
        <label htmlFor="number">Number:</label>
        <div className="is-flex">
          <div className="is-flex-1">
            <BookingNumber
              id="number"
              className="is-width-max-content margin-right-2"
              value={number}
              setValue={setNumber}
            />
            <strong className="has-text-red">
              {isNumberInvalid && "Invalid"}
            </strong>
          </div>
          <SVG
            path="site/icon/spinner"
            alt="Spinner"
            className={clsx("margin-right-2", !isLoading && "is-invisible")}
            height={20}
          />
          <button
            type="button"
            className="button is-ghost is-link is-paddingless margin-bottom-1"
            disabled={isEmpty}
            onClick={() => {
              setNumber("");
              setTour("");
              setSeries("");
              setDate("");
              setParty("");
              setFullName("");
              setPhoneNumber("");
              setEmail("");
              setCategory("active");
              setNotes("");
              setBookings([]);
              setTotal(0);
              setLimit(defaultLimit);
              setOffset(0);
              onBookingChange(null);
              searchParams.delete(seriesParam);
              searchParams.delete(dateParam);
              setSearchParams(searchParams);
            }}
          >
            Clear
          </button>
        </div>

        <label htmlFor="tour">Tour:</label>
        <div className="is-flex is-align-items-center">
          <Select
            id="tour"
            value={tour}
            onChange={(e) => {
              setTour(e.currentTarget.value);
              setSeries("");
            }}
          >
            <option value=""></option>
            {getPublicTourIds(db).map((tourId) => {
              const tourDO = db.tour.findByIdOrFail(tourId) as TourDO;
              return (
                <option key={tourDO._id} value={tourDO._id}>
                  {tourDO.name}
                </option>
              );
            })}
          </Select>
          <label htmlFor="series" className="margin-x-2">
            Series:
          </label>
          <Select
            id="series"
            value={series}
            onChange={(e) => {
              setSeries(e.currentTarget.value);
              if (searchParams.has(seriesParam)) {
                searchParams.delete(seriesParam);
                setSearchParams(searchParams);
              }
            }}
          >
            <option value=""></option>
            {uniqueCodes.map((code) => (
              <option key={code}>{code}</option>
            ))}
          </Select>
        </div>

        <label htmlFor="date">Date:</label>
        <div className="is-flex is-align-items-center">
          <Input
            id="date"
            type="date"
            value={date}
            onChange={(e) => {
              setDate(e.currentTarget.value);
              if (searchParams.has(dateParam)) {
                searchParams.delete(dateParam);
                setSearchParams(searchParams);
              }
            }}
          />
          <label htmlFor="party" className="margin-x-2">
            Party:
          </label>
          <Input
            id="party"
            value={party}
            onChange={(e) => setParty(e.currentTarget.value)}
          />
        </div>

        <label htmlFor="full-name">Name:</label>
        <div className="is-flex is-align-items-center">
          <Input
            id="full-name"
            value={fullName}
            onChange={(e) => setFullName(e.currentTarget.value)}
          />
          <label htmlFor="email" className="margin-x-2">
            Email:
          </label>
          <Input
            id="email"
            type="email"
            value={email}
            onChange={(e) => setEmail(e.currentTarget.value)}
          />
        </div>

        <label htmlFor="phone-number">Mobile:</label>
        <PhoneNumber
          id="phone-number"
          value={phoneNumber}
          onChange={setPhoneNumber}
        />

        <label htmlFor="category">Category:</label>
        <div className="is-flex is-align-items-center">
          <Select
            id="category"
            value={category}
            onChange={(e) => setCategory(e.currentTarget.value as Category)}
            className="is-width-auto"
          >
            {categories.map((category) => (
              <option key={category} value={category}>
                {capitalize(category)}
              </option>
            ))}
          </Select>

          <label htmlFor="notes" className="margin-x-2">
            Notes:
          </label>
          <Input
            id="notes"
            value={notes}
            onChange={(e) => setNotes(e.currentTarget.value)}
          />
        </div>
      </form>

      <div
        className="is-grid margin-top-3"
        style={{
          gridTemplateColumns: "repeat(3, auto) repeat(3, min-content)",
          gridAutoRows: "calc(16px * 1.15 + 7.5px)",
        }}
        tabIndex={0}
        onKeyDown={async (e) => {
          if (!bookings.length || isLoading) return;

          if (!booking) {
            onBookingChange(bookings[0]);
          } else {
            const currIdx = bookings.indexOf(booking);

            if (e.key === "ArrowUp") {
              if (currIdx === 0) {
                if (offset === 0) return; // we're at the very start
                return search(
                  { ...formValues, limit, offset: offset - limit },
                  undefined,
                  lastItem
                );
              }

              onBookingChange(bookings[currIdx - 1]);
            } else if (e.key === "ArrowDown") {
              if (isLastItem(currIdx, bookings.length)) {
                if (
                  bookings.length < limit ||
                  offset + bookings.length === total
                ) {
                  return; // we're at the very end
                }

                return search(
                  { ...formValues, limit, offset: offset + limit },
                  undefined,
                  (bookings) => bookings[0]
                );
              }

              onBookingChange(bookings[currIdx + 1]);
            } else if (e.key === "Enter") {
              onBookingManage(booking);
            }
          }
        }}
      >
        <strong className="padding-x-1 padding-y-pt-75">Number</strong>
        <strong className="padding-x-1 padding-y-pt-75">Departure</strong>
        <strong className="padding-x-1 padding-y-pt-75">Owner</strong>
        <strong className="padding-x-1 padding-y-pt-75">Pax</strong>
        <strong className="padding-x-1 padding-y-pt-75">Dep</strong>
        <strong className="padding-x-1 padding-y-pt-75">FP</strong>
        {bookings.map((someBooking) => {
          const isSelected = someBooking.id === booking?.id;
          const className = clsx(
            "padding-x-1 padding-y-pt-75",
            isSelected && "has-background-light-blue has-text-white"
          );
          const ownerName = getFullName(someBooking.owner);

          const pax = someBooking.guests.length;
          const depositsPaid = someBooking.guests.filter(
            (guest) => !isFeeOwed(depositFee(guest))
          ).length;
          const finalsPaid = someBooking.guests.filter(
            (guest) =>
              !isFeeOwed(finalPaymentFee(guest)) &&
              adjustmentFees(guest).every((f) => !isFeeOwed(f))
          ).length;
          const isAnnulled = isBookingAnnulled(someBooking);

          return (
            <div
              key={someBooking.id}
              className="is-display-contents is-clickable"
              onClick={() => onBookingChange(isSelected ? null : someBooking)}
              onDoubleClick={() => onBookingManage(someBooking)}
            >
              <span className={className}>{someBooking.number}</span>
              <span className={className}>
                {DateTime.fromISO(someBooking.departedAt).toFormat(MMM_d_yyyy)}
              </span>
              <span
                className={clsx(className, "has-ellipsis")}
                title={ownerName}
              >
                {ownerName}
              </span>
              <span className={className}>{pax}</span>
              <span
                className={clsx(className, "is-flex is-align-items-center")}
              >
                {isAnnulled ? (
                  "–"
                ) : depositsPaid === pax ? (
                  <Circle color="green" isSelected={isSelected} size="large" />
                ) : (
                  <>
                    {depositsPaid}
                    {isDepositOverdue(someBooking) && (
                      <Circle
                        color="red"
                        isSelected={isSelected}
                        size="small"
                      />
                    )}
                  </>
                )}
              </span>
              <span
                className={clsx(className, "is-flex is-align-items-center")}
              >
                {isAnnulled ? (
                  "–"
                ) : finalsPaid === pax ? (
                  <Circle color="green" isSelected={isSelected} size="large" />
                ) : (
                  <>
                    {finalsPaid}
                    {isFinalPaymentOverdue(someBooking) && (
                      <Circle
                        color="red"
                        isSelected={isSelected}
                        size="small"
                      />
                    )}
                  </>
                )}
              </span>
            </div>
          );
        })}
        {bookings.length < limit &&
          arrOfSize(6 * (limit - bookings.length)).map((_, idx) => (
            <span key={idx} className="has-background-white padding-1" />
          ))}
      </div>
      <div className="is-flex is-justify-content-space-between is-align-items-center margin-top-1 margin-x-1">
        {/* <div className="is-flex is-align-items-center">
          <label htmlFor="limit" className="margin-x-1">
            Display:
          </label>
          <Select
            id="limit"
            value={limit}
            onChange={(e) => {
              const limit = +e.currentTarget.value;
              if (isEmpty) {
                setLimit(limit);
              } else {
                search({ ...formValues, limit, offset: 0 });
              }
            }}
          >
            <option>25</option>
            <option>30</option>
            <option>50</option>
          </Select>
        </div> */}
        {/* <span>Page: {!!bookings.length && (offset + limit) / limit}</span> */}
        <span>
          {bookings.length ? 1 + offset : 0} to {offset + bookings.length} of{" "}
          {total}
        </span>
        <div className="is-flex">
          <button
            className="button is-ghost is-link is-paddingless margin-right-3"
            disabled={isLoading || offset === 0}
            onClick={() =>
              search({ ...formValues, limit, offset: offset - limit })
            }
          >
            Previous
          </button>
          <button
            className="button is-ghost is-link is-paddingless"
            disabled={
              isLoading || // prevent multiple clicks
              !bookings.length || // no search was made yet
              bookings.length < limit || // last page
              offset + bookings.length === total // also last page based on total #
            }
            onClick={() =>
              search({ ...formValues, limit, offset: offset + limit })
            }
          >
            Next
          </button>
        </div>
        <button
          className={clsx(
            "button is-ghost is-link is-paddingless",
            !booking && "is-invisible"
          )}
          onClick={() => booking && onBookingManage(booking)}
        >
          Manage
        </button>
      </div>
    </>
  );
};

export { BookingFinder };
