const { default: airports } = await import(
  `../../../../${process.env.JSONGO_DIR}/airport.json`,
  {
    assert: { type: "json" },
  }
);
const { default: global } = await import(
  `../../../../${process.env.JSONGO_DIR}/global.json`,
  {
    assert: { type: "json" },
  }
);
const { default: hotels } = await import(
  `../../../../${process.env.JSONGO_DIR}/hotel.json`,
  {
    assert: { type: "json" },
  }
);
// const { default: imgs } = await import(`../../../../${jsongo}/img.json`);
// const { default: logins } = await import(
//   `../../../../${process.env.JSONGO_DIR}/login.json`,
//   {
//     assert: { type: "json" },
//   }
// );
const { default: series } = await import(
  `../../../../${process.env.JSONGO_DIR}/series.json`,
  {
    assert: { type: "json" },
  }
);
const { default: tours } = await import(
  `../../../../${process.env.JSONGO_DIR}/tour.json`,
  {
    assert: { type: "json" },
  }
);
const { default: tourChanges } = await import(
  `../../../../${process.env.JSONGO_DIR}/tourChange.json`,
  {
    assert: { type: "json" },
  }
);
const { default: tourYears } = await import(
  `../../../../${process.env.JSONGO_DIR}/tourYear.json`,
  {
    assert: { type: "json" },
  }
);
const { default: travelDocuments } = await import(
  `../../../../${process.env.JSONGO_DIR}/travelDocument.json`,
  {
    assert: { type: "json" },
  }
);

import {
  AirportDO_Index,
  getGlobalDO,
  HotelDO_Index,
  TourYearDO_Index,
  TravelDocumentDO_Index,
} from "data-model";
import { memDB } from "jsongo";

export const db = memDB();

db.airport.insertMany(airports);
db.global.insertMany(global);
db.hotel.insertMany(hotels);
// db.img.insertMany(imgs);
// db.login.insertMany(logins);
db.series.insertMany(series);
db.tour.insertMany(tours);
db.tourChange.insertMany(tourChanges);
db.tourYear.insertMany(tourYears);
db.travelDocument.insertMany(travelDocuments);

export const airportsById = db.airport.index("_id") as AirportDO_Index;
export const globalDO = getGlobalDO(db);
export const hotelsById = db.hotel.index("_id") as HotelDO_Index;
export const tourYearsById = db.tourYear.index("_id") as TourYearDO_Index;
export const travelDocumentsById = db.travelDocument.index(
  "_id"
) as TravelDocumentDO_Index;
// export const loginsById = db.login.index("_id") as LoginDO_Index;
