import { Props as SharedProps } from "./RefundTpp";
import { accountIdParam } from "../../CheckAvailability";
import {
  BookingEventDO,
  fullName,
  isReplaceEvent,
  isSplitEvent,
  isTransferEvent,
} from "data-model";
import { DateAndInitials } from "react-components";
import { FC, Fragment } from "react";
import { useNavigate } from "react-router-dom";

const whiteCell = "has-background-white padding-1";

interface Props extends Pick<SharedProps, "onClose"> {
  events: BookingEventDO[];
}

const BookingHistory: FC<Props> = ({ events, onClose }) => {
  const navigate = useNavigate();

  if (!events.length) {
    return <p className="is-marginless">No events found.</p>;
  }

  const handleLink = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    e.preventDefault();
    onClose();
    navigate(e.currentTarget.getAttribute("href")!);
  };

  return (
    <div className="is-grid is-grid-template-columns-auto-1fr is-gap-1px has-background-gray has-border-gray">
      {events.map((event) => (
        <Fragment key={event.id}>
          <span className={whiteCell}>
            <DateAndInitials
              dateIso={event.createdAt}
              loginId={event.loginId}
              showTime
            />
          </span>
          <span className={whiteCell}>
            {isReplaceEvent(event) ? (
              <>
                Account changed from{" "}
                <a
                  onClick={handleLink}
                  href={`/check-availability?${accountIdParam}=${event.fromAccount.id}`}
                >
                  {fullName(event.fromAccount)}
                </a>{" "}
                to{" "}
                <a
                  onClick={handleLink}
                  href={`/check-availability?${accountIdParam}=${event.toAccount.id}`}
                >
                  {fullName(event.toAccount)}
                </a>
                <RequestedBy requestedBy={event.requestedBy} />
              </>
            ) : isTransferEvent(event) ? (
              <>
                Booking transferred to {event.toBooking.number}
                <RequestedBy requestedBy={event.requestedBy} />
              </>
            ) : isSplitEvent(event) ? (
              <>
                Booking split to {event.toBooking.number}
                <RequestedBy requestedBy={event.requestedBy} />
              </>
            ) : null}
          </span>
        </Fragment>
      ))}
    </div>
  );
};

export { BookingHistory };

interface RequestedByProps {
  requestedBy?: string;
}

const RequestedBy: FC<RequestedByProps> = ({ requestedBy }) =>
  requestedBy ? <> requested by {requestedBy}</> : null;
