import { sendSurvey } from "../../../api";
import {
  BookingDO,
  fullNameOrPlaceholder,
  isGuestAccountOwner,
} from "data-model";
import { Checkbox, ErrorMessage, useErrors } from "react-components";
import { Fragment, useState, type FC } from "react";

interface Props {
  booking: BookingDO;
  onClose: () => void;
}

const SendSurvey: FC<Props> = ({ booking: { id, owner, guests }, onClose }) => {
  const [guestIds, setGuestIds] = useState<number[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [errors, catchErrors] = useErrors();

  const handleCheck = (e: React.ChangeEvent<HTMLInputElement>) => {
    const guestId = +e.currentTarget.dataset.guestId!;
    if (e.currentTarget.checked) {
      setGuestIds([...guestIds, guestId]);
    } else {
      setGuestIds(guestIds.filter((id) => id !== guestId));
    }
  };

  const handleSend = () => {
    setIsLoading(true);
    catchErrors(
      async () => {
        await sendSurvey(id, guestIds);
        onClose();
      },
      () => setIsLoading(false)
    );
  };

  return (
    <>
      <div
        className="is-grid is-column-gap-2 is-row-gap-2 margin-top-2"
        style={{ gridTemplateColumns: "auto 2fr 1fr" }}
      >
        {guests.map((guest, posIdx) => {
          const isOwner = isGuestAccountOwner(guests, owner, posIdx);

          let email = guest.email;
          if (!email && isOwner) email = owner.email;

          if (!email) return;

          return (
            <Fragment key={guest.id}>
              <Checkbox
                checked={guestIds.includes(guest.id)}
                onChange={handleCheck}
                data-guest-id={guest.id}
              />
              <span className="margin-right-4">{email}</span>
              <span>{fullNameOrPlaceholder(guests, owner, posIdx)}</span>
            </Fragment>
          );
        })}
      </div>
      <button
        className="button is-light-blue padding-x-4 margin-left-auto margin-top-4"
        disabled={!guestIds.length || isLoading}
        onClick={handleSend}
      >
        Send Survey
      </button>
      <ErrorMessage className="margin-bottom-0" errors={errors} />
    </>
  );
};

export { SendSurvey };
